<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 100 500 400">
    <g id="freepik--background-simple--inject-14">
      <path
        d="M91.33,121.49c57.08-51.33,129.37,52.88,170.18,54.69,51.15,2.27,161.22-94.72,179.82-4.3C452.66,227,426,281.4,385.33,311.75,329.22,353.63,191.12,363,122.66,348.3,88,340.85,50,309.27,55.33,266c4.65-37.72,40.33-39.16,46-57.79C108.13,185.87,60.66,149.07,91.33,121.49Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M91.33,121.49c57.08-51.33,129.37,52.88,170.18,54.69,51.15,2.27,161.22-94.72,179.82-4.3C452.66,227,426,281.4,385.33,311.75,329.22,353.63,191.12,363,122.66,348.3,88,340.85,50,309.27,55.33,266c4.65-37.72,40.33-39.16,46-57.79C108.13,185.87,60.66,149.07,91.33,121.49Z"
        style="fill: #fff; opacity: 0.9"
      ></path>
    </g>
    <g id="freepik--Shadow--inject-14">
      <ellipse
        id="freepik--path--inject-14"
        cx="250"
        cy="412.39"
        rx="193.89"
        ry="11.32"
        style="fill: #f5f5f5"
      ></ellipse>
    </g>
    <g id="freepik--Plants--inject-14">
      <path
        d="M318.16,415.89c.42-1.13.85-2.26,1.3-3.38v0c.33-.8.65-1.6,1-2.4h0l.39-.93c1.44-3.41,3-6.81,4.76-10.1.06-.13.13-.25.2-.38q.7-1.29,1.44-2.55c.34-.57.68-1.13,1-1.69s.58-.91.88-1.36a55.31,55.31,0,0,1,4.27-5.7l.95-1.08c.19-.21.49.11.3.32s-.49.55-.73.83a54.61,54.61,0,0,0-3.56,4.59l-.17.25a4,4,0,0,0-.23.34,95.9,95.9,0,0,0-9.08,17.5c-.35.84-.7,1.69-1,2.55a0,0,0,0,1,0,0c-.42,1.05-.83,2.12-1.22,3.19a1.46,1.46,0,0,0-.07.19v0Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.30000000000000004">
        <path
          d="M318.16,415.89c.42-1.13.85-2.26,1.3-3.38v0c.33-.8.65-1.6,1-2.4h0l.39-.93c1.44-3.41,3-6.81,4.76-10.1.06-.13.13-.25.2-.38q.7-1.29,1.44-2.55c.34-.57.68-1.13,1-1.69s.58-.91.88-1.36a55.31,55.31,0,0,1,4.27-5.7l.95-1.08c.19-.21.49.11.3.32s-.49.55-.73.83a54.61,54.61,0,0,0-3.56,4.59l-.17.25a4,4,0,0,0-.23.34,95.9,95.9,0,0,0-9.08,17.5c-.35.84-.7,1.69-1,2.55a0,0,0,0,1,0,0c-.42,1.05-.83,2.12-1.22,3.19a1.46,1.46,0,0,0-.07.19v0Z"
          style="fill: #fff"
        ></path>
      </g>
      <path
        d="M334.62,385.75s-2.73-.2-1.7,1.19,2.36-.48,2.36-.48-.43,2.65,1.13,2.93.11-3.06.11-3.06,2,2.37,2.8,1.17-2.71-2.41-2.71-2.41,3.46.36,2.59-.73-3.62-.15-3.62-.15,1.05-2.2-.68-2.54-.39,2.53-.39,2.53-2-2.07-2.84-1.24S334.62,385.75,334.62,385.75Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M327.18,396.74a11.85,11.85,0,0,0,1.65-7.76C328.35,384.1,327.18,396.74,327.18,396.74Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M329.6,392.83s3-3.13,4.73-1.48S331.78,391.35,329.6,392.83Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M336.21,385.9c-.21.19-.7,0-1.08-.39s-.53-.91-.32-1.1.7,0,1.08.39S336.42,385.7,336.21,385.9Z"
        style="fill: #fff; opacity: 0.6000000000000001"
      ></path>
      <path
        d="M319.05,415.88c.29-1.06.56-2.14.81-3.21.21-.86.41-1.72.59-2.59.29-1.32.57-2.65.82-4a108.51,108.51,0,0,0,1.91-14.19.49.49,0,0,0,0-.17c.05-.64.08-1.29.09-1.93a57.69,57.69,0,0,0-.34-8.82c-.05-.47-.11-.95-.18-1.42,0-.27-.46-.17-.42.1l.15,1.09a54.36,54.36,0,0,1,.4,6.12c0,.13,0,.26,0,.39,0,1.3,0,2.61-.09,3.91a109.24,109.24,0,0,1-2.13,15.69c-.38,1.88-.78,3.75-1.22,5.62-.27,1.13-.55,2.27-.85,3.4l-.06.22Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.30000000000000004">
        <path
          d="M319.05,415.88c.29-1.06.56-2.14.81-3.21.21-.86.41-1.72.59-2.59.29-1.32.57-2.65.82-4a108.51,108.51,0,0,0,1.91-14.19.49.49,0,0,0,0-.17c.05-.64.08-1.29.09-1.93a57.69,57.69,0,0,0-.34-8.82c-.05-.47-.11-.95-.18-1.42,0-.27-.46-.17-.42.1l.15,1.09a54.36,54.36,0,0,1,.4,6.12c0,.13,0,.26,0,.39,0,1.3,0,2.61-.09,3.91a109.24,109.24,0,0,1-2.13,15.69c-.38,1.88-.78,3.75-1.22,5.62-.27,1.13-.55,2.27-.85,3.4l-.06.22Z"
          style="fill: #fff"
        ></path>
      </g>
      <path
        d="M322.86,379s2.38,1.36.75,1.93-1.69-1.71-1.69-1.71-1.12,2.44-2.57,1.81,1.61-2.61,1.61-2.61-3,.87-3-.59,3.59-.48,3.59-.48-3.07-1.63-1.74-2,3.09,1.89,3.09,1.89.35-2.42,2-1.74-1.09,2.32-1.09,2.32,2.83-.59,3.05.55S322.86,379,322.86,379Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M322.86,379s2.38,1.36.75,1.93-1.69-1.71-1.69-1.71-1.12,2.44-2.57,1.81,1.61-2.61,1.61-2.61-3,.87-3-.59,3.59-.48,3.59-.48-3.07-1.63-1.74-2,3.09,1.89,3.09,1.89.35-2.42,2-1.74-1.09,2.32-1.09,2.32,2.83-.59,3.05.55S322.86,379,322.86,379Z"
        style="fill: #fff; opacity: 0.5"
      ></path>
      <path
        d="M322.92,392.26a11.82,11.82,0,0,1,2.95-7.36C329,381.11,322.92,392.26,322.92,392.26Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M323.09,387.66s-.8-4.29-3.11-3.86S322.11,385.22,323.09,387.66Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M321.46,378.23c.07.28.56.4,1.11.28s.94-.46.88-.74-.57-.41-1.11-.28S321.4,378,321.46,378.23Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M141.35,397.71c-1.25-6.55-6-10.52-10.93-14.52,3,5.6,6.46,10.74,8,17a28.92,28.92,0,0,0-8-8.27s4.5,9.64,6.23,11.51A22.11,22.11,0,0,0,123.44,396c9.45,6.9,12.81,13.38,14.11,17.21a4.29,4.29,0,0,0,4,3l10-.07a4.36,4.36,0,0,0,4-3.12c1.75-5.19,4.8-9.65,9.33-12.2-3.81,1.17-7,2-9.3,5.57,1.42-5.19,3.84-8.19,8.34-10.37-4.18.74-8.3,2.45-10,6.71a17.12,17.12,0,0,1,6.22-12.23,9.38,9.38,0,0,0-7.7,7.32c-.12-5.22.38-10.47,3-14.84A16.59,16.59,0,0,0,149,394.25c-1.12-4.49-1.67-11.06-6.43-16.94a40.29,40.29,0,0,1,1.68,15.87c-.41-2.74-4.13-9.7-7.7-12.24C136.52,380.94,141.73,391.14,141.35,397.71Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M131.52,408.89a14.64,14.64,0,0,1,4.51,3.88,9,9,0,0,0-4.54.24l5.52.81a5,5,0,0,0-3.16,1.79,8.74,8.74,0,0,1,4.62-.23,5.78,5.78,0,0,0,1.69.26,1.48,1.48,0,0,0,1.32-1,2.1,2.1,0,0,0-.31-1.47C139.25,409.54,135,409,131.52,408.89Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M159.72,409.62a16.7,16.7,0,0,1-4.58,3.29,11.35,11.35,0,0,1,5.27.36,10,10,0,0,1-4.72.85,7.36,7.36,0,0,1,2.85,1.39l-6.43.68a2.7,2.7,0,0,1,.17-2.05C153.52,411.83,157.55,410.31,159.72,409.62Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.6000000000000001">
        <path
          d="M141.35,397.71c-1.25-6.55-6-10.52-10.93-14.52,3,5.6,6.46,10.74,8,17a28.92,28.92,0,0,0-8-8.27s4.5,9.64,6.23,11.51A22.11,22.11,0,0,0,123.44,396c9.45,6.9,12.81,13.38,14.11,17.21a4.29,4.29,0,0,0,4,3l10-.07a4.36,4.36,0,0,0,4-3.12c1.75-5.19,4.8-9.65,9.33-12.2-3.81,1.17-7,2-9.3,5.57,1.42-5.19,3.84-8.19,8.34-10.37-4.18.74-8.3,2.45-10,6.71a17.12,17.12,0,0,1,6.22-12.23,9.38,9.38,0,0,0-7.7,7.32c-.12-5.22.38-10.47,3-14.84A16.59,16.59,0,0,0,149,394.25c-1.12-4.49-1.67-11.06-6.43-16.94a40.29,40.29,0,0,1,1.68,15.87c-.41-2.74-4.13-9.7-7.7-12.24C136.52,380.94,141.73,391.14,141.35,397.71Z"
          style="fill: #fff"
        ></path>
        <path
          d="M131.52,408.89a14.64,14.64,0,0,1,4.51,3.88,9,9,0,0,0-4.54.24l5.52.81a5,5,0,0,0-3.16,1.79,8.74,8.74,0,0,1,4.62-.23,5.78,5.78,0,0,0,1.69.26,1.48,1.48,0,0,0,1.32-1,2.1,2.1,0,0,0-.31-1.47C139.25,409.54,135,409,131.52,408.89Z"
          style="fill: #fff"
        ></path>
        <path
          d="M159.72,409.62a16.7,16.7,0,0,1-4.58,3.29,11.35,11.35,0,0,1,5.27.36,10,10,0,0,1-4.72.85,7.36,7.36,0,0,1,2.85,1.39l-6.43.68a2.7,2.7,0,0,1,.17-2.05C153.52,411.83,157.55,410.31,159.72,409.62Z"
          style="fill: #fff"
        ></path>
      </g>
      <path
        d="M316.2,402.26c-1.28-4.95-6.17-7.95-11.14-11,3,4.23,6.59,8.1,8.18,12.82a26.68,26.68,0,0,0-8.19-6.24s4.6,7.28,6.36,8.69a25.47,25.47,0,0,0-13.47-5.6c9.63,5.22,13.06,10.1,14.38,13a4.44,4.44,0,0,0,4.13,2.3l10.21,0a4.42,4.42,0,0,0,4.07-2.36,17.83,17.83,0,0,1,9.52-9.21c-3.89.88-7.15,1.53-9.48,4.2,1.44-3.91,3.91-6.17,8.5-7.82-4.26.56-8.47,1.85-10.21,5.06.51-3.94,2.49-6.8,6.35-9.23-3.65.45-7.16,2.57-7.86,5.53-.12-3.94.39-7.91,3-11.21a12.46,12.46,0,0,0-6.61,8.47c-1.14-3.4-1.7-8.35-6.55-12.79a22.89,22.89,0,0,1,1.71,12c-.42-2.07-4.22-7.32-7.85-9.24C311.28,389.6,316.58,397.3,316.2,402.26Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M306.17,410.7a14.76,14.76,0,0,1,4.61,2.93,12.49,12.49,0,0,0-4.64.18l5.63.61a5.76,5.76,0,0,0-3.22,1.35,11.85,11.85,0,0,1,4.71-.17,7.68,7.68,0,0,0,1.72.19,1.52,1.52,0,0,0,1.35-.76,1.29,1.29,0,0,0-.31-1.12C314.05,411.19,309.69,410.77,306.17,410.7Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M334.94,411.25a17.82,17.82,0,0,1-4.67,2.48,15.56,15.56,0,0,1,5.36.27,13.13,13.13,0,0,1-4.8.65,8.89,8.89,0,0,1,2.9,1l-6.55.52a1.55,1.55,0,0,1,.17-1.55C328.61,412.92,332.72,411.77,334.94,411.25Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.6000000000000001">
        <path
          d="M316.2,402.26c-1.28-4.95-6.17-7.95-11.14-11,3,4.23,6.59,8.1,8.18,12.82a26.68,26.68,0,0,0-8.19-6.24s4.6,7.28,6.36,8.69a25.47,25.47,0,0,0-13.47-5.6c9.63,5.22,13.06,10.1,14.38,13a4.44,4.44,0,0,0,4.13,2.3l10.21,0a4.42,4.42,0,0,0,4.07-2.36,17.83,17.83,0,0,1,9.52-9.21c-3.89.88-7.15,1.53-9.48,4.2,1.44-3.91,3.91-6.17,8.5-7.82-4.26.56-8.47,1.85-10.21,5.06.51-3.94,2.49-6.8,6.35-9.23-3.65.45-7.16,2.57-7.86,5.53-.12-3.94.39-7.91,3-11.21a12.46,12.46,0,0,0-6.61,8.47c-1.14-3.4-1.7-8.35-6.55-12.79a22.89,22.89,0,0,1,1.71,12c-.42-2.07-4.22-7.32-7.85-9.24C311.28,389.6,316.58,397.3,316.2,402.26Z"
          style="fill: #fff"
        ></path>
        <path
          d="M306.17,410.7a14.76,14.76,0,0,1,4.61,2.93,12.49,12.49,0,0,0-4.64.18l5.63.61a5.76,5.76,0,0,0-3.22,1.35,11.85,11.85,0,0,1,4.71-.17,7.68,7.68,0,0,0,1.72.19,1.52,1.52,0,0,0,1.35-.76,1.29,1.29,0,0,0-.31-1.12C314.05,411.19,309.69,410.77,306.17,410.7Z"
          style="fill: #fff"
        ></path>
        <path
          d="M334.94,411.25a17.82,17.82,0,0,1-4.67,2.48,15.56,15.56,0,0,1,5.36.27,13.13,13.13,0,0,1-4.8.65,8.89,8.89,0,0,1,2.9,1l-6.55.52a1.55,1.55,0,0,1,.17-1.55C328.61,412.92,332.72,411.77,334.94,411.25Z"
          style="fill: #fff"
        ></path>
      </g>
      <path
        d="M131.35,389s2-3.65,4.93-2.56a3.53,3.53,0,0,1,2.22,4.15s4.56-1,6,1-.73,4.33-.73,4.33a5.13,5.13,0,0,1,4.46,3.85c.8,3.55-2.77,2.85-2.77,2.85s2.85,3.76,1.58,6.11-4,1-4,1,.93,4.32-1.35,5.7-3.92-1.24-3.92-1.24-.38,3.91-3,4.11-3.3-2.79-3.3-2.79-3.72,2.42-6.71,1.19-.61-4-.61-4-4.8.28-6-2.29,1.56-3.9,1.56-3.9a6.67,6.67,0,0,1-3.82-5.41c-.51-4.13,3.51-2.39,3.51-2.39s-2.38-3.95-.17-6.34a3,3,0,0,1,4.43-.24s-.46-4,2.06-4.86S131.35,389,131.35,389Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M131.35,389s2-3.65,4.93-2.56a3.53,3.53,0,0,1,2.22,4.15s4.56-1,6,1-.73,4.33-.73,4.33a5.13,5.13,0,0,1,4.46,3.85c.8,3.55-2.77,2.85-2.77,2.85s2.85,3.76,1.58,6.11-4,1-4,1,.93,4.32-1.35,5.7-3.92-1.24-3.92-1.24-.38,3.91-3,4.11-3.3-2.79-3.3-2.79-3.72,2.42-6.71,1.19-.61-4-.61-4-4.8.28-6-2.29,1.56-3.9,1.56-3.9a6.67,6.67,0,0,1-3.82-5.41c-.51-4.13,3.51-2.39,3.51-2.39s-2.38-3.95-.17-6.34a3,3,0,0,1,4.43-.24s-.46-4,2.06-4.86S131.35,389,131.35,389Z"
        style="fill: #fff; opacity: 0.30000000000000004"
      ></path>
      <path
        d="M145.5,402.61s3.05.56,2.84-2.21c-.93,2.93-3.6,1-3.6,1s1,4.61-1.18,6.2-4.06-.84-4.06-.84-1,4.3-3.65,4.57-3-2.78-3-2.78-2,3.37-4.51,2.42-1.79-3.93-1.79-3.93-4.4.61-6.59-1.78,1.15-3.87,1.15-3.87a9.54,9.54,0,0,1-3.93-3c-.85.16-1.57.81-1.34,2.71a6.67,6.67,0,0,0,3.82,5.41s-2.77,1.33-1.56,3.9,6,2.29,6,2.29-2.39,2.77.61,4,6.71-1.19,6.71-1.19.64,3,3.3,2.79,3-4.11,3-4.11,1.63,2.61,3.92,1.24,1.35-5.7,1.35-5.7,2.76,1.39,4-1S145.5,402.61,145.5,402.61Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M124.16,399.91c-.53.53-3.23,2.36-3.76,1.82s1.29-3.23,1.82-3.76a1.37,1.37,0,0,1,1.94,1.94Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M124.16,399.91c-.53.53-3.23,2.36-3.76,1.82s1.29-3.23,1.82-3.76a1.37,1.37,0,0,1,1.94,1.94Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M115.63,404.56c.54-.53,3.23-2.36,3.76-1.82s-1.28,3.22-1.82,3.76a1.37,1.37,0,0,1-1.94-1.94Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M115.63,404.56c.54-.53,3.23-2.36,3.76-1.82s-1.28,3.22-1.82,3.76a1.37,1.37,0,0,1-1.94-1.94Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M120,397.38c.2.73.43,4-.3,4.17s-2.15-2.73-2.34-3.46a1.37,1.37,0,1,1,2.64-.71Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M120,397.38c.2.73.43,4-.3,4.17s-2.15-2.73-2.34-3.46a1.37,1.37,0,1,1,2.64-.71Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M119.78,407.09c-.2-.73-.43-4,.3-4.17s2.15,2.73,2.35,3.46a1.37,1.37,0,1,1-2.65.71Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M119.78,407.09c-.2-.73-.43-4,.3-4.17s2.15,2.73,2.35,3.46a1.37,1.37,0,1,1-2.65.71Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M115.75,399.7c.73.2,3.66,1.62,3.46,2.35s-3.44.5-4.17.3a1.37,1.37,0,1,1,.71-2.65Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M115.75,399.7c.73.2,3.66,1.62,3.46,2.35s-3.44.5-4.17.3a1.37,1.37,0,1,1,.71-2.65Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M124,404.76c-.73-.19-3.65-1.61-3.46-2.34s3.44-.5,4.17-.3a1.37,1.37,0,1,1-.71,2.64Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M124,404.76c-.73-.19-3.65-1.61-3.46-2.34s3.44-.5,4.17-.3a1.37,1.37,0,1,1-.71,2.64Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M121.21,403.55a1.86,1.86,0,1,1,0-2.63A1.87,1.87,0,0,1,121.21,403.55Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M121.21,403.55a1.86,1.86,0,1,1,0-2.63A1.87,1.87,0,0,1,121.21,403.55Z"
        style="fill: #fff; opacity: 0.2"
      ></path>
      <path
        d="M134.7,410.45c-.53.53-3.23,2.36-3.76,1.82s1.29-3.23,1.82-3.76a1.37,1.37,0,0,1,1.94,1.94Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M134.7,410.45c-.53.53-3.23,2.36-3.76,1.82s1.29-3.23,1.82-3.76a1.37,1.37,0,0,1,1.94,1.94Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M126.17,415.1c.54-.53,3.23-2.36,3.76-1.82s-1.29,3.22-1.82,3.76a1.37,1.37,0,1,1-1.94-1.94Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M126.17,415.1c.54-.53,3.23-2.36,3.76-1.82s-1.29,3.22-1.82,3.76a1.37,1.37,0,1,1-1.94-1.94Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M130.55,407.92c.2.73.43,4-.3,4.17s-2.15-2.73-2.34-3.46a1.37,1.37,0,1,1,2.64-.71Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M130.55,407.92c.2.73.43,4-.3,4.17s-2.15-2.73-2.34-3.46a1.37,1.37,0,1,1,2.64-.71Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M130.32,417.63c-.2-.73-.43-4,.3-4.17s2.15,2.73,2.35,3.46a1.37,1.37,0,1,1-2.65.71Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M130.32,417.63c-.2-.73-.43-4,.3-4.17s2.15,2.73,2.35,3.46a1.37,1.37,0,1,1-2.65.71Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M126.29,410.24c.73.2,3.66,1.62,3.46,2.35s-3.44.5-4.17.3a1.37,1.37,0,1,1,.71-2.65Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M126.29,410.24c.73.2,3.66,1.62,3.46,2.35s-3.44.5-4.17.3a1.37,1.37,0,1,1,.71-2.65Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M134.58,415.3c-.73-.19-3.65-1.61-3.46-2.34s3.44-.5,4.17-.3a1.37,1.37,0,1,1-.71,2.64Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M134.58,415.3c-.73-.19-3.65-1.61-3.46-2.34s3.44-.5,4.17-.3a1.37,1.37,0,1,1-.71,2.64Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M131.75,414.08a1.86,1.86,0,1,1,0-2.62A1.86,1.86,0,0,1,131.75,414.08Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M131.75,414.08a1.86,1.86,0,1,1,0-2.62A1.86,1.86,0,0,1,131.75,414.08Z"
        style="fill: #fff; opacity: 0.2"
      ></path>
      <path
        d="M146.45,391.46c-.54.53-3.23,2.36-3.76,1.82s1.28-3.23,1.82-3.76a1.36,1.36,0,0,1,1.94,0A1.38,1.38,0,0,1,146.45,391.46Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M146.45,391.46c-.54.53-3.23,2.36-3.76,1.82s1.28-3.23,1.82-3.76a1.36,1.36,0,0,1,1.94,0A1.38,1.38,0,0,1,146.45,391.46Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M137.92,396.11c.53-.54,3.23-2.36,3.76-1.82s-1.29,3.22-1.82,3.76a1.38,1.38,0,0,1-1.94,0A1.36,1.36,0,0,1,137.92,396.11Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M137.92,396.11c.53-.54,3.23-2.36,3.76-1.82s-1.29,3.22-1.82,3.76a1.38,1.38,0,0,1-1.94,0A1.36,1.36,0,0,1,137.92,396.11Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M142.3,388.93c.2.73.43,4-.3,4.17s-2.15-2.73-2.35-3.46a1.37,1.37,0,0,1,2.65-.71Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M142.3,388.93c.2.73.43,4-.3,4.17s-2.15-2.73-2.35-3.46a1.37,1.37,0,0,1,2.65-.71Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M142.07,398.64c-.2-.73-.43-4,.3-4.17s2.15,2.73,2.34,3.46a1.37,1.37,0,1,1-2.64.71Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M142.07,398.64c-.2-.73-.43-4,.3-4.17s2.15,2.73,2.34,3.46a1.37,1.37,0,1,1-2.64.71Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M138,391.25c.73.2,3.65,1.62,3.46,2.35s-3.44.5-4.17.3a1.37,1.37,0,0,1,.71-2.65Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M138,391.25c.73.2,3.65,1.62,3.46,2.35s-3.44.5-4.17.3a1.37,1.37,0,0,1,.71-2.65Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M146.33,396.31c-.73-.19-3.66-1.61-3.46-2.34s3.44-.5,4.17-.3a1.37,1.37,0,1,1-.71,2.64Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M146.33,396.31c-.73-.19-3.66-1.61-3.46-2.34s3.44-.5,4.17-.3a1.37,1.37,0,1,1-.71,2.64Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M143.49,395.09a1.85,1.85,0,1,1,0-2.62A1.84,1.84,0,0,1,143.49,395.09Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M143.49,395.09a1.85,1.85,0,1,1,0-2.62A1.84,1.84,0,0,1,143.49,395.09Z"
        style="fill: #fff; opacity: 0.2"
      ></path>
      <path
        d="M134.51,388.84c-.83.83-5,3.66-5.83,2.83s2-5,2.83-5.84a2.12,2.12,0,1,1,3,3Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M134.51,388.84c-.83.83-5,3.66-5.83,2.83s2-5,2.83-5.84a2.12,2.12,0,1,1,3,3Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M121.29,396.06c.83-.83,5-3.66,5.83-2.83s-2,5-2.83,5.83a2.12,2.12,0,0,1-3-3Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M121.29,396.06c.83-.83,5-3.66,5.83-2.83s-2,5-2.83,5.83a2.12,2.12,0,0,1-3-3Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M128.08,384.91c.31,1.14.67,6.17-.46,6.47s-3.34-4.23-3.64-5.37a2.12,2.12,0,0,1,4.1-1.1Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M128.08,384.91c.31,1.14.67,6.17-.46,6.47s-3.34-4.23-3.64-5.37a2.12,2.12,0,0,1,4.1-1.1Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M127.72,400c-.31-1.13-.67-6.17.47-6.47s3.33,4.24,3.63,5.37a2.12,2.12,0,1,1-4.1,1.1Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M127.72,400c-.31-1.13-.67-6.17.47-6.47s3.33,4.24,3.63,5.37a2.12,2.12,0,1,1-4.1,1.1Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M121.47,388.52c1.13.31,5.67,2.51,5.37,3.64s-5.34.77-6.47.47a2.13,2.13,0,1,1,1.1-4.11Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M121.47,388.52c1.13.31,5.67,2.51,5.37,3.64s-5.34.77-6.47.47a2.13,2.13,0,1,1,1.1-4.11Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M134.33,396.37c-1.13-.3-5.67-2.5-5.37-3.64s5.34-.77,6.47-.47a2.13,2.13,0,1,1-1.1,4.11Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M134.33,396.37c-1.13-.3-5.67-2.5-5.37-3.64s5.34-.77,6.47-.47a2.13,2.13,0,1,1-1.1,4.11Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M129.93,394.48a2.87,2.87,0,1,1,0-4.07A2.87,2.87,0,0,1,129.93,394.48Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M129.93,394.48a2.87,2.87,0,1,1,0-4.07A2.87,2.87,0,0,1,129.93,394.48Z"
        style="fill: #fff; opacity: 0.2"
      ></path>
      <path
        d="M147.84,407.87c-.54.53-3.23,2.36-3.76,1.82s1.29-3.22,1.82-3.76a1.38,1.38,0,0,1,1.94,0A1.36,1.36,0,0,1,147.84,407.87Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M147.84,407.87c-.54.53-3.23,2.36-3.76,1.82s1.29-3.22,1.82-3.76a1.38,1.38,0,0,1,1.94,0A1.36,1.36,0,0,1,147.84,407.87Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M139.31,412.52c.53-.53,3.23-2.36,3.76-1.82s-1.29,3.23-1.82,3.76a1.37,1.37,0,1,1-1.94-1.94Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M139.31,412.52c.53-.53,3.23-2.36,3.76-1.82s-1.29,3.23-1.82,3.76a1.37,1.37,0,1,1-1.94-1.94Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M143.69,405.34c.2.73.43,4-.3,4.17s-2.15-2.73-2.35-3.46a1.37,1.37,0,0,1,2.65-.71Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M143.69,405.34c.2.73.43,4-.3,4.17s-2.15-2.73-2.35-3.46a1.37,1.37,0,0,1,2.65-.71Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M143.46,415.05c-.2-.73-.43-4,.3-4.17s2.15,2.73,2.34,3.46a1.37,1.37,0,1,1-2.64.71Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M143.46,415.05c-.2-.73-.43-4,.3-4.17s2.15,2.73,2.34,3.46a1.37,1.37,0,1,1-2.64.71Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M139.43,407.67c.73.19,3.65,1.61,3.46,2.34s-3.44.5-4.17.3a1.37,1.37,0,1,1,.71-2.64Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M139.43,407.67c.73.19,3.65,1.61,3.46,2.34s-3.44.5-4.17.3a1.37,1.37,0,1,1,.71-2.64Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M147.72,412.73c-.73-.2-3.66-1.62-3.46-2.35s3.44-.5,4.17-.3a1.37,1.37,0,0,1-.71,2.65Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M147.72,412.73c-.73-.2-3.66-1.62-3.46-2.35s3.44-.5,4.17-.3a1.37,1.37,0,0,1-.71,2.65Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M144.89,411.51a1.86,1.86,0,1,1,0-2.62A1.87,1.87,0,0,1,144.89,411.51Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M144.89,411.51a1.86,1.86,0,1,1,0-2.62A1.87,1.87,0,0,1,144.89,411.51Z"
        style="fill: #fff; opacity: 0.2"
      ></path>
      <path
        d="M139.29,397.2c0,1.05-.86,5.5-1.91,5.5s-1.91-4.45-1.91-5.5a1.91,1.91,0,0,1,3.82,0Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M139.29,397.2c0,1.05-.86,5.5-1.91,5.5s-1.91-4.45-1.91-5.5a1.91,1.91,0,0,1,3.82,0Z"
        style="fill: #fff; opacity: 0.6000000000000001"
      ></path>
      <path
        d="M135.47,412.42c0-1.05.86-5.5,1.91-5.5s1.91,4.45,1.91,5.5a1.91,1.91,0,0,1-3.82,0Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M135.47,412.42c0-1.05.86-5.5,1.91-5.5s1.91,4.45,1.91,5.5a1.91,1.91,0,0,1-3.82,0Z"
        style="fill: #fff; opacity: 0.6000000000000001"
      ></path>
      <path
        d="M145,406.72c-1.05,0-5.5-.85-5.5-1.91s4.45-1.91,5.5-1.91a1.91,1.91,0,0,1,0,3.82Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M145,406.72c-1.05,0-5.5-.85-5.5-1.91s4.45-1.91,5.5-1.91a1.91,1.91,0,0,1,0,3.82Z"
        style="fill: #fff; opacity: 0.6000000000000001"
      ></path>
      <path
        d="M129.77,402.9c1,0,5.5.86,5.5,1.91s-4.45,1.91-5.5,1.91a1.91,1.91,0,1,1,0-3.82Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M129.77,402.9c1,0,5.5.86,5.5,1.91s-4.45,1.91-5.5,1.91a1.91,1.91,0,1,1,0-3.82Z"
        style="fill: #fff; opacity: 0.6000000000000001"
      ></path>
      <path
        d="M144.11,400.78c-.74.74-4.49,3.28-5.24,2.54s1.8-4.5,2.54-5.24a1.91,1.91,0,1,1,2.7,2.7Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M144.11,400.78c-.74.74-4.49,3.28-5.24,2.54s1.8-4.5,2.54-5.24a1.91,1.91,0,1,1,2.7,2.7Z"
        style="fill: #fff; opacity: 0.6000000000000001"
      ></path>
      <path
        d="M130.65,408.84c.74-.74,4.49-3.28,5.24-2.54s-1.8,4.5-2.54,5.24a1.91,1.91,0,0,1-2.7-2.7Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M130.65,408.84c.74-.74,4.49-3.28,5.24-2.54s-1.8,4.5-2.54,5.24a1.91,1.91,0,0,1-2.7-2.7Z"
        style="fill: #fff; opacity: 0.6000000000000001"
      ></path>
      <path
        d="M141.41,411.54c-.74-.74-3.28-4.49-2.54-5.24s4.5,1.8,5.24,2.54a1.91,1.91,0,0,1-2.7,2.7Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M141.41,411.54c-.74-.74-3.28-4.49-2.54-5.24s4.5,1.8,5.24,2.54a1.91,1.91,0,0,1-2.7,2.7Z"
        style="fill: #fff; opacity: 0.6000000000000001"
      ></path>
      <path
        d="M133.35,398.08c.74.74,3.28,4.49,2.54,5.24s-4.5-1.8-5.24-2.54a1.91,1.91,0,1,1,2.7-2.7Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M133.35,398.08c.74.74,3.28,4.49,2.54,5.24s-4.5-1.8-5.24-2.54a1.91,1.91,0,1,1,2.7-2.7Z"
        style="fill: #fff; opacity: 0.6000000000000001"
      ></path>
      <path
        d="M140.64,404.81a3.26,3.26,0,1,1-3.26-3.25A3.26,3.26,0,0,1,140.64,404.81Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M140.64,404.81a3.26,3.26,0,1,1-3.26-3.25A3.26,3.26,0,0,1,140.64,404.81Z"
        style="fill: #fff; opacity: 0.2"
      ></path>
      <path
        d="M327.05,405.26c-.58.58-3.5,2.56-4.08,2s1.4-3.5,2-4.08a1.48,1.48,0,0,1,2.1,0A1.5,1.5,0,0,1,327.05,405.26Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M327.05,405.26c-.58.58-3.5,2.56-4.08,2s1.4-3.5,2-4.08a1.48,1.48,0,0,1,2.1,0A1.5,1.5,0,0,1,327.05,405.26Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M317.81,410.3c.58-.58,3.49-2.56,4.07-2s-1.39,3.5-2,4.08a1.48,1.48,0,1,1-2.1-2.1Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M317.81,410.3c.58-.58,3.49-2.56,4.07-2s-1.39,3.5-2,4.08a1.48,1.48,0,1,1-2.1-2.1Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M322.56,402.52c.21.79.46,4.3-.33,4.52s-2.33-3-2.54-3.75a1.49,1.49,0,0,1,2.87-.77Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M322.56,402.52c.21.79.46,4.3-.33,4.52s-2.33-3-2.54-3.75a1.49,1.49,0,0,1,2.87-.77Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M322.3,413c-.21-.79-.46-4.31.33-4.52s2.33,3,2.54,3.75a1.49,1.49,0,1,1-2.87.77Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M322.3,413c-.21-.79-.46-4.31.33-4.52s2.33,3,2.54,3.75a1.49,1.49,0,1,1-2.87.77Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M317.94,405c.79.21,4,1.75,3.75,2.54s-3.73.54-4.52.33a1.49,1.49,0,1,1,.77-2.87Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M317.94,405c.79.21,4,1.75,3.75,2.54s-3.73.54-4.52.33a1.49,1.49,0,1,1,.77-2.87Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M326.92,410.52c-.79-.21-4-1.75-3.75-2.54s3.72-.54,4.52-.33a1.49,1.49,0,0,1-.77,2.87Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M326.92,410.52c-.79-.21-4-1.75-3.75-2.54s3.72-.54,4.52-.33a1.49,1.49,0,0,1-.77,2.87Z"
        style="fill: #fff; opacity: 0.8"
      ></path>
      <path
        d="M323.85,409.2a2,2,0,1,1,0-2.84A2,2,0,0,1,323.85,409.2Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M323.85,409.2a2,2,0,1,1,0-2.84A2,2,0,0,1,323.85,409.2Z"
        style="fill: #fff; opacity: 0.2"
      ></path>
    </g>
    <g id="freepik--character-2--inject-14">
      <path
        d="M348.76,315.34s-5.7-1.1-6.66-.11-.94.6-1.49,1.65a5.19,5.19,0,0,0,.1,2.14l4.43.13c.58,0,5.19-.06,5.11-2.28C350.25,316.87,349.47,315.62,348.76,315.34Z"
        style="fill: #ff8b7b"
      ></path>
      <path
        d="M379.22,307.27s0,0,0,0c-.41,1.17-1.23,3.82-2.09,6.57h0c-1.07,3.4-2.25,6.51-3.14,7.63-1.66,2.07-25.09-3-25.09-3l-.62-3.28s21,1.83,22.06-.92c3.18-8,1.68-7.78,3.37-12.7,1.3-3.77,4.93-4.54,6.62-1.23C380.88,301.5,380.86,302.52,379.22,307.27Z"
        style="fill: #ff8b7b"
      ></path>
      <polygon
        points="361.92 408.87 355.31 409.78 348.56 396.61 355.87 395.39 361.92 408.87"
        style="fill: #ff8b7b"
      ></polygon>
      <polygon
        points="355.87 395.39 348.56 396.61 352.24 403.78 359.17 402.73 355.87 395.39"
        style="isolation: isolate; opacity: 0.2"
      ></polygon>
      <path
        d="M353.39,407.28l8.17-.45a.57.57,0,0,1,.6.43l1.65,5.92a1,1,0,0,1-.68,1.21h-.23c-2.65.1-4.56,0-7.91.22-2,.11-8.2.67-11,.82s-3.34-2.62-2.2-2.94c5.12-1.41,8.93-3.22,10.52-4.77A1.57,1.57,0,0,1,353.39,407.28Z"
        style="fill: #263238"
      ></path>
      <path
        d="M351.23,408.58a2,2,0,0,1-1.31-.27.88.88,0,0,1-.38-.89.48.48,0,0,1,.25-.46c.72-.42,3,.8,3.22.94a.15.15,0,0,1,.08.15.16.16,0,0,1-.11.14A8.06,8.06,0,0,1,351.23,408.58Zm-1.07-1.33a.3.3,0,0,0-.21,0,.26.26,0,0,0-.09.19.64.64,0,0,0,.25.56,3.19,3.19,0,0,0,2.39,0,6.49,6.49,0,0,0-2.34-.75Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M352.94,408.25h-.08c-.71-.34-2.13-1.75-2-2.46a.53.53,0,0,1,.49-.45.89.89,0,0,1,.69.17c.82.59,1.05,2.52,1.05,2.61a.14.14,0,0,1-.06.15Zm-1.5-2.65h-.08c-.2,0-.21.13-.22.18-.07.44.86,1.52,1.58,2a3.8,3.8,0,0,0-.92-2.06.53.53,0,0,0-.36-.13Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M394.75,365.89l-4.85.18A119,119,0,0,1,366,362.52a119.15,119.15,0,0,0-16.17-2.35c3.73,16,10.45,40.3,10.45,40.3l-10.39,1.2s-27.05-51.54-15.11-57.44c10.66-5.69,47.82-1.63,47.82-1.63l1.34-1.41,25.53-4.39S427.08,363,394.75,365.89Z"
        style="fill: #263238"
      ></path>
      <polygon
        points="349.25 403.77 361.14 400.17 359.71 395.21 346.83 400.11 349.25 403.77"
        v-bind:style="`fill: ${hexColor}`"
      ></polygon>
      <polygon
        points="349.25 403.77 361.14 400.17 359.71 395.21 346.83 400.11 349.25 403.77"
        style="fill: #fff; opacity: 0.5"
      ></polygon>
      <path
        d="M390.7,354.68c-5.9,6.74-24.71,7.54-27.78,7.12,13.26,3.5,21.34,4.09,26.52,4.33l4.85-.18a30.2,30.2,0,0,0,4.5-.33C396.55,351,394.69,350.12,390.7,354.68Z"
        style="opacity: 0.4"
      ></path>
      <polygon
        points="426.53 395.59 427.39 402.27 411.97 404.79 411.12 398.09 426.53 395.59"
        style="fill: #ff8b7b"
      ></polygon>
      <path
        d="M426,403.2l-4.79-6.68a.59.59,0,0,1,0-.73l4.1-4.58a1,1,0,0,1,1.4-.07.72.72,0,0,1,.11.19c1.51,2.17,2.49,3.81,4.45,6.53,1.19,1.67,5,6.57,6.62,8.86s-.41,4.22-1.29,3.44c-3.95-3.56-7.5-5.82-9.68-6.3A1.57,1.57,0,0,1,426,403.2Z"
        style="fill: #263238"
      ></path>
      <path
        d="M428.28,404.32a1.92,1.92,0,0,1,.47,1.25.9.9,0,0,1-.49.77.51.51,0,0,1-.53,0c-.75-.38-.93-2.92-.94-3.21a.14.14,0,0,1,.08-.16.17.17,0,0,1,.18,0A8.85,8.85,0,0,1,428.28,404.32Zm-.55,1.63a.32.32,0,0,0,.16.14s.1,0,.21,0a.66.66,0,0,0,.34-.52,3.2,3.2,0,0,0-1.31-2A6.73,6.73,0,0,0,427.73,406Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M427.08,403.06l0,.07c.09.78-.32,2.74-1,3a.53.53,0,0,1-.64-.17.88.88,0,0,1-.23-.67c.06-1,1.53-2.27,1.61-2.33a.18.18,0,0,1,.16,0A.12.12,0,0,1,427.08,403.06Zm-1.43,2.69,0,.07a.19.19,0,0,0,.27.1,3.89,3.89,0,0,0,.83-2.41,3.89,3.89,0,0,0-1.25,1.88A.57.57,0,0,0,425.65,405.75Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <polygon
        points="411.13 398.09 411.98 404.79 419.93 403.48 419.07 396.8 411.13 398.09"
        style="isolation: isolate; opacity: 0.2"
      ></polygon>
      <path
        d="M391.82,350.9c-2.34,3.72-39.37,46.29-24.23,56.3,11.16,7.36,49.9-.82,49.9-.82l-1.12-11.12s-27.89.93-32.05,2.46c0,0,34-38.8,29.05-46.53C408.74,343.89,391.82,350.9,391.82,350.9Z"
        style="fill: #263238"
      ></path>
      <polygon
        points="418.28 407.19 417.89 394.77 412.74 394.86 414.11 408.58 418.28 407.19"
        v-bind:style="`fill: ${hexColor}`"
      ></polygon>
      <polygon
        points="418.28 407.19 417.89 394.77 412.74 394.86 414.11 408.58 418.28 407.19"
        style="fill: #fff; opacity: 0.5"
      ></polygon>
      <path
        d="M377,308.1c-1.46-1.4-3-2.88-3.6-4.81s1.28-4.19,3.15-5C377.12,301.58,376.49,304.83,377,308.1Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M377,308.1c-1.46-1.4-3-2.88-3.6-4.81s1.28-4.19,3.15-5C377.12,301.58,376.49,304.83,377,308.1Z"
        style="fill: #fff; opacity: 0.5"
      ></path>
      <path
        d="M381.92,341.67c.32-1.78-1.95-2.64.29-3.82,3-1.57,7.18-4.82,20-6.88,6-1,7.9,3.39,8.34,5.83a1.88,1.88,0,0,1-.1,1l-.15.42c-.54.37-27.68,4.19-27.9,4.42A1,1,0,0,1,381.92,341.67Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M381.92,341.67c.32-1.78-1.95-2.64.29-3.82,3-1.57,7.18-4.82,20-6.88,6-1,7.9,3.39,8.34,5.83a1.88,1.88,0,0,1-.1,1l-.15.42c-.54.37-27.68,4.19-27.9,4.42A1,1,0,0,1,381.92,341.67Z"
        style="fill: #fff; opacity: 0.2"
      ></path>
      <g id="freepik--group--inject-14">
        <path
          d="M376.59,298.28c-.08.36-2.88,8.36-1.94,13.58a76,76,0,0,0,2.81,11.56c1,2.89,4,16.84,4,16.84h0L410,335.1a6.48,6.48,0,0,0-1.65-5.27s-4.32-9.06-3.65-18.92c1.11-16.33-2.13-17.47-2.1-17.43s0,0,0,0a72.39,72.39,0,0,0-10.4.69,77.1,77.1,0,0,0-10.86,2.2A21.09,21.09,0,0,0,376.59,298.28Z"
          v-bind:style="`fill: ${hexColor}`"
        ></path>
        <path
          d="M376.59,298.28c-.08.36-2.88,8.36-1.94,13.58a76,76,0,0,0,2.81,11.56c1,2.89,4,16.84,4,16.84h0L410,335.1a6.48,6.48,0,0,0-1.65-5.27s-4.32-9.06-3.65-18.92c1.11-16.33-2.13-17.47-2.1-17.43s0,0,0,0a72.39,72.39,0,0,0-10.4.69,77.1,77.1,0,0,0-10.86,2.2A21.09,21.09,0,0,0,376.59,298.28Z"
          style="fill: #fff; opacity: 0.4"
        ></path>
        <path
          d="M388.64,282.12c0,3.65.63,10.19,3.55,12L391,296.38c-2,3.92-8.63,11.72-9.7,0h0c3.69-1.72,1.75-5.37,1.37-9.33Z"
          style="fill: #ff8b7b"
        ></path>
        <path
          d="M379.56,283.72c1.58,2.46,3.91,6.47,4,9.69a6.28,6.28,0,0,0,4.81-5.56,26,26,0,0,1-2.06-3.36Z"
          style="isolation: isolate; opacity: 0.2"
        ></path>
        <path
          d="M388.14,276.56c1.24,5.85,1.89,8.29-.2,12-3.13,5.64-10.58,5.88-13.54.53-2.66-4.82-4.24-13.56.76-17.4a8.14,8.14,0,0,1,13,4.83Z"
          style="fill: #ff8b7b"
        ></path>
        <path
          d="M388.35,279.55c-.2.9-.76,2.27-.49,3.14-.64-.11-.95-.88-1-1.53S387.41,279.35,388.35,279.55Z"
          style="fill: #263238"
        ></path>
        <path
          d="M389.94,280.55a21.24,21.24,0,0,0-1.05-5.87,11.23,11.23,0,0,0-3.26-4.88,3.33,3.33,0,0,0-2.58-1c-.86.15-2,.79-1.89,1.66C381.88,277.23,388.77,280.92,389.94,280.55Z"
          style="fill: #263238"
        ></path>
        <path
          d="M382.76,267.85c5.72-1.72,8.46,5.34,10.35,8.57,1.37,2.36,1.61,3.1,1.48,5.82-.16,3.58-3.6,5.37-2.73,8.84-2.21-.65-4.12-3-3.8-5.3.27-1.94,1.71-3.86,1-5.69-.46-1.15-1.63-1.82-2.58-2.62C384.19,275.53,382.21,270.79,382.76,267.85Z"
          style="fill: #263238"
        ></path>
        <path
          d="M383.34,268.09a10.4,10.4,0,0,0-9.5.72c-2.7,1.51-3.65,5.34-3.13,8.42,5.32.27,9.74-3.18,12.9-6.41a2,2,0,0,0,.73-1.32A1.68,1.68,0,0,0,383.34,268.09Z"
          style="fill: #263238"
        ></path>
        <path
          d="M382.45,278.71s0,.07.1.08l.3,0c.06,0,.11,0,.11,0s0-.06-.09-.07l-.3-.06C382.51,278.65,382.46,278.67,382.45,278.71Z"
          style="fill: #263238"
        ></path>
        <path
          d="M382.68,278.67l1.31-.11,8.9,1.53c.3.05.52.21.5.36l-.05.25c0,.15-.29.23-.58.18l-8.9-1.52-1.21-.54Z"
          v-bind:style="`fill: ${hexColor}`"
        ></path>
        <rect
          x="387.83"
          y="275.33"
          width="0.81"
          height="8.74"
          transform="translate(47.03 615.16) rotate(-80.29)"
          style="fill: #fff; opacity: 0.5"
        ></rect>
        <path
          d="M391.73,281.49a4.36,4.36,0,0,1-2,2.68c-1.32.77-2.37-.31-2.33-1.76s.78-3.26,2.24-3.47a2,2,0,0,1,2.08,2.55Z"
          style="fill: #ff8b7b"
        ></path>
        <path
          d="M379.37,280.65c.18.48.06,1-.25,1.09s-.73-.18-.91-.67,0-1,.26-1.1S379.2,280.16,379.37,280.65Z"
          style="fill: #263238"
        ></path>
        <path
          d="M373.83,282.66c.17.49.06,1-.26,1.1s-.73-.19-.9-.69-.06-1,.25-1.09S373.67,282.15,373.83,282.66Z"
          style="fill: #263238"
        ></path>
        <path
          d="M373.08,282l-1.28.12S372.69,282.72,373.08,282Z"
          style="fill: #263238"
        ></path>
        <path
          d="M375.74,282.53a14.2,14.2,0,0,1-.9,3.7,2.22,2.22,0,0,0,1.88-.16Z"
          style="fill: #ff5652"
        ></path>
        <path
          d="M379,287.85a5.28,5.28,0,0,1-.8.53.14.14,0,0,1-.18-.05h0a.15.15,0,0,1,.06-.17,4.13,4.13,0,0,0,2.24-3,.11.11,0,0,1,.11-.13.11.11,0,0,1,.13.09v0A4.21,4.21,0,0,1,379,287.85Z"
          style="fill: #263238"
        ></path>
        <path
          d="M380.65,276.39a.21.21,0,0,1-.22,0,2.12,2.12,0,0,0-1.94-.08.24.24,0,0,1-.31-.08.21.21,0,0,1,0-.3h0a2.54,2.54,0,0,1,2.35.07.26.26,0,0,1,.14.3A.16.16,0,0,1,380.65,276.39Z"
          style="fill: #263238"
        ></path>
        <path
          d="M371.57,279.3a.26.26,0,0,1-.38-.31h0a3,3,0,0,1,2.12-1.75.25.25,0,0,1,.29.19v0a.25.25,0,0,1-.21.29h0a2.46,2.46,0,0,0-1.73,1.45A.24.24,0,0,1,371.57,279.3Z"
          style="fill: #263238"
        ></path>
        <path
          d="M378.62,279.94l-1.28.12S378.26,280.73,378.62,279.94Z"
          style="fill: #263238"
        ></path>
      </g>
      <path
        d="M365.42,334.36,359,336.78l3.72,4.56a31.74,31.74,0,0,0,4.34-4.24Z"
        style="fill: #ff8b7b"
      ></path>
      <path
        d="M357.63,341.41l.16.16a2.32,2.32,0,0,0,2.31.57l2.61-.8L359,336.78l-1.36,1.36A2.32,2.32,0,0,0,357.63,341.41Z"
        style="fill: #ff8b7b"
      ></path>
      <path
        d="M367.66,336.73s13.66-5.83,23.08-13.86c0,0,8.25-10.47,11.7-16.17,5.07-8.38,1.78-18.91-10.6,1.85-2.56,4.3-5.88,9.72-5.88,9.72-7.83,7.92-21.27,16.6-21.27,16.6C363.27,338.88,367.66,336.73,367.66,336.73Z"
        style="fill: #ff8b7b"
      ></path>
      <path
        d="M402.24,293.39c-3.94,0-5.29,3.45-5.22,7.39,1.59-1.44,5-2,5.77-1.38s2.33,2.38.67,5.88C408.09,300.64,407.6,293.44,402.24,293.39Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M402.24,293.39c-3.94,0-5.29,3.45-5.22,7.39,1.59-1.44,5-2,5.77-1.38s2.33,2.38.67,5.88C408.09,300.64,407.6,293.44,402.24,293.39Z"
        style="fill: #fff; opacity: 0.5"
      ></path>
      <g style="opacity: 0.30000000000000004">
        <path
          d="M412.34,341.62c-5.94,1.1-11.76,2.35-17.72,2.64a12.61,12.61,0,0,0-7.1-2.51c7.68-1.11,15.2-2.91,22.88-4C411,339.3,411.77,340.09,412.34,341.62Z"
          style="fill: #fff"
        ></path>
      </g>
      <path
        d="M410.52,377.59h-.3a1.3,1.3,0,0,1-1.39-1.23l1.67-37.23a.84.84,0,0,1,.94-.7h0a.85.85,0,0,1,.9.76L412,376.45A1.3,1.3,0,0,1,410.52,377.59Z"
        style="fill: #263238"
      ></path>
      <rect
        x="403.87"
        y="339.63"
        width="14.51"
        height="7.44"
        rx="2.67"
        transform="translate(-8.69 10.68) rotate(-1.47)"
        style="fill: #263238"
      ></rect>
      <rect
        x="403.87"
        y="339.63"
        width="14.51"
        height="7.44"
        rx="2.67"
        transform="translate(-8.69 10.68) rotate(-1.47)"
        style="fill: #fff; opacity: 0.5"
      ></rect>
      <path
        d="M416,367.67c-.54-6.43-1.44-13.34-2-19.76a64.21,64.21,0,0,0-9.81-.19,56.37,56.37,0,0,0,3.05,23,32,32,0,0,0,8.91-1.78C416.16,368.77,416,367.87,416,367.67Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M416,367.67c-.54-6.43-1.44-13.34-2-19.76a64.21,64.21,0,0,0-9.81-.19,56.37,56.37,0,0,0,3.05,23,32,32,0,0,0,8.91-1.78C416.16,368.77,416,367.87,416,367.67Z"
        style="fill: #fff; opacity: 0.4"
      ></path>
      <path
        d="M404.08,354.18c-5-.87-5.76-3.05-9.92-6-3.6-2.52-8.06-5.46-12.46-5.6,2.2-.15,2.72-.62,4.92-.82.31,0,.61,0,.9,0a12.61,12.61,0,0,1,7.1,2.51,20.18,20.18,0,0,1,2.18,1.79c2.74,2.54,3.54,3.66,7.27,3.94C404.25,350.88,403.9,353.29,404.08,354.18Z"
        style="fill: #fff; opacity: 0.30000000000000004"
      ></path>
    </g>
    <g id="freepik--character-1--inject-14">
      <polygon
        points="96.9 411.24 90.28 411.12 87.82 398.35 95.37 396.97 96.9 411.24"
        style="fill: #b55b52"
      ></polygon>
      <polygon
        points="96.12 403.3 95.41 397.45 92.03 397.58 87.82 398.35 88.87 403.91 96.12 403.3"
        style="opacity: 0.1"
      ></polygon>
      <path
        d="M98,409.32a.17.17,0,0,0,.13.13h0a14.18,14.18,0,0,0,1.6.12h.59a3.17,3.17,0,0,0,2-.57.56.56,0,0,0-.07-.69.94.94,0,0,0-.55-.35,4.29,4.29,0,0,0-2.13.43c-.34.14-.65.29-.92.43s-.61.33-.64.35A.14.14,0,0,0,98,409.32Zm.67-.1.4-.21a7.76,7.76,0,0,1,2.29-.78l.33,0a.49.49,0,0,1,.34.21c.15.19.11.29.07.34-.25.37-1.53.48-2.72.45Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M98.05,408.59a4.9,4.9,0,0,0-.08.72.14.14,0,0,0,.08.13s0,0,.08,0h0a.09.09,0,0,0,.07,0A3.79,3.79,0,0,0,99,409a5.87,5.87,0,0,0,.65-.58c.53-.55.94-1.16.83-1.56,0-.17-.19-.39-.66-.41a1.27,1.27,0,0,0-.91.3A3.17,3.17,0,0,0,98.05,408.59Zm.33.09a3.4,3.4,0,0,1,.76-1.76,1,1,0,0,1,.57-.21h.09c.31,0,.34.13.35.16.07.4-.73,1.35-1.5,1.91a2.37,2.37,0,0,1-.3.21A1.39,1.39,0,0,1,98.38,408.68Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M97.35,408.55l-7.75.27a.58.58,0,0,0-.57.48l-1,5.92a1,1,0,0,0,.86,1.14h.22c2.79-.14,5.26-.37,8.74-.49,4.11-.14,3.1.35,7.93.24,2.91-.06,3.22-3.16,2-3.27-5.69-.46-6.39-2-8.71-3.83A2.69,2.69,0,0,0,97.35,408.55Z"
        style="fill: #263238"
      ></path>
      <polygon
        points="50.16 404.64 44.2 402.76 45.9 391.23 53.66 392 50.16 404.64"
        style="fill: #b55b52"
      ></polygon>
      <polygon
        points="53.66 392 45.9 391.23 44.91 397.93 51.53 399.49 53.66 392"
        style="opacity: 0.1"
      ></polygon>
      <polygon
        points="43.06 396.06 54.18 397.66 55.39 393.39 42.92 391.75 43.06 396.06"
        v-bind:style="`fill: ${hexColor}`"
      ></polygon>
      <polygon
        points="43.06 396.06 54.18 397.66 55.39 393.39 42.92 391.75 43.06 396.06"
        style="fill: #fff; opacity: 0.5"
      ></polygon>
      <path
        d="M51.27,404.42a.18.18,0,0,0,.08.17h0a12.64,12.64,0,0,0,1.41.63l.55.19a3,3,0,0,0,2.11.1.62.62,0,0,0,.2-.69.81.81,0,0,0-.38-.52A3.85,3.85,0,0,0,53.1,404a8.35,8.35,0,0,0-1,.11c-.41.06-.69.13-.73.14S51.27,404.36,51.27,404.42Zm.66.12a3.5,3.5,0,0,1,.45-.07,7.22,7.22,0,0,1,2.41,0,1.85,1.85,0,0,1,.29.13.52.52,0,0,1,.24.32c0,.24,0,.32-.08.35-.37.28-1.59,0-2.67-.44Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M51.27,404.56a.11.11,0,0,0,.08,0h0a.06.06,0,0,0,.07,0,3.28,3.28,0,0,0,.88-.23,7.39,7.39,0,0,0,.78-.34c.71-.36,1.34-.83,1.39-1.27,0-.18,0-.44-.46-.62a1.21,1.21,0,0,0-1,0,3.69,3.69,0,0,0-1.5,1.58,5.28,5.28,0,0,0-.33.68A.16.16,0,0,0,51.27,404.56Zm.8-.46-.35.11a2.1,2.1,0,0,1,.15-.3,3.81,3.81,0,0,1,1.36-1.46.86.86,0,0,1,.61,0l.09,0c.27.12.26.24.26.28C54.1,403.12,53,403.8,52.07,404.1Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M51,403.45l-7.25-2.25a.6.6,0,0,0-.71.28L39.85,407a1,1,0,0,0,.37,1.39.72.72,0,0,0,.2.09c2.62.77,5,1.35,8.25,2.36,3.84,1.2,2.72,1.36,7.22,2.82,2.71.89,4.17-2.05,3.06-2.56-5.07-2.31-5.13-4.07-6.57-6.6A2.49,2.49,0,0,0,51,403.45Z"
        style="fill: #263238"
      ></path>
      <path
        d="M95.52,253.78l1.36,144.87L86.55,399c-4.44-19-7.13-33.55-7.46-44.25-.45-14.9-.26-15.37-.89-21.56-.74-7.36-3.15-14.31-3.44-20.43-1.56-32-1.42-59.72-1.42-59.72Z"
        style="fill: #263238"
      ></path>
      <path
        d="M71.75,281.58c-.19,6.35-.16,14,.27,22.94.14,2.91,3.15,12.1,4.1,19.33,9-23,7.06-53.18,7.06-53.18Z"
        style="opacity: 0.30000000000000004"
      ></path>
      <path
        d="M73.43,331.47,54.11,393.42l-10.17-1.33s2.6-24.18,3.75-37.47c.76-8.69,6.63-19.07,7.58-26.45.15-1.23.35-2.48.46-3.77,4.84-57,10.21-72.73,10.21-72.73l22.79,2.53Z"
        style="fill: #263238"
      ></path>
      <polygon
        points="86.52 401.78 97.69 400.66 97.84 396.23 85.34 397.62 86.52 401.78"
        v-bind:style="`fill: ${hexColor}`"
      ></polygon>
      <polygon
        points="86.52 401.78 97.69 400.66 97.84 396.23 85.34 397.62 86.52 401.78"
        style="fill: #fff; opacity: 0.5"
      ></polygon>
      <path
        d="M118.12,217.58c1-.12,2.42-4.63,2.42-4.63l2.36-.36c.94-.06.63-1.36.27-1.61l-.83-2.16c-.21-.14-.09-.94-1.22-.54l-3.46.45c-.57.74-1,.65-1.6,1.8a7.33,7.33,0,0,0-.78,3.38v1.41Z"
        style="fill: #b55b52"
      ></path>
      <path
        d="M115.35,215.23l3.6,1.51s-5,16.89-10,20.09c-3.27,2.1-13.91-21.3-15.42-28.78l4.29.75a8.2,8.2,0,0,1,6.44,5.67l4.11,11.58Z"
        style="fill: #b55b52"
      ></path>
      <path
        d="M80.22,191.3a4.64,4.64,0,0,0-4.89,2.42c.12,0,.13.27.24.27a1,1,0,0,0,.63.8,2.08,2.08,0,0,0,1,.08,5.11,5.11,0,0,0,3.24-1.78,5.47,5.47,0,0,0-.41,5.94c.67-.28.87-1.19,1.11-1.87a7.86,7.86,0,0,0,.23-4.58,2.08,2.08,0,0,0-.5-1C80.63,191.28,80.53,191.06,80.22,191.3Z"
        style="fill: #263238"
      ></path>
      <path
        d="M80.22,191.3a4.64,4.64,0,0,0-4.89,2.42c.12,0,.13.27.24.27a1,1,0,0,0,.63.8,2.08,2.08,0,0,0,1,.08,5.11,5.11,0,0,0,3.24-1.78,5.47,5.47,0,0,0-.41,5.94c.67-.28.87-1.19,1.11-1.87a7.86,7.86,0,0,0,.23-4.58,2.08,2.08,0,0,0-.5-1C80.63,191.28,80.53,191.06,80.22,191.3Z"
        style="fill: #fff; opacity: 0.4"
      ></path>
      <path
        d="M95.86,254.65c-.43-1.89.34-5.25-2-6.41-3.18-1.53-5.88-2.34-19.28-3.91a7.71,7.71,0,0,0-9,6.65,2.14,2.14,0,0,0,.14,1l.19.44c.57.37,29.38,3,29.62,3.22A1.06,1.06,0,0,0,95.86,254.65Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M95.86,254.65c-.43-1.89.34-5.25-2-6.41-3.18-1.53-5.88-2.34-19.28-3.91a7.71,7.71,0,0,0-9,6.65,2.14,2.14,0,0,0,.14,1l.19.44c.57.37,29.38,3,29.62,3.22A1.06,1.06,0,0,0,95.86,254.65Z"
        style="fill: #fff; opacity: 0.2"
      ></path>
      <g id="freepik--group--inject-14">
        <path
          d="M98.33,208.49c.12.38,5.69,2.12.2,27-.71,3.2-2.21,17.62-2.21,17.62l-.57.69h0l-13.54-1.46L65.93,250s.66-14.69.21-25.26c-.14-3.45-.21-7.66-.21-7.66a73.83,73.83,0,0,0,3.17-11.31s0,0,0,0a76.87,76.87,0,0,1,11.15-.18,83.4,83.4,0,0,1,11.78,1.4C95.69,207.74,98.33,208.48,98.33,208.49Z"
          v-bind:style="`fill: ${hexColor}`"
        ></path>
        <path
          d="M98.33,208.49c.12.38,5.69,2.12.2,27-.71,3.2-2.21,17.62-2.21,17.62l-.57.69h0l-13.54-1.46L65.93,250s.66-14.69.21-25.26c-.14-3.45-.21-7.66-.21-7.66a73.83,73.83,0,0,0,3.17-11.31s0,0,0,0a76.87,76.87,0,0,1,11.15-.18,83.4,83.4,0,0,1,11.78,1.4C95.69,207.74,98.33,208.48,98.33,208.49Z"
          style="fill: #fff; opacity: 0.4"
        ></path>
        <path
          d="M83,192.4c.29,3.89.22,10.92-2.74,13.14l1.44,2.27c4.84,5.06,8.53,3.5,10.35-.88h0c-4.09-1.51-2.34-5.57-2.28-9.83Z"
          style="fill: #b55b52"
        ></path>
        <path
          d="M92.83,193.32c-1.46,2.75-3.6,7.23-3.38,10.67a5.7,5.7,0,0,1-.62-.11,5.46,5.46,0,0,1-3.78-7.18c.25-.71.51-1.46.68-2Z"
          style="isolation: isolate; opacity: 0.2"
        ></path>
        <path
          d="M81.75,194.89a6.34,6.34,0,0,0,.34,2.25,2.24,2.24,0,0,0,1.66,1.44,7.36,7.36,0,0,0-.18-3.91,1.93,1.93,0,0,0-1.47-.9C82.1,193.87,81.71,193.89,81.75,194.89Z"
          style="fill: #263238"
        ></path>
        <path
          d="M83.06,186.43c-.81,6.34-1.29,9,1.26,12.82,3.84,5.74,11.8,5.35,14.48-.61,2.43-5.38,3.34-14.83-2.32-18.5a8.71,8.71,0,0,0-13.42,6.29Z"
          style="fill: #b55b52"
        ></path>
        <path
          d="M83.09,189.33c2.5,2.94.89,5.31,1.34,8,.09.56.75.79,1.31.69a31.77,31.77,0,0,1,1.53-14.09c-1.33-.21-2.66.91-3.37,2.06a10.19,10.19,0,0,0-1.15,3.85C82.83,189.62,83,189.51,83.09,189.33Z"
          style="fill: #263238"
        ></path>
        <path
          d="M85.31,186.32c13.42-8.07,15.2.76,15.2.76,1.71-1.63,2.2-6.8-1.58-11.1-2.37-2.69-6.6-3-10-2.2a12.9,12.9,0,0,0-4.72,2.16,14.88,14.88,0,0,0-5.52,8.11c-.89,3.26-.19,7.73,2.87,10.34C81.52,194.39,83.57,187.37,85.31,186.32Z"
          style="fill: #263238"
        ></path>
        <g style="opacity: 0.4">
          <path
            d="M85.31,186.32c13.42-8.07,15.2.76,15.2.76,1.71-1.63,2.2-6.8-1.58-11.1-2.37-2.69-6.6-3-10-2.2a12.9,12.9,0,0,0-4.72,2.16,14.88,14.88,0,0,0-5.52,8.11c-.89,3.26-.19,7.73,2.87,10.34C81.52,194.39,83.57,187.37,85.31,186.32Z"
            style="fill: #fff"
          ></path>
        </g>
        <path
          d="M79.66,192A4.69,4.69,0,0,0,82,194.68c1.47.71,2.5-.54,2.33-2.08-.15-1.39-1.12-3.41-2.69-3.51a2.12,2.12,0,0,0-2.16,2.06A2.25,2.25,0,0,0,79.66,192Z"
          style="fill: #b55b52"
        ></path>
        <path
          d="M92.42,190c-.11.54.1,1,.45,1.11s.74-.32.84-.86-.11-1-.45-1.12S92.52,189.41,92.42,190Z"
          style="fill: #263238"
        ></path>
        <path
          d="M98.61,191.14c-.1.55.11,1,.47,1.12s.73-.32.83-.88-.1-1-.46-1.11S98.7,190.57,98.61,191.14Z"
          style="fill: #263238"
        ></path>
        <path
          d="M96,191.78a14.81,14.81,0,0,0,1.29,3.87,2.38,2.38,0,0,1-2,0Z"
          style="fill: #a02724"
        ></path>
        <path
          d="M92.9,197.1a5.63,5.63,0,0,0,.9.49.15.15,0,0,0,.19-.07h0a.15.15,0,0,0-.08-.18,4.47,4.47,0,0,1-2.65-3,.13.13,0,0,0-.13-.13.11.11,0,0,0-.13.11v0A4.52,4.52,0,0,0,92.9,197.1Z"
          style="fill: #263238"
        ></path>
        <path
          d="M90.87,186.43a.22.22,0,0,0,.22,0,2.31,2.31,0,0,1,2.07-.25.25.25,0,0,0,.32-.12.21.21,0,0,0-.06-.31h0a2.74,2.74,0,0,0-2.51.28.28.28,0,0,0-.12.33A.18.18,0,0,0,90.87,186.43Z"
          style="fill: #263238"
        ></path>
        <path
          d="M100.8,188.73a.26.26,0,0,0,.27,0,.27.27,0,0,0,.1-.37h0a3.15,3.15,0,0,0-2.4-1.68.26.26,0,0,0-.3.23v0a.28.28,0,0,0,.26.29h0a2.64,2.64,0,0,1,2,1.4A.24.24,0,0,0,100.8,188.73Z"
          style="fill: #263238"
        ></path>
      </g>
      <path
        d="M73.34,208.5l6.45,17.77,26.09,6.63-1.37,4.24s-26.07-.71-30-4.76c-3-3-8.18-14.54-10.37-19.51a4.34,4.34,0,0,1,.9-4.81l1.16-1.16a4.34,4.34,0,0,1,5.36-.61h0A4.34,4.34,0,0,1,73.34,208.5Z"
        style="fill: #b55b52"
      ></path>
      <path
        d="M107.82,248.67l-.3-.06a1.52,1.52,0,0,1-1.16-1.84l7.18-30a1,1,0,0,1,1.16-.73h0a1,1,0,0,1,.75,1.14l-5.82,30.29A1.51,1.51,0,0,1,107.82,248.67Z"
        style="fill: #263238"
      ></path>
      <rect
        x="106.17"
        y="217.61"
        width="14.51"
        height="7.44"
        rx="2.67"
        transform="translate(35.61 -14.88) rotate(8.9)"
        style="fill: #263238"
      ></rect>
      <rect
        x="106.17"
        y="217.61"
        width="14.51"
        height="7.44"
        rx="2.67"
        transform="translate(35.61 -14.88) rotate(8.9)"
        style="fill: #fff; opacity: 0.5"
      ></rect>
      <path
        d="M104.51,237.14c.46.8,5.33.23,5.33.23l2.35.33a.79.79,0,0,0,.84-.63l1.34-4.5a.41.41,0,0,0-.31-.49l-2.2-.64c-1.13-.29-1.55-.74-3.48.23L104,233.72Z"
        style="fill: #b55b52"
      ></path>
      <g style="opacity: 0.30000000000000004">
        <path
          d="M62.88,255.52c5.94,1.1,11.76,2.35,17.72,2.64a12.61,12.61,0,0,1,7.1-2.51c-7.68-1.11-15.2-2.91-22.88-4C64.25,253.2,63.45,254,62.88,255.52Z"
          style="fill: #fff"
        ></path>
      </g>
      <path
        d="M59.24,281.56c.54-6.42,1.43-13.33,2-19.76a67,67,0,0,1,9.82-.19,56.66,56.66,0,0,1-3,23,31.75,31.75,0,0,1-8.92-1.77C59.06,282.67,59.22,281.76,59.24,281.56Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M59.24,281.56c.54-6.42,1.43-13.33,2-19.76a67,67,0,0,1,9.82-.19,56.66,56.66,0,0,1-3,23,31.75,31.75,0,0,1-8.92-1.77C59.06,282.67,59.22,281.76,59.24,281.56Z"
        style="fill: #fff; opacity: 0.4"
      ></path>
      <path
        d="M71.14,268.08c5-.87,5.76-3.05,9.92-6,3.6-2.52,10.11-3.27,14.51-3.41v-3.14c-.31,0-7.58.09-7.87.09a12.61,12.61,0,0,0-7.1,2.51A20.18,20.18,0,0,0,78.42,260c-2.74,2.54-3.54,3.66-7.27,3.94C71,264.78,71.32,267.19,71.14,268.08Z"
        style="fill: #fff; opacity: 0.30000000000000004"
      ></path>
    </g>
    <g id="freepik--Hashtag--inject-14">
      <polygon
        points="272.41 212.26 274.3 212.26 284.57 212.25 320.3 212.22 343.57 212.2 359.9 212.18 369.68 212.17 366.06 264.7 352.38 264.71 333.84 264.73 308.25 264.75 286.39 264.77 269.45 264.78 268.77 264.78 272.41 212.26"
        v-bind:style="`fill: ${hexColor}`"
      ></polygon>
      <polygon
        points="272.41 212.26 274.3 212.26 284.57 212.25 320.3 212.22 343.57 212.2 359.9 212.18 369.68 212.17 366.06 264.7 352.38 264.71 333.84 264.73 308.25 264.75 286.39 264.77 269.45 264.78 268.77 264.78 272.41 212.26"
        style="opacity: 0.1"
      ></polygon>
      <polygon
        points="369.68 212.17 336.91 264.78 352.38 264.71 362.18 250.86 360.29 264.26 366.06 264.7 369.68 212.17"
        style="opacity: 0.1"
      ></polygon>
      <path
        d="M302,264.71c-2.12-12.7-5-32.56-8.25-52.52l-14.29,0a257.87,257.87,0,0,1,9.4,52.51Z"
        style="opacity: 0.1"
      ></path>
      <path
        d="M323.82,212.16l-13.05,0c.4,10.6,1.34,19.15,3.37,19.5C316.41,232.06,320.19,223,323.82,212.16Z"
        style="opacity: 0.1"
      ></path>
      <path
        d="M341.82,212.14c-9.6,18.67-20.39,39.35-28.39,52.55h12.62c9-18.07,18.23-37,26.21-52.55Z"
        style="opacity: 0.1"
      ></path>
      <polygon
        points="328.19 167.84 325.88 200.88 324.94 214.34 324.09 226.5 323.1 240.69 321.45 264.2 320.5 277.8 319.2 296.4 318.3 309.28 317.3 323.56 316.67 332.62 315.86 344.3 313.22 382.04 311.52 406.3 310.83 416.18 258.18 416.18 258.37 413.44 259.75 393.76 261.65 366.5 263.29 343.23 264.47 326.31 264.94 319.62 265.49 311.65 267.24 286.64 268.63 266.85 269.98 247.56 270.99 233.02 272.41 212.77 273.18 201.64 274.42 184.07 275.17 173.22 275.55 167.84 328.19 167.84"
        v-bind:style="`fill: ${hexColor}`"
      ></polygon>
      <g style="opacity: 0.1">
        <path
          d="M324.09,226.5l-1,14.19A271.22,271.22,0,0,0,270,247.56L271,233C291.89,230.39,312,228.17,324.09,226.5Z"
        ></path>
        <path
          d="M263.29,343.23l1.18-16.92c6.28,1.57,11.33,3.46,13.71,5.7C282.58,336.14,274.13,340.15,263.29,343.23Z"
        ></path>
        <path
          d="M265.49,311.65c17.09,3.4,35.35,7.06,51.81,11.91l-.63,9.06c-9.67-3.9-29.4-8.22-51.73-13Z"
        ></path>
        <path
          d="M315.86,344.3,313.22,382a469.09,469.09,0,0,1-53.47,11.72l1.9-27.26C284.3,359.46,305.31,350.85,315.86,344.3Z"
        ></path>
        <path
          d="M286.84,210c-.36,1.72-6.3,2.49-14.43,2.73l.77-11.13C281.3,205,287.23,208.12,286.84,210Z"
        ></path>
        <path
          d="M293.61,281.77c-1,3.31,12,9.34,25.59,14.63l-.9,12.88c-15.37-6.18-36.24-14.94-51.06-22.64l1.39-19.79c14.41-1.64,34.57-2.39,52.82-2.65l-.95,13.6C306.34,278.07,294.43,279.11,293.61,281.77Z"
        ></path>
        <path
          d="M258.37,413.44c17.43-2.75,36.52-5.18,53.15-7.14l-.69,9.87H258.18Z"
        ></path>
        <path
          d="M325.88,200.88l-.94,13.46c-11.7-8.05-31.53-19.52-50.52-30.27l.75-10.85C290.81,181.86,308.85,191.51,325.88,200.88Z"
        ></path>
      </g>
      <polygon
        points="258.18 416.18 310.83 416.18 315.86 340.53 263.48 340.53 258.18 416.18"
        style="opacity: 0.1"
      ></polygon>
      <polygon
        points="358.56 371.59 362.18 319.07 113.24 319.29 109.62 371.81 358.56 371.59"
        v-bind:style="`fill: ${hexColor}`"
      ></polygon>
      <polygon
        points="113.34 319.26 109.62 371.81 174.65 371.75 179.54 319.23 113.34 319.26"
        style="opacity: 0.1"
      ></polygon>
      <g style="opacity: 0.1">
        <path
          d="M305.5,371.59c-2.12-12.69-5-32.55-8.25-52.51l-14.29,0a257.87,257.87,0,0,1,9.4,52.51Z"
        ></path>
        <path
          d="M204.6,334.5c2.27-2.6,4-8.35,5.35-15.33H193C196.35,330.11,200.65,339,204.6,334.5Z"
        ></path>
        <path
          d="M171.15,319.2l-25.59,0a447.65,447.65,0,0,0,13.54,52.51l35.73,0C188.15,362.13,178.85,341.5,171.15,319.2Z"
        ></path>
        <path
          d="M213.84,371.68c4.3-16.72,7.25-35.33,10-52.52H216.3c-4,22.79-7.66,43.08-11.19,52.54Z"
        ></path>
        <path
          d="M330,319.05H317c.41,10.6,1.35,19.16,3.38,19.51C322.63,339,326.4,329.85,330,319.05Z"
        ></path>
        <path
          d="M265.32,319.11l-16.48,0c-7.16,14.37-15.52,36.5-21.29,52.54h12.37c4.5-12.86,9.63-25,12.75-24.19,2.47.67,3.84,11.28,4.58,24.17h12.9C269.19,352.93,267.64,332.44,265.32,319.11Z"
        ></path>
        <path
          d="M135.84,371.76c-2.46-16.17-5.52-35.19-8.83-52.51H113.34l-1.45,21a185.34,185.34,0,0,1,7.61,31.55Z"
        ></path>
        <path
          d="M348,319c-9.6,18.67-20.39,39.35-28.38,52.55h12.61c9-18.07,18.23-37,26.22-52.55Z"
        ></path>
      </g>
      <polygon
        points="203.74 416.13 151.09 416.13 168.46 167.79 221.11 167.79 203.74 416.13"
        v-bind:style="`fill: ${hexColor}`"
      ></polygon>
      <polygon
        points="221.11 167.79 220.84 171.59 220.12 182.01 218.86 199.96 217.95 212.98 216.57 232.67 215.57 246.92 214.34 264.52 213.19 280.96 211.45 305.88 210.92 313.42 210.48 319.75 209.3 336.66 207.78 358.46 205.99 383.99 204.69 402.49 203.74 416.13 151.09 416.13 151.77 406.36 152.91 390.07 154.54 366.86 157.03 331.22 157.74 320.97 158.36 312.26 159.31 298.58 160.18 286.24 161.38 268.95 162.28 256.08 163.84 233.93 164.75 220.82 165.74 206.71 166.62 194.12 168.46 167.79 221.11 167.79"
        v-bind:style="`fill: ${hexColor}`"
      ></polygon>
      <polygon
        points="161.68 264.89 160.98 274.32 214.08 264.84 161.68 264.89"
        style="opacity: 0.2"
      ></polygon>
      <polygon
        points="168.42 167.89 161.68 264.26 214.34 264.52 221.11 167.79 168.42 167.89"
        style="opacity: 0.1"
      ></polygon>
      <g style="opacity: 0.1">
        <path
          d="M207.4,363.19c-12.52-3-32.13-7.22-51.82-11.85l1-14.25a257.79,257.79,0,0,0,51.74,13Z"
        ></path>
        <path
          d="M177.34,260c-2.74,2.08-8.6,3.43-15.66,4.28l1.18-16.9C173.53,251.45,182.14,256.35,177.34,260Z"
        ></path>
        <path
          d="M164.38,225.56,166.17,200a445,445,0,0,1,51.45,17.12l-2.49,35.64C206,245.47,186.1,234.77,164.38,225.56Z"
        ></path>
        <path
          d="M213.68,273.73c-17,3.15-35.74,4.8-53.09,6.39l1.09-15.23c23.39,0,52.62.14,52.62.14Z"
        ></path>
        <path
          d="M153.29,384.05l.91-13c10.55,1.13,19,2.66,19.23,4.71C173.66,378,164.32,381.17,153.29,384.05Z"
        ></path>
        <path
          d="M157.81,319.5,159,303.05c14.83-6.15,37.48-13,53.89-17.62L212,297.77c-13.13,3.61-25.64,7.89-25,11.06.5,2.51,11,4.61,23.8,6.23l-.9,12.87C191.28,325.69,171,322.73,157.81,319.5Z"
        ></path>
        <path
          d="M219.25,194c-16-3.57-34.73-7.93-51.78-12.42l.95-13.64h21a186.06,186.06,0,0,0,30.95,9.76Z"
        ></path>
        <path
          d="M151.31,412.43c16.08-6.9,35.57-14.77,54.22-22.54l.88-12.58c-13.72,7.06-35.09,16.4-54.38,24.69Z"
        ></path>
      </g>
      <polygon
        points="268.77 264.78 263.1 264.79 255.53 264.8 230.56 264.82 214.08 264.84 196.44 264.85 182.15 264.87 162.41 264.89 149.37 264.9 131.37 264.92 120.92 264.92 117.12 264.92 120.74 212.4 147.14 212.38 159.75 212.37 173.9 212.35 187.03 212.34 209.25 212.31 222.15 212.31 239.49 212.29 251.85 212.27 265.56 212.27 272.41 212.26 268.77 264.78"
        v-bind:style="`fill: ${hexColor}`"
      ></polygon>
      <polygon
        points="268.77 264.78 263.1 264.79 255.53 264.8 230.56 264.82 214.08 264.84 196.44 264.85 182.15 264.87 162.41 264.89 149.37 264.9 131.37 264.92 120.92 264.92 117.12 264.92 120.74 212.4 147.14 212.38 159.75 212.37 173.9 212.35 187.03 212.34 209.25 212.31 222.15 212.31 239.49 212.29 251.85 212.27 265.56 212.27 272.41 212.26 268.77 264.78"
        v-bind:style="`fill: ${hexColor}`"
      ></polygon>
      <g style="opacity: 0.1">
        <path
          d="M212.1,227.61c2.27-2.6,4-8.35,5.35-15.33H200.5C203.85,223.22,208.14,232.15,212.1,227.61Z"
        ></path>
        <path
          d="M178.65,212.31l-25.59,0a443.73,443.73,0,0,0,13.54,52.51l35.73,0C195.65,255.24,186.35,234.61,178.65,212.31Z"
        ></path>
        <path
          d="M221.33,264.79c4.31-16.72,7.25-35.32,10-52.52h-7.57c-4,22.79-7.65,43.08-11.19,52.54Z"
        ></path>
        <path
          d="M143.33,264.87c-2.45-16.17-5.51-35.19-8.82-52.51H120.84l-1.45,21A185.58,185.58,0,0,1,127,264.89Z"
        ></path>
      </g>
      <path
        d="M263.45,212.13l-16.48,0c-7.16,14.37-15.52,36.5-21.29,52.54h12.37c4.5-12.86,9.63-25,12.75-24.19,2.47.67,3.85,11.28,4.59,24.17h12.89C267.32,246,265.78,225.46,263.45,212.13Z"
        style="opacity: 0.1"
      ></path>
      <polygon
        points="261.24 371.81 260.53 381.25 313.9 371.44 261.24 371.81"
        style="opacity: 0.2"
      ></polygon>
      <polygon
        points="272.41 212.26 268.77 264.78 260.17 264.92 272.41 212.26"
        style="opacity: 0.2"
      ></polygon>
      <polygon
        points="145.72 371.72 154.43 371.69 156.89 319.17 145.72 371.72"
        style="opacity: 0.2"
      ></polygon>
    </g>
  </svg>
</template>

<script>
import colors from "vuetify/lib/util/colors";
export default {
  name: "BuildingHashtagDrawing",
  props: ["color"],
  data() {
    return {
      colors: colors,
    };
  },
  computed: {
    hexColor() {
      let result = [];
      const attrs = this.color.split(" ");
      for (let attr of attrs) {
        const part = attr.split("-");
        let tmp;
        if (part.length > 1) {
          tmp = part[0] + this.capitalizeFirstLetter(part[1]);
        } else {
          tmp = part[0];
        }
        result.push(tmp);
      }
      const hex = this.colors[result[0]][result[1]];
      return hex;
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>