<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <g id="freepik--background-simple--inject-85">
      <path
        d="M248.5,115.94c34.41-17.59,62.2-54.65,105.65-49.79,32.63,3.64,49.44,31.56,54,62.62,12,81.57-94.52,143.11-165.63,152.58C168.89,291.16,66.7,258.83,80,174.4c5.48-34.8,33.72-52.94,70.79-58.5C182.28,111.18,221.54,129.72,248.5,115.94Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M248.5,115.94c34.41-17.59,62.2-54.65,105.65-49.79,32.63,3.64,49.44,31.56,54,62.62,12,81.57-94.52,143.11-165.63,152.58C168.89,291.16,66.7,258.83,80,174.4c5.48-34.8,33.72-52.94,70.79-58.5C182.28,111.18,221.54,129.72,248.5,115.94Z"
        style="fill: #fff; opacity: 0.9"
      ></path>
    </g>
    <g id="freepik--Shadow--inject-85">
      <ellipse
        id="freepik--path--inject-85"
        cx="250"
        cy="416.24"
        rx="193.89"
        ry="11.32"
        style="fill: #f5f5f5"
      ></ellipse>
    </g>
    <g id="freepik--Character--inject-85">
      <path
        d="M223.13,87.65c3.49.42,3.07,3.78.28,4.66s-13.28.33-13.28.33Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M223.13,87.65c3.49.42,3.07,3.78.28,4.66s-13.28.33-13.28.33Z"
        style="opacity: 0.2"
      ></path>
      <path
        d="M187.16,96.36c-3.28,3.55-3.61,13.44-1.28,19.39s11.84,8.27,20,5.61,12.89-10.51,12.89-10.51,1,2.23,5.38,3.17c-.5-3.11-3.38-18.66-7.66-23.55S190.94,80.3,187.16,96.36Z"
        style="fill: #263238"
      ></path>
      <path
        d="M189.38,118c-6.78,3-7.57,9.89-1.81,12.73s12-3.39,10.81-9.17S189.38,118,189.38,118Z"
        style="fill: #263238"
      ></path>
      <path
        d="M229.32,151l1.26,3.5c.44,1.2.91,2.38,1.38,3.56.93,2.37,1.93,4.7,3,7s2.14,4.53,3.38,6.61c.29.54.62,1,.93,1.55a17.26,17.26,0,0,0,1,1.49l.49.73.13.18,0,.06-.14-.16a3.76,3.76,0,0,0-.87-.64,3.42,3.42,0,0,0-1.28-.38,3.62,3.62,0,0,0-.56,0,2,2,0,0,0-.43.07c-.44.1-.53.19-.55.21a2.57,2.57,0,0,0,.68-.46,15.09,15.09,0,0,0,1.1-1.06,34.14,34.14,0,0,0,2.3-2.72c1.55-1.94,3-4.06,4.48-6.2,2.9-4.3,5.77-8.76,8.57-13.19l4.85,2.35a143.79,143.79,0,0,1-6.52,14.91c-1.23,2.44-2.57,4.84-4.08,7.26-.77,1.2-1.58,2.39-2.54,3.61-.48.61-1,1.23-1.61,1.87a12.58,12.58,0,0,1-2.45,2,8.06,8.06,0,0,1-2.4.95c-.3.05-.62.09-.92.12a7.63,7.63,0,0,1-1.06,0,6.17,6.17,0,0,1-2.22-.69,6.37,6.37,0,0,1-1.65-1.25l-.31-.34-.11-.14-.18-.21-.7-.87c-.49-.58-.94-1.16-1.36-1.75s-.88-1.18-1.26-1.79a72.35,72.35,0,0,1-4.32-7.35c-1.3-2.49-2.46-5-3.52-7.58-.51-1.29-1-2.58-1.49-3.89s-.92-2.58-1.36-4Z"
        style="fill: #cf7a64"
      ></path>
      <path
        d="M224.06,140.72c11,5.17,11.17,20.08,11.17,20.08l-13.56,6.55s-8.19-12.58-7.18-18.06C215.54,143.57,217.85,137.8,224.06,140.72Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M220.58,152c2.39,1.47,6,7,7.77,12.17l-6.69,3.24s-5.13-7.91-6.76-13.92C215.57,151.69,217.56,150.28,220.58,152Z"
        style="opacity: 0.2"
      ></path>
      <path
        d="M253.5,147.63l4.33-4.41L263,150.8s-4.31,5.4-9.93,3.84l-.58-4A3.58,3.58,0,0,1,253.5,147.63Z"
        style="fill: #ce7a63"
      ></path>
      <polygon
        points="262.44 138.69 265.66 141.78 263.02 150.81 257.83 143.22 262.44 138.69"
        style="fill: #ce7a63"
      ></polygon>
      <polygon
        points="181.11 406.19 191.07 407.14 195.62 383.97 185.65 383.02 181.11 406.19"
        style="fill: #ce7a63"
      ></polygon>
      <polygon
        points="121.56 379.81 130.01 385.23 142.37 365.29 133.93 359.87 121.56 379.81"
        style="fill: #ce7a63"
      ></polygon>
      <path
        d="M131.73,382.57l-7.2-8.74a.82.82,0,0,0-1-.18l-7.73,4.71a1.62,1.62,0,0,0-.35,2.39c2.56,3,3.95,4.32,7.12,8.16,2,2.36,5.92,7.87,8.61,11.14s6.06.77,5.24-.83c-3.72-7.17-4.07-11.48-4-15A2.61,2.61,0,0,0,131.73,382.57Z"
        style="fill: #263238"
      ></path>
      <path
        d="M192.32,404.13h-12a1,1,0,0,0-.94.73l-2.6,9.46a1.48,1.48,0,0,0,1.46,1.89c4.21,0,6.21,0,11.47,0h16.07c4.37,0,5.28-4.41,3.47-4.81-8.1-1.8-12.77-4.27-15.28-6.63A2.42,2.42,0,0,0,192.32,404.13Z"
        style="fill: #263238"
      ></path>
      <polygon
        points="195.61 383.97 193.27 395.92 183.12 395.92 185.64 383.02 195.61 383.97"
        style="opacity: 0.2"
      ></polygon>
      <polygon
        points="133.92 359.87 142.37 365.29 135.99 375.57 127.54 370.15 133.92 359.87"
        style="opacity: 0.2"
      ></polygon>
      <path
        d="M227.25,146.69c-.32,6.66-2.5,21.53-10.87,54.64l-40.78-3.52c2.12-16.54,3-26.78-2-61.53a120.35,120.35,0,0,1,17.51-.78,130.12,130.12,0,0,1,18.52,1.6c4.5.81,9.41,2.06,12.79,3A6.51,6.51,0,0,1,227.25,146.69Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M180.37,151.66c1.76,3.72,1.1,16.19-3.12,20.55-.13-5-.53-10.81-1.31-18A5.83,5.83,0,0,1,180.37,151.66Z"
        style="opacity: 0.2"
      ></path>
      <path
        d="M197.78,155.17a1.69,1.69,0,0,1-1.12-.43c-6.63-5.91-13-14.06-11.34-18,.74-1.74,3-2.48,6.58-2.19a.28.28,0,0,1,.13,0l13.43,9.65a.22.22,0,0,1,.1.18.24.24,0,0,1-.07.2,34,34,0,0,0-6.09,9.45,1.78,1.78,0,0,1-1.23,1A1.72,1.72,0,0,1,197.78,155.17ZM190.45,135c-2.55,0-4.12.65-4.67,1.95-1.51,3.54,4.5,11.44,11.21,17.42a1.19,1.19,0,0,0,1.07.27,1.24,1.24,0,0,0,.87-.75,34.5,34.5,0,0,1,6-9.4l-13.15-9.44C191.32,135,190.87,135,190.45,135Z"
        style="fill: #fff"
      ></path>
      <path
        d="M213.15,155.46H213a1.75,1.75,0,0,1-1.47-1.1A35.56,35.56,0,0,0,206,145a.24.24,0,0,1-.05-.24l2.71-8.44a.27.27,0,0,1,.28-.17c3.86.62,6.45,2.31,7.71,5,1.95,4.23.12,9.87-2,13.41A1.72,1.72,0,0,1,213.15,155.46Zm-6.68-10.65a36.41,36.41,0,0,1,5.53,9.36,1.22,1.22,0,0,0,1,.78,1.17,1.17,0,0,0,1.14-.57c2.1-3.44,3.89-8.89,2-12.94-1.17-2.53-3.56-4.11-7.13-4.73Z"
        style="fill: #fff"
      ></path>
      <path
        d="M194.79,114.8c.65,6.06.87,17.08-3.64,20.7,0,0,4.82,4.92,14.57,10.35,7.09-4.9,3.95-8.75,3.95-8.75-6.51-2.16-5.91-7.08-4.35-11.61Z"
        style="fill: #ce7a63"
      ></path>
      <path
        d="M199.08,119.17l6.24,6.32a18.26,18.26,0,0,0-.86,3.24c-2.51-.59-6.14-3.19-6.18-5.88A10.63,10.63,0,0,1,199.08,119.17Z"
        style="opacity: 0.2"
      ></path>
      <path
        d="M189.46,105.34c2.53,9.68,3.39,13.83,9.33,17.88,8.93,6.1,20.26.88,20.68-9.36.37-9.21-3.82-23.49-14.18-25.51A13.65,13.65,0,0,0,189.46,105.34Z"
        style="fill: #ce7a63"
      ></path>
      <path
        d="M191.15,111.08c11.18-4.28,17.51-17.44,17.51-17.44l7.15,1.61s-2.62-7.73-10.49-8-13,.55-17.1,9.61S191.15,111.08,191.15,111.08Z"
        style="fill: #263238"
      ></path>
      <path
        d="M185.31,104a120.58,120.58,0,0,1,27.84-14.18,1.05,1.05,0,0,0,.67-1.32c-.83-2.54-3.22-7-4.86-7.77-1.89-.85-12.75-1.64-17.76.47s-10,10.13-10.7,12c-.58,1.46,1.94,8,3.27,10.48A1.05,1.05,0,0,0,185.31,104Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M210.51,87.57l-5.82,1.57c-.18,0-.4-.09-.53-.35l-1.42-2.64c-.2-.39-.13-.85.14-.92l5.83-1.57c.18,0,.39.09.53.35l1.41,2.64C210.86,87,210.79,87.5,210.51,87.57Z"
        style="fill: #fff"
      ></path>
      <path
        d="M193.78,98.69c8-2.51,17.08-2.73,20.38-5s2.12-3.54,9-6.09a30.49,30.49,0,0,0-9.41.57S196.92,94.69,193.78,98.69Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M188.66,113.55a7.31,7.31,0,0,0,4.3,3.56c2.45.74,3.73-1.44,3.09-3.79-.57-2.11-2.55-5-5-4.75A3.29,3.29,0,0,0,188.66,113.55Z"
        style="fill: #ce7a63"
      ></path>
      <path
        d="M216.38,201.33s13.8,61,4.59,104.08c-6.17,28.82-23.51,87-23.51,87L181.9,392s10.1-61.1,13.58-85.13c6-41.11-9-108.13-9-108.13Z"
        style="fill: #263238"
      ></path>
      <path
        d="M200.47,213.66l-8.7,13.72a418,418,0,0,1,5,49.14C209,254.62,203.19,225.25,200.47,213.66Z"
        style="opacity: 0.2"
      ></path>
      <polygon
        points="198.55 393.36 179.99 392.81 181.23 386.21 200.23 387.25 198.55 393.36"
        v-bind:style="`fill: ${hexColor}`"
      ></polygon>
      <path
        d="M207.47,200.68s-9.08,63.87-25.6,99.71c-13.33,28.94-40.7,72-40.7,72l-12.57-6s16.52-43,29-69.83c22.41-48.23,3.39-81.64,18-98.71Z"
        style="fill: #263238"
      ></path>
      <polygon
        points="141.83 375 126.53 366.66 128.99 360.12 145.3 369.26 141.83 375"
        v-bind:style="`fill: ${hexColor}`"
      ></polygon>
      <path
        d="M175.19,195.36,173,198.85c-.17.27.14.59.61.63l42.78,3.7c.36,0,.68-.12.73-.35l.68-3.62c0-.25-.25-.49-.65-.53l-41.3-3.57A.78.78,0,0,0,175.19,195.36Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M180.34,200.46l-1.1-.1c-.22,0-.38-.14-.35-.28l.92-4.7c0-.14.23-.23.45-.22l1.11.1c.22,0,.38.15.35.28l-.93,4.7C180.76,200.38,180.56,200.48,180.34,200.46Z"
        style="fill: #263238"
      ></path>
      <path
        d="M213.28,203.31l-1.1-.1c-.22,0-.38-.15-.35-.28l.93-4.7c0-.14.22-.24.44-.22l1.11.1c.22,0,.38.15.35.28l-.93,4.7C213.71,203.23,213.5,203.33,213.28,203.31Z"
        style="fill: #263238"
      ></path>
      <path
        d="M196.81,201.88l-1.1-.09c-.22,0-.38-.15-.35-.29l.92-4.7c0-.13.23-.23.45-.21l1.11.09c.22,0,.38.15.35.29l-.93,4.7C197.24,201.81,197,201.9,196.81,201.88Z"
        style="fill: #263238"
      ></path>
      <path
        d="M205.84,105c.16.8.71,1.37,1.23,1.26s.82-.83.66-1.63-.7-1.36-1.22-1.26S205.69,104.21,205.84,105Z"
        style="fill: #263238"
      ></path>
      <path
        d="M214.86,103.23c.16.8.71,1.36,1.23,1.26s.82-.83.66-1.63-.71-1.36-1.23-1.26S214.7,102.43,214.86,103.23Z"
        style="fill: #263238"
      ></path>
      <path
        d="M215.3,101.69l1.81-.93S216.42,102.45,215.3,101.69Z"
        style="fill: #263238"
      ></path>
      <path
        d="M211.76,105.22a21.57,21.57,0,0,0,3.85,4.53,3.52,3.52,0,0,1-2.74,1.1Z"
        style="fill: #ba4d3c"
      ></path>
      <path
        d="M209.18,114.65a6.62,6.62,0,0,1-4.55-1.73.2.2,0,0,1,0-.28.19.19,0,0,1,.28,0,6.49,6.49,0,0,0,5.67,1.47.21.21,0,0,1,.24.16.2.2,0,0,1-.16.23A8.09,8.09,0,0,1,209.18,114.65Z"
        style="fill: #263238"
      ></path>
      <path
        d="M200.43,101.91a.42.42,0,0,1-.21-.06.4.4,0,0,1-.13-.54A4.69,4.69,0,0,1,203.68,99a.4.4,0,0,1,0,.8,3.85,3.85,0,0,0-2.94,1.94A.42.42,0,0,1,200.43,101.91Z"
        style="fill: #263238"
      ></path>
      <path
        d="M217.36,98.72a.45.45,0,0,1-.19,0,3.94,3.94,0,0,0-3.52-.24.38.38,0,0,1-.53-.16.4.4,0,0,1,.16-.54,4.66,4.66,0,0,1,4.28.24.4.4,0,0,1,.15.54A.4.4,0,0,1,217.36,98.72Z"
        style="fill: #263238"
      ></path>
      <path
        d="M177.76,152.88c-1.53,4.59-3.06,9.38-4.37,14-.64,2.33-1.24,4.65-1.65,6.84a25.6,25.6,0,0,0-.37,3c0,.39,0,.72,0,.72a4.41,4.41,0,0,0-.26-.47l-.09-.12s0,0,0,0l0,.06a.57.57,0,0,0,.08.13,3.4,3.4,0,0,0,1.25,1,9.14,9.14,0,0,0,2.44.75,26.37,26.37,0,0,0,6.48.21,63.18,63.18,0,0,0,7.07-.93c2.38-.4,4.83-1,7.12-1.54l2,5a60.27,60.27,0,0,1-15,5.53,32.76,32.76,0,0,1-8.67.74,18.5,18.5,0,0,1-4.93-1,12.71,12.71,0,0,1-5-3.22,7.43,7.43,0,0,1-.53-.59l-.25-.32a1.13,1.13,0,0,1-.14-.18l-.21-.32a8.16,8.16,0,0,1-1-2.21,13.52,13.52,0,0,1-.46-3,28,28,0,0,1,.21-4.61,69.38,69.38,0,0,1,1.46-8,129.39,129.39,0,0,1,4.55-15Z"
        style="fill: #cf7a64"
      ></path>
      <path
        d="M193.47,178.52l3.76-8.17,5.62,6.86s-3.2,5-7.39,4.93Z"
        style="fill: #ce7a63"
      ></path>
      <polygon
        points="201.47 167.91 206.78 173.47 202.85 177.22 197.23 170.35 201.47 167.91"
        style="fill: #ce7a63"
      ></polygon>
      <path
        d="M173.64,136.28c-11.14,1.8-11.14,20.64-11.14,20.64l15.25,8.89s6.51-13.84,6-20.35C183.27,139.93,180,135.25,173.64,136.28Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M134.6,387.45a1.33,1.33,0,0,1-.4,0c-1.24-.18-2.42-2.22-3-3.41a.22.22,0,0,1,0-.22.2.2,0,0,1,.21-.06c.39.12,3.84,1.22,4.18,2.44a.79.79,0,0,1-.21.76A1.48,1.48,0,0,1,134.6,387.45Zm-2.83-3.15c.86,1.64,1.78,2.66,2.49,2.76a.92.92,0,0,0,.85-.36.36.36,0,0,0,.11-.38C135,385.57,133,384.71,131.77,384.3Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M134.52,384.3a7.38,7.38,0,0,1-3.22-.15.23.23,0,0,1-.13-.16.2.2,0,0,1,.07-.18c.1-.08,2.33-1.91,3.76-1.68a1.22,1.22,0,0,1,.86.56.79.79,0,0,1,0,1A2.27,2.27,0,0,1,134.52,384.3Zm-2.72-.42c1.15.26,3.26.15,3.75-.45.09-.1.16-.25,0-.53a.83.83,0,0,0-.59-.38C134,382.36,132.44,383.4,131.8,383.88Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M197.63,406.07a15.47,15.47,0,0,1-3.27-.42.18.18,0,0,1-.15-.17.2.2,0,0,1,.11-.2c.49-.23,4.83-2.23,5.92-1.5a.66.66,0,0,1,.28.62,1.43,1.43,0,0,1-.59,1.12A3.91,3.91,0,0,1,197.63,406.07Zm-2.59-.68c1.29.24,3.67.55,4.65-.19a1,1,0,0,0,.44-.82.26.26,0,0,0-.11-.27C199.41,403.7,196.84,404.6,195,405.39Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M194.4,405.66a.18.18,0,0,1-.12-.05.2.2,0,0,1-.07-.19c0-.19,1-4.46,3.38-4.19.59.07.75.36.78.59.15,1.09-2.58,3.29-3.89,3.82Zm3-4.05c-1.51,0-2.38,2.49-2.67,3.49,1.41-.74,3.36-2.53,3.27-3.22,0-.05,0-.21-.44-.26Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M206.28,103.46l1.81-.92S207.4,104.23,206.28,103.46Z"
        style="fill: #263238"
      ></path>
    </g>
    <g id="freepik--Camera--inject-85">
      <path
        d="M282.17,173.42h3.37a1.31,1.31,0,0,0,1.24-1.9l-2.09-6a3,3,0,0,0-2.55-1.9h-3.38a1.31,1.31,0,0,0-1.24,1.9l2.09,6A3,3,0,0,0,282.17,173.42Z"
      ></path>
      <path
        d="M283.71,173.42h3.37a1.31,1.31,0,0,0,1.24-1.9l-2.09-6a3,3,0,0,0-2.55-1.9H280.3a1.31,1.31,0,0,0-1.24,1.9l2.1,6A3,3,0,0,0,283.71,173.42Z"
        style="fill: #263238"
      ></path>
      <path
        d="M291.46,173.42h-3.38a1.31,1.31,0,0,1-1.24-1.9l2.1-6a3,3,0,0,1,2.55-1.9h3.37a1.31,1.31,0,0,1,1.24,1.9l-2.09,6A3,3,0,0,1,291.46,173.42Z"
      ></path>
      <path
        d="M293,173.42h-3.37a1.31,1.31,0,0,1-1.24-1.9l2.09-6a3,3,0,0,1,2.55-1.9h3.38a1.31,1.31,0,0,1,1.24,1.9l-2.1,6A3,3,0,0,1,293,173.42Z"
        style="fill: #263238"
      ></path>
      <path
        d="M294.52,167.56h-4.24l-14.55-1.65a4.09,4.09,0,0,1-3.62-4v-9.54a3.21,3.21,0,0,1,3.21-3.21h19.2a1.38,1.38,0,0,1,1.38,1.38v15.69A1.38,1.38,0,0,1,294.52,167.56Z"
      ></path>
      <rect
        x="290.28"
        y="149.11"
        width="11.16"
        height="18.45"
        rx="1.38"
        style="fill: #263238"
      ></rect>
      <polygon
        points="301.44 157.92 284 159.92 284 150.5 301.44 150.5 301.44 157.92"
      ></polygon>
      <path
        d="M303.9,156.22h-9.38l-32.16-3.65a9,9,0,0,1-8-8.95V122.54a7.09,7.09,0,0,1,7.09-7.1H303.9a3,3,0,0,1,3,3v34.69A3,3,0,0,1,303.9,156.22Z"
      ></path>
      <polygon
        points="240.8 115.44 231.8 115.44 225.6 93.5 240.8 93.5 240.8 115.44"
      ></polygon>
      <rect x="260.25" y="104.47" width="9.1" height="2.25"></rect>
      <rect
        x="237.8"
        y="93.5"
        width="27"
        height="21.94"
        transform="translate(502.59 208.94) rotate(180)"
        style="fill: #263238"
      ></rect>
      <polygon
        points="256.13 128.78 251.63 128.78 237.8 104.47 242.3 104.47 256.13 128.78"
      ></polygon>
      <polygon
        points="283.13 128.78 278.63 128.78 264.8 104.47 269.3 104.47 283.13 128.78"
      ></polygon>
      <rect x="233.2" y="104.47" width="9.1" height="2.25"></rect>
      <rect
        x="294.52"
        y="115.44"
        width="24.66"
        height="40.78"
        rx="3.04"
        style="fill: #263238"
      ></rect>
      <path
        d="M308,127.67h47.75a0,0,0,0,1,0,0V144a0,0,0,0,1,0,0H308a7.22,7.22,0,0,1-7.22-7.22v-1.89A7.22,7.22,0,0,1,308,127.67Z"
      ></path>
      <path
        d="M339.83,135.83a14,14,0,0,1-14,14c-7.73,0-10.92-6.26-10.92-14s3.19-14,10.92-14A14,14,0,0,1,339.83,135.83Z"
        style="fill: #263238"
      ></path>
      <path
        d="M332.26,153.06l-7.15-4.79a5.72,5.72,0,0,1-2.53-4.75V128.14a5.72,5.72,0,0,1,2.53-4.75l7.15-4.78a1.6,1.6,0,0,1,.9-.28h32.65a1.64,1.64,0,0,1,1.15.48l5.81,5.8a1.66,1.66,0,0,1,.47,1.16V145.9a1.64,1.64,0,0,1-.47,1.15l-5.81,5.8a1.6,1.6,0,0,1-1.15.48H333.16A1.59,1.59,0,0,1,332.26,153.06Z"
      ></path>
      <path
        d="M337.27,152.85l-5.8-5.8a1.64,1.64,0,0,1-.48-1.15V125.77a1.67,1.67,0,0,1,.48-1.16l5.8-5.8a1.67,1.67,0,0,1,1.16-.48h32.46a1.67,1.67,0,0,1,1.16.48l5.8,5.8a1.63,1.63,0,0,1,.48,1.16V145.9a1.6,1.6,0,0,1-.48,1.15l-5.8,5.8a1.63,1.63,0,0,1-1.16.48H338.43A1.63,1.63,0,0,1,337.27,152.85Z"
        style="fill: #263238"
      ></path>
      <path
        d="M370.33,150.33H339l-5-5v-19c2-2,3.05-3,5-5h31.34c1.95,2,3,3,5,5v19Z"
        style="fill: #407bff; opacity: 0.5"
      ></path>
      <circle cx="354.66" cy="135.83" r="12.25" style="fill: #263238"></circle>
      <path
        d="M350.8,126.81a8.9,8.9,0,0,0-2.84.46,10.64,10.64,0,0,0,0,17.12,8.9,8.9,0,0,0,2.84.46,9,9,0,1,0,0-18Z"
        style="fill: #407bff; opacity: 0.7000000000000001"
      ></path>
      <path
        d="M357.48,132.93a3.61,3.61,0,1,1-3.61-3.61A3.61,3.61,0,0,1,357.48,132.93Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M286.28,142.82l-33.55-10.06a3,3,0,0,1-2.18-2.92v-6.56a3.06,3.06,0,0,1,3.06-3.06h33.55a3.06,3.06,0,0,1,3.06,3.06v16.61A3.06,3.06,0,0,1,286.28,142.82Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M284.5,142.82l-33.56-10.06a3,3,0,0,1-2.17-2.92v-6.56a3.05,3.05,0,0,1,3-3.06h33.56a3.06,3.06,0,0,1,3.05,3.06v16.61A3.05,3.05,0,0,1,284.5,142.82Z"
        style="fill: #263238"
      ></path>
      <path
        d="M250.55,137.66v2.43a1.8,1.8,0,0,0,1.29,1.74l36,10.8a1.82,1.82,0,0,0,2.34-1.73v-2.44a1.82,1.82,0,0,0-1.3-1.74l-36-10.8A1.81,1.81,0,0,0,250.55,137.66Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M296.85,169.39H276.62v19.78h20.23c3.68,0,6.57-4.35,6.57-9.89S300.53,169.39,296.85,169.39Z"
        style="fill: #263238"
      ></path>
      <path
        d="M276.62,189.17c-3.68,0-6.57-4.35-6.57-9.89s2.89-9.89,6.57-9.89,6.56,4.34,6.56,9.89S280.29,189.17,276.62,189.17Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <ellipse
        cx="276.62"
        cy="179.28"
        rx="4.56"
        ry="7.89"
        style="fill: #263238"
      ></ellipse>
      <path
        d="M287.19,191.76c-5.65,0-10.44,2.68-12.09,6.39A2,2,0,0,0,277,201h20.39a2,2,0,0,0,1.89-2.85C297.62,194.44,292.83,191.76,287.19,191.76Z"
        style="fill: #263238"
      ></path>
      <rect x="276.62" y="189.17" width="21.14" height="6.33" rx="2.27"></rect>
      <polygon
        points="251.84 416.24 248.64 416.24 276.99 201 280.19 201 251.84 416.24"
        style="fill: #263238"
      ></polygon>
      <polygon
        points="272.65 416.24 269.45 416.24 283.62 201 286.82 201 272.65 416.24"
        style="fill: #263238"
      ></polygon>
      <polygon
        points="322.53 416.24 325.73 416.24 297.38 201 294.19 201 322.53 416.24"
        style="fill: #263238"
      ></polygon>
      <polygon
        points="302.02 416.24 304.92 416.24 290.75 201 287.85 201 302.02 416.24"
        style="fill: #263238"
      ></polygon>
      <polygon
        points="245.73 197.35 243.96 196.42 265.69 155.22 273.29 155.22 273.29 157.22 266.89 157.22 245.73 197.35"
      ></polygon>
      <rect
        x="239.37"
        y="188.95"
        width="17.2"
        height="4"
        transform="translate(-36.64 321.13) rotate(-62.18)"
        v-bind:style="`fill: ${hexColor}`"
      ></rect>
    </g>
  </svg>
</template>

<script>
import colors from "vuetify/lib/util/colors";
export default {
  name: "LanguageSimpleDrawing",
  props: ["color"],
  data() {
    return {
      colors: colors,
    };
  },
  computed: {
    hexColor() {
      let result = [];
      const attrs = this.color.split(" ");
      for (let attr of attrs) {
        const part = attr.split("-");
        let tmp;
        if (part.length > 1) {
          tmp = part[0] + this.capitalizeFirstLetter(part[1]);
        } else {
          tmp = part[0];
        }
        result.push(tmp);
      }
      const hex = this.colors[result[0]][result[1]];
      return hex;
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>