<template>
  <svg class="" viewBox="0 0 500 500">
    <g id="freepik--background-simple--inject-17">
      <path
        d="M417,120.35l-2.09-3.45c-17.64-29.06-49.52-46.58-83.14-44.45a87.35,87.35,0,0,0-12.87,1.77c-30.57,6.54-57.16,29.65-68.18,59.26-6.49,17.4-8.3,37.45-20.6,51.27-16,18-43.2,18.55-67.1,17.8s-51.1-.11-67,17.95C86,231.8,82.84,247.89,83.64,263c2.13,40.09,30.07,75.94,65.6,93.76s77.36,19.41,116.24,11.57c54.47-11,106.28-41.13,137.41-87.71S441.91,170.62,417,120.35Z"
        style="fill: #ebebeb"
      ></path>
    </g>
    <g id="freepik--Books--inject-17">
      <rect
        x="440.13"
        y="389.17"
        width="19.21"
        height="22.84"
        style="fill: #f5f5f5"
      ></rect>
      <rect
        x="353.97"
        y="387.68"
        width="86.82"
        height="25.76"
        v-bind:style="`fill: ${hexColor}`"
      ></rect>
      <path
        d="M459.92,389.85H438.47v-2.17h21.45a1.09,1.09,0,0,1,1.09,1.08h0A1.09,1.09,0,0,1,459.92,389.85Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M459.92,413.45H438.47v-2.17h21.45a1.08,1.08,0,0,1,1.09,1.08h0A1.09,1.09,0,0,1,459.92,413.45Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.4">
        <rect x="423.71" y="387.68" width="10.26" height="25.76"></rect>
      </g>
      <g style="opacity: 0.4">
        <rect x="361.67" y="387.68" width="10.26" height="25.76"></rect>
      </g>
      <path
        d="M459.34,393c0,.06-4.16.11-9.28.11s-9.29-.05-9.29-.11,4.16-.12,9.29-.12S459.34,392.88,459.34,393Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M459.34,396.37c0,.07-4.16.12-9.28.12s-9.29-.05-9.29-.12,4.16-.11,9.29-.11S459.34,396.31,459.34,396.37Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M459.34,400.59c0,.06-4.16.11-9.28.11s-9.29-.05-9.29-.11,4.16-.11,9.29-.11S459.34,400.53,459.34,400.59Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M459.34,404.81c0,.06-4.16.11-9.28.11s-9.29-.05-9.29-.11,4.16-.12,9.29-.12S459.34,404.74,459.34,404.81Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M459.34,409c0,.07-4.16.12-9.28.12s-9.29-.05-9.29-.12,4.16-.11,9.29-.11S459.34,409,459.34,409Z"
        style="fill: #e0e0e0"
      ></path>
      <g style="opacity: 0.2">
        <rect x="379.13" y="398.27" width="39.06" height="4.64"></rect>
      </g>
      <rect
        x="439.64"
        y="413.95"
        width="19.51"
        height="13.92"
        style="fill: #fafafa"
      ></rect>
      <rect
        x="356.85"
        y="413.45"
        width="83.01"
        height="14.93"
        style="fill: #455a64"
      ></rect>
      <path
        d="M439.26,414.46h21a.51.51,0,0,0,.51-.51h0a.5.5,0,0,0-.51-.5h-21Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M439.26,427.36h21.05a.42.42,0,0,1,.42.42V428a.42.42,0,0,1-.42.42H439.26a0,0,0,0,1,0,0v-1A0,0,0,0,1,439.26,427.36Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M459.15,416.59c0,.07-4.32.12-9.65.12s-9.64,0-9.64-.12,4.32-.11,9.64-.11S459.15,416.53,459.15,416.59Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M459.15,419.23c0,.06-4.32.11-9.65.11s-9.64,0-9.64-.11,4.32-.12,9.64-.12S459.15,419.16,459.15,419.23Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M459.15,421.86c0,.06-4.32.11-9.65.11s-9.64-.05-9.64-.11,4.32-.11,9.64-.11S459.15,421.8,459.15,421.86Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M459.15,424.49c0,.07-4.32.12-9.65.12s-9.64-.05-9.64-.12,4.32-.11,9.64-.11S459.15,424.43,459.15,424.49Z"
        style="fill: #e0e0e0"
      ></path>
      <rect
        x="442.2"
        y="429.7"
        width="19.91"
        height="37.73"
        style="fill: #ebebeb"
      ></rect>
      <rect
        x="348.74"
        y="428.37"
        width="93.46"
        height="40.9"
        v-bind:style="`fill: ${hexColor}`"
      ></rect>
      <path
        d="M463.66,469.28h-24v-2.66h24A1.33,1.33,0,0,1,465,468h0A1.33,1.33,0,0,1,463.66,469.28Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M439.64,428.37h24.25a1.1,1.1,0,0,1,1.1,1.1v.45a1.1,1.1,0,0,1-1.1,1.1H439.64a0,0,0,0,1,0,0v-2.65A0,0,0,0,1,439.64,428.37Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M462.11,434.76c0,.06-4.46.11-10,.11s-9.95-.05-9.95-.11,4.45-.12,9.95-.12S462.11,434.69,462.11,434.76Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M462.11,439.59c0,.07-4.46.12-10,.12s-9.95,0-9.95-.12,4.45-.11,9.95-.11S462.11,439.53,462.11,439.59Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M462.11,444.43c0,.07-4.46.12-10,.12s-9.95-.05-9.95-.12,4.45-.11,9.95-.11S462.11,444.37,462.11,444.43Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M462.11,449.27c0,.06-4.46.12-10,.12s-9.95-.06-9.95-.12,4.45-.11,9.95-.11S462.11,449.21,462.11,449.27Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M462.11,454.11c0,.06-4.46.11-10,.11s-9.95-.05-9.95-.11,4.45-.11,9.95-.11S462.11,454.05,462.11,454.11Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M462.11,459c0,.06-4.46.11-10,.11s-9.95-.05-9.95-.11,4.45-.12,9.95-.12S462.11,458.88,462.11,459Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M462.11,463.79c0,.06-4.46.11-10,.11s-9.95,0-9.95-.11,4.45-.12,9.95-.12S462.11,463.72,462.11,463.79Z"
        style="fill: #e0e0e0"
      ></path>
      <g style="opacity: 0.30000000000000004">
        <polygon
          points="371.93 428.37 359.06 469.28 348.74 469.28 348.74 428.37 371.93 428.37"
        ></polygon>
      </g>
      <path
        d="M360,446.22h3.64c2.62,0,4.41,1.58,4.41,4s-1.79,4-4.41,4H360Zm3.55,6.48a2.48,2.48,0,1,0,0-5h-1.69v5Z"
        style="fill: #fff"
      ></path>
      <path d="M369.45,446.22h1.85v8h-1.85Z" style="fill: #fff"></path>
      <path
        d="M372.69,450.22a4.41,4.41,0,0,1,7.64-2.72l-1.19,1.1a2.57,2.57,0,0,0-2-.94,2.56,2.56,0,0,0,0,5.12,2.55,2.55,0,0,0,2-.95l1.19,1.1a4.4,4.4,0,0,1-7.64-2.71Z"
        style="fill: #fff"
      ></path>
      <path
        d="M383.15,447.73h-2.56v-1.51h7v1.51H385v6.49h-1.86Z"
        style="fill: #fff"
      ></path>
      <path d="M388.56,446.22h1.85v8h-1.85Z" style="fill: #fff"></path>
      <path
        d="M391.81,450.22a4.38,4.38,0,1,1,4.38,4.14A4.14,4.14,0,0,1,391.81,450.22Zm6.88,0a2.51,2.51,0,1,0-2.5,2.56A2.44,2.44,0,0,0,398.69,450.22Z"
        style="fill: #fff"
      ></path>
      <path
        d="M409.3,446.22v8h-1.52l-4-4.85v4.85H402v-8h1.53l4,4.86v-4.86Z"
        style="fill: #fff"
      ></path>
      <path
        d="M416.47,452.51h-3.72l-.71,1.71h-1.89l3.56-8h1.83l3.58,8h-1.94Zm-.59-1.41L414.62,448l-1.27,3.06Z"
        style="fill: #fff"
      ></path>
      <path
        d="M425.05,454.22,423.51,452h-1.7v2.23H420v-8h3.47c2.14,0,3.47,1.11,3.47,2.91a2.58,2.58,0,0,1-1.64,2.52l1.79,2.57Zm-1.73-6.49h-1.51v2.79h1.51c1.13,0,1.7-.53,1.7-1.39S424.45,447.73,423.32,447.73Z"
        style="fill: #fff"
      ></path>
      <path
        d="M432.08,451.39v2.83h-1.85v-2.85l-3.1-5.15h2l2.14,3.56,2.13-3.56h1.82Z"
        style="fill: #fff"
      ></path>
      <path
        d="M382.07,418.24l-.56,2.81H385l-.08.43h-3.49l-.59,2.9h-.47l1.31-6.57h4.39l-.09.43Z"
        style="fill: #fff"
      ></path>
      <path
        d="M389.34,419.44l-.08.46h-.15c-1.11,0-1.84.67-2.1,2l-.51,2.52H386l1-4.9h.46l-.21,1A2.25,2.25,0,0,1,389.34,419.44Z"
        style="fill: #fff"
      ></path>
      <path
        d="M390,422c0,.11,0,.21,0,.31,0,1.06.62,1.65,1.81,1.65a2.26,2.26,0,0,0,1.68-.65l.22.33a2.65,2.65,0,0,1-1.94.75,2,2,0,0,1-2.23-2.06,2.74,2.74,0,0,1,2.68-2.92,1.91,1.91,0,0,1,2.08,2.05,3,3,0,0,1-.06.54Zm.06-.36h3.77a2.09,2.09,0,0,0,0-.25,1.5,1.5,0,0,0-1.65-1.57A2.15,2.15,0,0,0,390.06,421.67Z"
        style="fill: #fff"
      ></path>
      <path
        d="M400.22,421a2.62,2.62,0,0,1-.06.54l-.56,2.83h-.47l.56-2.83a2,2,0,0,0,.05-.47c0-.76-.46-1.21-1.38-1.21a2.06,2.06,0,0,0-2.15,1.84l-.53,2.67h-.47l1-4.9h.46l-.19.93a2.38,2.38,0,0,1,2-1C399.61,419.44,400.22,420,400.22,421Z"
        style="fill: #fff"
      ></path>
      <path
        d="M401.45,422.38a2.79,2.79,0,0,1,2.82-2.94,2,2,0,0,1,1.86.92l-.39.24a1.6,1.6,0,0,0-1.5-.73,2.33,2.33,0,0,0-2.32,2.48A1.56,1.56,0,0,0,403.7,424a2.07,2.07,0,0,0,1.62-.71l.33.26a2.49,2.49,0,0,1-2,.88A2,2,0,0,1,401.45,422.38Z"
        style="fill: #fff"
      ></path>
      <path
        d="M411.66,421a3.5,3.5,0,0,1-.05.54l-.57,2.83h-.47l.57-2.83a2.63,2.63,0,0,0,0-.47c0-.76-.46-1.21-1.38-1.21a2.07,2.07,0,0,0-2.15,1.84l-.53,2.67h-.47l1.39-7h.47l-.59,3a2.35,2.35,0,0,1,2-.95A1.51,1.51,0,0,1,411.66,421Z"
        style="fill: #fff"
      ></path>
      <path d="M383,396.54h1.22l-1.31,6.57h-1.22Z" style="fill: #fff"></path>
      <path
        d="M386.17,401.43a1.65,1.65,0,0,0,0,.21.52.52,0,0,0,.59.57,1.22,1.22,0,0,0,.66-.2l.21.86a2.25,2.25,0,0,1-1.21.3,1.29,1.29,0,0,1-1.44-1.34,1.84,1.84,0,0,1,0-.39l.48-2.37h-.82l.19-.94h.81l.23-1.14h1.17l-.23,1.14h1.35l-.19.94h-1.35Z"
        style="fill: #fff"
      ></path>
      <path
        d="M394.22,398.1l-1,5h-1.12l.1-.52a2.35,2.35,0,0,1-1.63.58,2.1,2.1,0,0,1-2.21-2.2A2.8,2.8,0,0,1,391.2,398a1.88,1.88,0,0,1,1.71.8l.14-.74Zm-1.61,2.19a1.18,1.18,0,0,0-1.33-1.25,1.73,1.73,0,0,0-1.72,1.87,1.18,1.18,0,0,0,1.32,1.26A1.75,1.75,0,0,0,392.61,400.29Z"
        style="fill: #fff"
      ></path>
      <path d="M396.15,396.14h1.17l-1.38,7h-1.18Z" style="fill: #fff"></path>
      <path
        d="M398.47,398.1h1.17l-1,5h-1.18Zm.15-1.49a.75.75,0,0,1,.8-.75.65.65,0,0,1,.7.63.75.75,0,0,1-.8.78A.66.66,0,0,1,398.62,396.61Z"
        style="fill: #fff"
      ></path>
      <path
        d="M406.08,398.1l-1,5H404l.1-.52a2.37,2.37,0,0,1-1.63.58,2.1,2.1,0,0,1-2.21-2.2,2.8,2.8,0,0,1,2.83-2.93,1.86,1.86,0,0,1,1.7.8l.14-.74Zm-1.62,2.19a1.18,1.18,0,0,0-1.32-1.25,1.74,1.74,0,0,0-1.73,1.87,1.19,1.19,0,0,0,1.33,1.26A1.74,1.74,0,0,0,404.46,400.29Z"
        style="fill: #fff"
      ></path>
      <path
        d="M412.17,399.73a3.29,3.29,0,0,1-.06.6l-.55,2.78h-1.18l.55-2.75a2.77,2.77,0,0,0,0-.39c0-.58-.31-.91-1-.91a1.59,1.59,0,0,0-1.64,1.45l-.53,2.6h-1.17l1-5h1.12l-.11.55a2.54,2.54,0,0,1,1.73-.61A1.61,1.61,0,0,1,412.17,399.73Z"
        style="fill: #fff"
      ></path>
    </g>
    <g id="freepik--Plant--inject-17">
      <rect
        x="59.12"
        y="136.5"
        width="136.82"
        height="7.04"
        style="fill: #455a64"
      ></rect>
      <path
        d="M170.74,65.23s0-.08-.1-.22l-.33-.61c-.3-.53-.77-1.3-1.44-2.25a24.2,24.2,0,0,0-7.13-6.74,17.54,17.54,0,0,0-6.4-2.35,15.87,15.87,0,0,0-7.78.81,12.65,12.65,0,0,0-6.65,5.45c-1.53,2.67-2,5.8-2.91,8.79a16.08,16.08,0,0,1-1.86,4.2A12.79,12.79,0,0,1,133,75.42c-1.19.88-2.43,1.67-3.56,2.59a15.33,15.33,0,0,0-2.9,3.14,19.36,19.36,0,0,0-3,7.24,25.89,25.89,0,0,0-.4,6.8,52.16,52.16,0,0,0,1.74,9.68c.3,1.12.55,2,.72,2.57a5.11,5.11,0,0,0,.29.89,6.07,6.07,0,0,0-.19-.91c-.15-.64-.35-1.51-.61-2.6a55.41,55.41,0,0,1-1.56-9.65,25.12,25.12,0,0,1,.45-6.69,19,19,0,0,1,2.93-7,15.4,15.4,0,0,1,2.81-3c1.1-.89,2.34-1.67,3.55-2.56a13.14,13.14,0,0,0,3.29-3.24,16.12,16.12,0,0,0,1.93-4.34c.91-3,1.4-6.13,2.86-8.69a12.15,12.15,0,0,1,6.37-5.24,15.49,15.49,0,0,1,7.54-.84,17.18,17.18,0,0,1,6.26,2.24,24.7,24.7,0,0,1,7.16,6.53c.69.93,1.19,1.68,1.52,2.19A6.44,6.44,0,0,0,170.74,65.23Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M131.58,76.21c.83-.76,1.23-.23,2.34,0a6.83,6.83,0,0,1,3,1.5A12.62,12.62,0,0,1,141,90.91a9.84,9.84,0,0,1-9-14.78"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M165.46,60.79c.41-1,1-.73,2.09-1a7,7,0,0,1,3.34.06A12.63,12.63,0,0,1,180.28,70a9.84,9.84,0,0,1-14.52-9.46"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M124.12,97.08A11.36,11.36,0,0,1,142,98a14.05,14.05,0,0,1-9.26,3.65,14.36,14.36,0,0,1-9.33-3.77"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M126.9,81.5a11.5,11.5,0,0,1-11.62-13.74,10.33,10.33,0,0,1,12,13.62"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M138.82,63.18a11.5,11.5,0,0,1-15.52-9.09,10.33,10.33,0,0,1,15.85,8.85"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M142.82,57.2a11.5,11.5,0,0,1,15.53,9.09,10.34,10.34,0,0,1-15.85-8.86"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M147.59,54a11.5,11.5,0,0,1-6.13-16.91A10.33,10.33,0,0,1,148,54"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M159.79,54.56a11.52,11.52,0,0,1,9.4-15.35A10.32,10.32,0,0,1,160,54.88"
        style="fill: #e0e0e0"
      ></path>
      <polyline
        points="110.58 137.09 104.07 96.26 153.92 96.86 149 136.55"
        style="fill: #263238"
      ></polyline>
      <path
        d="M132.09,88.8a8.5,8.5,0,0,0,8.27.7,9,9,0,0,0,4.53-7.15,9.58,9.58,0,0,0-13.76,5.72"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M154.87,97.59a8.49,8.49,0,0,0,8.28.7,9,9,0,0,0,4.52-7.15,9.57,9.57,0,0,0-13.75,5.72"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M142.08,78a8.5,8.5,0,0,0,6.53,5.13,8.94,8.94,0,0,0,7.71-3.48,9.55,9.55,0,0,0-14.63-2.78"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M150.4,88.13A8.51,8.51,0,0,0,143,91.81a9,9,0,0,0-.17,8.47,9.57,9.57,0,0,0,8.8-12"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M158.43,104.2a8.5,8.5,0,0,0,.2,8.3,8.94,8.94,0,0,0,7.6,3.73,10.17,10.17,0,0,0-.48-8.26c-1.31-2.38-3.67-3.56-6.62-4.23"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M171.68,106.09a5.89,5.89,0,0,0-2-1.25,8.66,8.66,0,0,0-2.71-.48,34.4,34.4,0,0,0-3.68.22,10.69,10.69,0,0,1-4.52-.39,6.93,6.93,0,0,1-3.74-3.61,20.64,20.64,0,0,1-1.61-5.52,16.1,16.1,0,0,0-1.92-5.66,20.4,20.4,0,0,0-4.22-4.28,20.51,20.51,0,0,1-4-4.09,13.51,13.51,0,0,1-2-9.39,13,13,0,0,1,2.45-6,9.93,9.93,0,0,1,1.19-1.31c.3-.28.46-.42.47-.4a2.46,2.46,0,0,1-.39.47,12.3,12.3,0,0,0-1.1,1.37,13.67,13.67,0,0,0-.2,15,20.06,20.06,0,0,0,4,4,20.56,20.56,0,0,1,4.33,4.4A16.33,16.33,0,0,1,154,95a20.25,20.25,0,0,0,1.53,5.39,6.51,6.51,0,0,0,3.45,3.42,10.63,10.63,0,0,0,4.35.43,30.64,30.64,0,0,1,3.72-.14,8.41,8.41,0,0,1,2.76.58,4.44,4.44,0,0,1,1.5,1,2,2,0,0,1,.31.35A.52.52,0,0,1,171.68,106.09Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M171.68,106.09a5.89,5.89,0,0,0-2-1.25,8.66,8.66,0,0,0-2.71-.48,34.4,34.4,0,0,0-3.68.22,10.69,10.69,0,0,1-4.52-.39,6.93,6.93,0,0,1-3.74-3.61,20.64,20.64,0,0,1-1.61-5.52,16.1,16.1,0,0,0-1.92-5.66,20.4,20.4,0,0,0-4.22-4.28,20.51,20.51,0,0,1-4-4.09,13.51,13.51,0,0,1-2-9.39,13,13,0,0,1,2.45-6,9.93,9.93,0,0,1,1.19-1.31c.3-.28.46-.42.47-.4a2.46,2.46,0,0,1-.39.47,12.3,12.3,0,0,0-1.1,1.37,13.67,13.67,0,0,0-.2,15,20.06,20.06,0,0,0,4,4,20.56,20.56,0,0,1,4.33,4.4A16.33,16.33,0,0,1,154,95a20.25,20.25,0,0,0,1.53,5.39,6.51,6.51,0,0,0,3.45,3.42,10.63,10.63,0,0,0,4.35.43,30.64,30.64,0,0,1,3.72-.14,8.41,8.41,0,0,1,2.76.58,4.44,4.44,0,0,1,1.5,1,2,2,0,0,1,.31.35A.52.52,0,0,1,171.68,106.09Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M76.52,110.27A12.61,12.61,0,0,0,59.45,97.85a21,21,0,0,0,16.62,12.27"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M73.23,112c-2.44,2.68-2.28,7-.54,10.16s4.75,5.43,7.75,7.44a16.35,16.35,0,0,0-7-18.57"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M85,99.46a14.54,14.54,0,0,0,16.54,13c-1-3.91-2.52-7.86-5.55-10.52s-7.8-3.65-11.15-1.41"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M85,95.46c-.61-3.56-4.13-6.15-7.73-6.49s-7.17,1.21-10.06,3.39A13.08,13.08,0,0,0,85,94.12"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M66.39,125.8c-3.42,2.92-4.28,8.13-2.86,12.4s4.76,7.67,8.5,10.17c1.44-4.23,2.34-8.77,1.58-13.18s-3.41-8.66-7.51-10.45"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M84.4,85.46a10.52,10.52,0,0,1,16.67-1,9.09,9.09,0,0,1-16.41.42"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M88.6,75.17A13.67,13.67,0,0,1,71.16,64.48a31.55,31.55,0,0,1,8.24.12,13.37,13.37,0,0,1,7.29,3.59,7.24,7.24,0,0,1,1.91,7"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M96.51,72.51a11.17,11.17,0,0,1-.07-17.25,23.4,23.4,0,0,1,4.88,10.42,8.77,8.77,0,0,1,0,4.32,4,4,0,0,1-3,2.82"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.4">
        <path
          d="M76.52,110.27A12.61,12.61,0,0,0,59.45,97.85a21,21,0,0,0,16.62,12.27"
        ></path>
      </g>
      <g style="opacity: 0.4">
        <path
          d="M73.23,112c-2.44,2.68-2.28,7-.54,10.16s4.75,5.43,7.75,7.44a16.35,16.35,0,0,0-7-18.57"
        ></path>
      </g>
      <g style="opacity: 0.4">
        <path
          d="M85,99.46a14.54,14.54,0,0,0,16.54,13c-1-3.91-2.52-7.86-5.55-10.52s-7.8-3.65-11.15-1.41"
        ></path>
      </g>
      <g style="opacity: 0.4">
        <path
          d="M85,95.46c-.61-3.56-4.13-6.15-7.73-6.49s-7.17,1.21-10.06,3.39A13.08,13.08,0,0,0,85,94.12"
        ></path>
      </g>
      <g style="opacity: 0.4">
        <path
          d="M66.39,125.8c-3.42,2.92-4.28,8.13-2.86,12.4s4.76,7.67,8.5,10.17c1.44-4.23,2.34-8.77,1.58-13.18s-3.41-8.66-7.51-10.45"
        ></path>
      </g>
      <g style="opacity: 0.4">
        <path
          d="M84.4,85.46a10.52,10.52,0,0,1,16.67-1,9.09,9.09,0,0,1-16.41.42"
        ></path>
      </g>
      <g style="opacity: 0.4">
        <path
          d="M88.6,75.17A13.67,13.67,0,0,1,71.16,64.48a31.55,31.55,0,0,1,8.24.12,13.37,13.37,0,0,1,7.29,3.59,7.24,7.24,0,0,1,1.91,7"
        ></path>
      </g>
      <g style="opacity: 0.4">
        <path
          d="M96.51,72.51a11.17,11.17,0,0,1-.07-17.25,23.4,23.4,0,0,1,4.88,10.42,8.77,8.77,0,0,1,0,4.32,4,4,0,0,1-3,2.82"
        ></path>
      </g>
      <path
        d="M110.18,97.57a1,1,0,0,1,0-.28c0-.21,0-.48,0-.82,0-.72.11-1.78.14-3.15A28.25,28.25,0,0,0,108.52,82,17.27,17.27,0,0,0,104,75.58a12.16,12.16,0,0,0-8.29-3A10.32,10.32,0,0,0,91,73.7a9.36,9.36,0,0,0-3.62,3.45c-1.8,3-2.25,6.73-2.09,10.42s.66,7.38.24,11a16.82,16.82,0,0,1-1.36,5.08,10.54,10.54,0,0,1-3.07,4,15.45,15.45,0,0,1-4.27,2.19,21.75,21.75,0,0,0-4,1.83,14.48,14.48,0,0,0-5.29,5.76A14,14,0,0,0,66,123.7a15.09,15.09,0,0,0,.8,4.87,18.64,18.64,0,0,0,1.89,3.79,1.19,1.19,0,0,1-.18-.22,6.1,6.1,0,0,1-.48-.67,13,13,0,0,1-1.43-2.83,14.93,14.93,0,0,1-.9-4.94,14.15,14.15,0,0,1,1.49-6.48,14.86,14.86,0,0,1,5.39-6c2.46-1.65,5.72-2.05,8.22-4s3.83-5.33,4.22-8.77-.13-7.12-.27-10.88S85,80,86.93,76.88a9.71,9.71,0,0,1,3.82-3.63A10.94,10.94,0,0,1,95.66,72a12.62,12.62,0,0,1,8.59,3.21,17.71,17.71,0,0,1,4.62,6.6,23.61,23.61,0,0,1,1.57,6.47,35.6,35.6,0,0,1,.14,5c-.06,1.38-.16,2.44-.24,3.16,0,.33-.08.6-.1.82A1,1,0,0,1,110.18,97.57Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.4">
        <path
          d="M110.18,97.57a1,1,0,0,1,0-.28c0-.21,0-.48,0-.82,0-.72.11-1.78.14-3.15A28.25,28.25,0,0,0,108.52,82,17.27,17.27,0,0,0,104,75.58a12.16,12.16,0,0,0-8.29-3A10.32,10.32,0,0,0,91,73.7a9.36,9.36,0,0,0-3.62,3.45c-1.8,3-2.25,6.73-2.09,10.42s.66,7.38.24,11a16.82,16.82,0,0,1-1.36,5.08,10.54,10.54,0,0,1-3.07,4,15.45,15.45,0,0,1-4.27,2.19,21.75,21.75,0,0,0-4,1.83,14.48,14.48,0,0,0-5.29,5.76A14,14,0,0,0,66,123.7a15.09,15.09,0,0,0,.8,4.87,18.64,18.64,0,0,0,1.89,3.79,1.19,1.19,0,0,1-.18-.22,6.1,6.1,0,0,1-.48-.67,13,13,0,0,1-1.43-2.83,14.93,14.93,0,0,1-.9-4.94,14.15,14.15,0,0,1,1.49-6.48,14.86,14.86,0,0,1,5.39-6c2.46-1.65,5.72-2.05,8.22-4s3.83-5.33,4.22-8.77-.13-7.12-.27-10.88S85,80,86.93,76.88a9.71,9.71,0,0,1,3.82-3.63A10.94,10.94,0,0,1,95.66,72a12.62,12.62,0,0,1,8.59,3.21,17.71,17.71,0,0,1,4.62,6.6,23.61,23.61,0,0,1,1.57,6.47,35.6,35.6,0,0,1,.14,5c-.06,1.38-.16,2.44-.24,3.16,0,.33-.08.6-.1.82A1,1,0,0,1,110.18,97.57Z"
        ></path>
      </g>
      <path
        d="M182.76,88.66a10.53,10.53,0,0,0,.75-3.65,9.2,9.2,0,0,0-1-4.22,8.81,8.81,0,0,0-4.07-3.92,9.08,9.08,0,0,0-3.2-.87,10.36,10.36,0,0,0-3.58.42c-2.45.65-4.86,2-7.75,2.61a9.25,9.25,0,0,1-4.49-.11A6.7,6.7,0,0,1,155.79,76c-1.74-2.7-2.24-6.08-4.34-8.57a8.52,8.52,0,0,0-3.94-2.58,11.09,11.09,0,0,0-4.6-.46,11.77,11.77,0,0,0-7.65,4,18.13,18.13,0,0,0-3.8,7,21.16,21.16,0,0,0-.84,7,76.44,76.44,0,0,0,1.29,10.18c.2,1.16.36,2.08.47,2.76.05.29.08.53.11.72a.94.94,0,0,1,0,.25,1,1,0,0,1-.07-.24c-.05-.19-.1-.42-.16-.71-.14-.67-.34-1.59-.58-2.74a71.05,71.05,0,0,1-1.47-10.21,21.62,21.62,0,0,1,.8-7.1A18.52,18.52,0,0,1,134.89,68a13.54,13.54,0,0,1,3.5-2.79,11.61,11.61,0,0,1,4.47-1.38,11.49,11.49,0,0,1,4.82.48,9.08,9.08,0,0,1,4.18,2.74c2.23,2.66,2.7,6.07,4.37,8.64a6.29,6.29,0,0,0,3.37,2.72,8.7,8.7,0,0,0,4.24.11c2.79-.53,5.22-1.9,7.73-2.55a10.66,10.66,0,0,1,3.73-.4,9.29,9.29,0,0,1,3.34.93,9.12,9.12,0,0,1,4.17,4.14,9.26,9.26,0,0,1,.91,4.36,8.7,8.7,0,0,1-.54,2.76A4,4,0,0,1,182.76,88.66Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M153.61,70.57a9.66,9.66,0,0,0,8.89.59A9,9,0,0,0,167,64a9.58,9.58,0,0,0-13.76,5.72"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M163.06,79.15A9.66,9.66,0,0,0,167.19,87a9,9,0,0,0,8.46.15,10.18,10.18,0,0,0-3.94-7.28,10.07,10.07,0,0,0-8.11-1.49"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M179.45,77.25a9.71,9.71,0,0,0,8.9-.37,9,9,0,0,0,3.74-7.59A9.57,9.57,0,0,0,179,76.44"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M139.84,64.9c-2.39-2.06-4-5.68-3.53-8.39a9,9,0,0,1,5.49-6.44,9.56,9.56,0,0,1-1.4,14.83"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M75.83,53.16s0-.08.1-.22l.33-.61c.3-.53.77-1.3,1.44-2.25a24.2,24.2,0,0,1,7.13-6.74A17.54,17.54,0,0,1,91.23,41,15.86,15.86,0,0,1,99,41.8a12.65,12.65,0,0,1,6.65,5.45c1.53,2.67,2,5.8,2.92,8.79a15.88,15.88,0,0,0,1.85,4.2,12.79,12.79,0,0,0,3.16,3.11c1.19.88,2.44,1.67,3.56,2.59a15.33,15.33,0,0,1,2.9,3.14,19.19,19.19,0,0,1,3,7.24,25.45,25.45,0,0,1,.4,6.8,52.16,52.16,0,0,1-1.74,9.68c-.3,1.12-.55,2-.72,2.57a5.56,5.56,0,0,1-.29.89,6.07,6.07,0,0,1,.19-.91c.15-.64.35-1.51.61-2.6A56.4,56.4,0,0,0,123,83.1a25.57,25.57,0,0,0-.46-6.69,19,19,0,0,0-2.93-7,14.84,14.84,0,0,0-2.81-3c-1.1-.89-2.34-1.67-3.55-2.56A13.14,13.14,0,0,1,110,60.54a16.26,16.26,0,0,1-1.93-4.34c-.91-3.05-1.4-6.13-2.86-8.69a12.1,12.1,0,0,0-6.37-5.24,15.35,15.35,0,0,0-7.53-.84A17.17,17.17,0,0,0,85,43.67a24.83,24.83,0,0,0-7.16,6.53c-.69.93-1.19,1.68-1.52,2.19A6.44,6.44,0,0,1,75.83,53.16Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M115,64.14c-.83-.77-1.23-.23-2.34,0a6.92,6.92,0,0,0-3,1.49,12.64,12.64,0,0,0-4.09,13.24,9.84,9.84,0,0,0,9-14.79"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M81.12,48.72c-.42-1-1-.74-2.1-1a7,7,0,0,0-3.34.06A12.6,12.6,0,0,0,66.3,57.94a9.84,9.84,0,0,0,14.51-9.46"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M122.45,85a11.36,11.36,0,0,0-17.86.87,14.07,14.07,0,0,0,9.26,3.65,14.36,14.36,0,0,0,9.33-3.77"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M119.67,69.43a11.5,11.5,0,0,0,11.62-13.74,10.33,10.33,0,0,0-12,13.62"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M107.75,51.11A11.5,11.5,0,0,0,123.27,42a10.34,10.34,0,0,0-15.85,8.86"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M103.75,45.12a11.52,11.52,0,0,0-15.53,9.1,10.34,10.34,0,0,0,15.85-8.86"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M99,41.9A11.51,11.51,0,0,0,105.11,25a10.34,10.34,0,0,0-6.52,17"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M86.78,42.49a11.52,11.52,0,0,0-9.4-15.35,10.32,10.32,0,0,0,9.17,15.67"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <polygon
        points="103.44 132.36 107.25 137.09 154.16 137.09 157.36 132.36 103.44 132.36"
        style="fill: #263238"
      ></polygon>
      <path
        d="M156.33,132.31c0,.15-11.53.27-25.75.27s-25.74-.12-25.74-.27,11.52-.27,25.74-.27S156.33,132.16,156.33,132.31Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M151.06,114.5a1.82,1.82,0,0,1-.3.39,10.88,10.88,0,0,1-.95,1,17.54,17.54,0,0,1-4.19,2.91,18.5,18.5,0,0,1-7.32,1.88,17.75,17.75,0,0,1-4.48-.38,25.74,25.74,0,0,1-4.57-1.51c-3-1.29-5.77-2.76-8.45-3.61a14.53,14.53,0,0,0-7.3-.7,9.4,9.4,0,0,0-4.48,2.08,15.72,15.72,0,0,0-1.27,1.33s.06-.16.23-.43a7.25,7.25,0,0,1,.9-1.06,9.31,9.31,0,0,1,4.54-2.3,14.61,14.61,0,0,1,7.53.61c2.74.84,5.52,2.31,8.51,3.58a26.34,26.34,0,0,0,4.47,1.49,17.2,17.2,0,0,0,4.35.4,18.53,18.53,0,0,0,7.17-1.73A22.24,22.24,0,0,0,151.06,114.5Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M151.52,110.84s-.1.14-.32.36a11,11,0,0,1-1,.94,19.1,19.1,0,0,1-4.34,2.69,20.21,20.21,0,0,1-7.37,1.76,19.76,19.76,0,0,1-4.49-.3,29.09,29.09,0,0,1-4.64-1.31c-3.1-1.12-5.94-2.42-8.66-3.17a17.12,17.12,0,0,0-7.36-.67,11,11,0,0,0-4.64,1.77c-1,.69-1.4,1.21-1.44,1.17s.09-.14.29-.39a8,8,0,0,1,1-1,10.81,10.81,0,0,1,4.7-2,17.08,17.08,0,0,1,7.56.57c2.77.74,5.64,2,8.7,3.14a29.73,29.73,0,0,0,4.55,1.3,20.05,20.05,0,0,0,4.38.33,20.73,20.73,0,0,0,7.24-1.62A25.83,25.83,0,0,0,151.52,110.84Z"
        style="fill: #455a64"
      ></path>
      <rect
        x="96.17"
        y="141.69"
        width="4.11"
        height="11.45"
        style="fill: #455a64"
      ></rect>
      <rect
        x="154.78"
        y="141.69"
        width="4.11"
        height="11.45"
        style="fill: #455a64"
      ></rect>
    </g>
    <g id="freepik--Character--inject-17">
      <polyline
        points="211.45 248.39 229.75 240.66 273.5 232 320.71 241.27 258.34 308.29 228.77 309.98 206.36 265.45 211.45 248.39"
        style="fill: #ffbe9d"
      ></polyline>
      <path
        d="M370.41,299.51s-29.81-47.87-37.84-53.58-33.6-9.32-33.6-9.32S270.81,300.2,244,298s-29.75-50.88-29.75-50.88l-11.81,4.71a15.63,15.63,0,0,0-9,9.44L169.8,330.09,210,339.65c7.06,26.3,17,49.18,29.48,69l1.28-6.57,83.53-1.29,24.19-9.36-29.13-66.2Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M216.11,358.58a2.19,2.19,0,0,1-.24-.54c-.14-.41-.33-.93-.56-1.57-.5-1.43-1.19-3.4-2-5.8-1.68-4.9-4-11.68-6.15-19.31-1-3.83-1.9-7.51-2.55-10.88s-1.05-6.45-1.27-9c-.15-1.29-.18-2.46-.24-3.49S203,306,203,305.3v-1.67a2.39,2.39,0,0,1,0-.58,2.45,2.45,0,0,1,.07.58c0,.44,0,1,.09,1.67s.07,1.6.17,2.63.15,2.19.32,3.48c.26,2.57.74,5.62,1.37,9s1.53,7,2.58,10.83c2.11,7.61,4.41,14.4,6,19.34.8,2.46,1.44,4.46,1.86,5.85.19.65.35,1.18.47,1.6A2.88,2.88,0,0,1,216.11,358.58Z"
        style="fill: #263238"
      ></path>
      <path
        d="M300.2,347.56a2,2,0,0,1-.44.25c-.3.15-.74.39-1.33.63a32.58,32.58,0,0,1-5.12,1.74,35.43,35.43,0,0,1-33.2-8.61,32.62,32.62,0,0,1-3.62-4c-.4-.5-.66-.92-.86-1.2a1.7,1.7,0,0,1-.26-.44s.46.54,1.27,1.51a39.49,39.49,0,0,0,3.72,3.87,36.22,36.22,0,0,0,32.87,8.52,37.59,37.59,0,0,0,5.12-1.56A18.7,18.7,0,0,1,300.2,347.56Z"
        style="fill: #263238"
      ></path>
      <path
        d="M309.06,272.32a6.22,6.22,0,0,1,.25-1.19c.19-.76.52-1.85,1-3.17a48.64,48.64,0,0,1,4.88-9.81,47.9,47.9,0,0,1,7-8.46,33.14,33.14,0,0,1,2.49-2.2,5.44,5.44,0,0,1,1-.72c.06.07-1.26,1.19-3.21,3.18a54.18,54.18,0,0,0-11.75,18.14C309.63,270.68,309.15,272.35,309.06,272.32Z"
        style="fill: #263238"
      ></path>
      <path
        d="M311.82,325.67c-.14,0-1.31-8.88-2.62-19.87s-2.25-19.92-2.11-19.93,1.32,8.88,2.63,19.87S312,325.66,311.82,325.67Z"
        style="fill: #263238"
      ></path>
      <g style="opacity: 0.30000000000000004">
        <path
          d="M255.9,337.78c4.27,6.27,8.7,12.61,14.76,17.18s14.11,7.13,21.36,4.9a24.31,24.31,0,0,0,6.45-3.21c2.87-1.92,5.66-4.55,6.07-8a2,2,0,0,0-.73-2.12,2.21,2.21,0,0,0-1.42-.07c-5.1,1-9.55,4.57-14.74,4.95a24.85,24.85,0,0,1-7.87-1.11c-9-2.27-17.72-5.61-23.88-12.55"
        ></path>
      </g>
      <g style="opacity: 0.30000000000000004">
        <path
          d="M315.88,365.51c1.3-1.57,2.18-2.86,3.4-4.31a6.85,6.85,0,0,0,1.29-2.57,7.93,7.93,0,0,0,0-3.29,144.06,144.06,0,0,0-6.7-27.23,1,1,0,0,1,.63-1.58c1.52-.47,3.3-1.1,4.82-1.57,2.23,11,4.08,23.22,6.28,33.77,0-.15-6.51,4.64-9.73,6.78"
        ></path>
      </g>
      <g style="opacity: 0.30000000000000004">
        <path
          d="M209.61,339.77a43,43,0,0,1-8.75-12.21,26.57,26.57,0,0,1-2.14-6.09,32,32,0,0,1-.42-7.19,111.43,111.43,0,0,1,3.33-24.18,3.48,3.48,0,0,1,.59,1.58c1.52,8.18.59,16.65,1.86,24.87.52,3.32,1.39,6.57,1.93,9.88a41.4,41.4,0,0,0,1,5.06c.44,1.49,1.12,2.91,1.6,4.39a35.35,35.35,0,0,1,1,4.31"
        ></path>
      </g>
      <path
        d="M271.89,396.87l53.7-38.29L319.33,325l35.2-18.2s12.77,53.48,4.33,74.11C356,388,269,415.63,269,415.63Z"
        style="fill: #ffbe9d"
      ></path>
      <path
        d="M345.89,355.29c0,.09-1.2-.35-3.16-.71a23.56,23.56,0,0,0-3.48-.34,22.2,22.2,0,0,0-8.37,1.45,22.88,22.88,0,0,0-3.15,1.49c-1.73,1-2.68,1.81-2.74,1.74s.19-.25.62-.63a14.46,14.46,0,0,1,1.93-1.42,20.14,20.14,0,0,1,3.17-1.63,22.84,22.84,0,0,1,4.2-1.19,22.17,22.17,0,0,1,4.36-.29,19.71,19.71,0,0,1,3.53.46,15.4,15.4,0,0,1,2.3.69A3,3,0,0,1,345.89,355.29Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M338.27,348.59a24.71,24.71,0,0,1-2.22,1.21c-1.36.75-3.2,1.89-5.16,3.22a19.46,19.46,0,0,0-4.49,4c-.94,1.22-1.29,2.14-1.37,2.1a5.74,5.74,0,0,1,1.07-2.32,17,17,0,0,1,4.49-4.22,41.37,41.37,0,0,1,5.29-3.12A8.5,8.5,0,0,1,338.27,348.59Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M229.1,422.69c2.88,0,5.21,0,5.21.07s-2.33.07-5.21.07-5.21,0-5.21-.07S226.23,422.69,229.1,422.69Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M235.64,400.58s-99.32-40.75-115.8-44.28-45.35-5.26-57.5,19c-8.49,16.93-9.42,37,23,54.1s128.88,33,128.88,33,87.08,21.76,101.21,15.88,33.56-18.24,27.68-41.78-4.59-35.67-25.81-37c-20.93-1.28-26.08-2-46,17.21Z"
        style="fill: #263238"
      ></path>
      <path
        d="M348.05,390.31s17.84,29.79,11.08,47-24,28.42-24,28.42L306.38,403Z"
        style="fill: #263238"
      ></path>
      <path
        d="M225.87,442.44l-.36-.17-1.06-.54c-.92-.48-2.28-1.2-4-2.17-3.45-1.94-8.41-4.82-14.25-8.83s-12.57-9.1-19.87-15c-3.65-2.92-7.49-6-11.43-9.21s-8-6.57-12.39-9.71a102.44,102.44,0,0,0-13.39-8.2,119.62,119.62,0,0,0-13.47-5.75,182.78,182.78,0,0,0-23.91-6.61c-6.92-1.47-12.56-2.42-16.46-3.1l-4.49-.77-1.17-.21-.39-.09.4,0,1.18.16,4.5.67c3.91.62,9.57,1.52,16.51,2.94a179.12,179.12,0,0,1,24,6.52,120,120,0,0,1,13.53,5.74,102.83,102.83,0,0,1,13.47,8.23c4.39,3.16,8.47,6.52,12.4,9.74s7.77,6.3,11.41,9.24c7.28,5.86,13.95,11,19.78,15s10.73,7,14.14,8.95c1.71,1,3.05,1.75,4,2.26.44.24.78.44,1,.59A1.67,1.67,0,0,1,225.87,442.44Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M227.11,441.8c4.24-1.74-9.07,2.67-13.49,1.44-7-1.95-11.77-9.95-19.07-10.15-4.69-.12-8.72,3.11-13.13,4.72-5.39,2-11.34,1.5-17,.65s-11.4-2-17.1-1.39a7.34,7.34,0,0,0-4.58,1.78,5.27,5.27,0,0,0-.49,6.14,9.19,9.19,0,0,0,5.19,3.86,39.21,39.21,0,0,0,6.48,1.23c10.21,1.53,19.85,5.57,29.87,8.06,10.34,2.58,21,3.5,31.64,4.4C215.42,462.54,214.69,446.88,227.11,441.8Z"
        style="fill: #ffbe9d"
      ></path>
      <path
        d="M319.26,442.42a1.19,1.19,0,0,1,.17.4,11.88,11.88,0,0,1,.39,1.19,24.54,24.54,0,0,1,.84,4.51,24.26,24.26,0,0,1-.34,6.81,21.26,21.26,0,0,1-3.13,7.73,19.07,19.07,0,0,1-6.11,5.69,17.89,17.89,0,0,1-6.41,2.3,14.91,14.91,0,0,1-4.6,0,9.36,9.36,0,0,1-1.22-.26,1.94,1.94,0,0,1-.42-.13,20,20,0,0,0,6.18,0,18.21,18.21,0,0,0,6.23-2.35,19,19,0,0,0,5.91-5.57,21.28,21.28,0,0,0,3.1-7.54,24.82,24.82,0,0,0,.44-6.68,28.65,28.65,0,0,0-.66-4.49A13,13,0,0,1,319.26,442.42Z"
        style="fill: #455a64"
      ></path>
      <polygon
        points="250.66 422.78 311.23 395.07 330.53 399.11 331.37 401.19 260.58 429.57 250.66 427.18 250.66 422.78"
        style="fill: #fff"
      ></polygon>
      <polygon
        points="312.91 397.63 250.66 427.18 271.09 432.1 335.44 401.97 312.91 397.63"
        style="fill: #455a64"
      ></polygon>
      <path
        d="M227.11,441.8s32-21.85,40.41-26.21,53-14.56,64.13-9.93-33.22,44.44-33.22,44.44Z"
        style="fill: #263238"
      ></path>
      <path
        d="M292.25,424.26a1.06,1.06,0,0,1-.24-.09l-.68-.29-2.59-1.14a69.26,69.26,0,0,0-9.75-3.58,31.35,31.35,0,0,0-7.05-1.11,25.48,25.48,0,0,0-4,.17,24.57,24.57,0,0,0-4.1.91c-5.56,1.69-10.86,5.41-16.32,9.27s-11,7.24-15.83,10.41c-2.44,1.58-4.74,3.12-6.85,4.6a19.14,19.14,0,0,0-5.14,4.88,22.85,22.85,0,0,0-2.68,5.24c-.61,1.63-1,3.07-1.34,4.26s-.53,2.08-.7,2.74l-.19.72a.75.75,0,0,1-.08.24.84.84,0,0,1,0-.26c0-.19.08-.43.13-.72.13-.64.32-1.57.62-2.77a41.29,41.29,0,0,1,1.26-4.31,23,23,0,0,1,2.65-5.35,19.32,19.32,0,0,1,5.19-5c2.11-1.51,4.4-3.06,6.84-4.65,4.86-3.2,10.31-6.56,15.79-10.43s10.81-7.61,16.48-9.31a23.18,23.18,0,0,1,8.22-1.05,31,31,0,0,1,7.13,1.18,63.35,63.35,0,0,1,9.74,3.76c1.12.52,2,.94,2.55,1.23l.66.33A.83.83,0,0,1,292.25,424.26Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M297.43,422.5c-.06.07-.9-.74-2.52-1.64a18.92,18.92,0,0,0-6.84-2.11,41.32,41.32,0,0,0-7.24-.23,22.1,22.1,0,0,1-3,.1,14.89,14.89,0,0,1,3-.47,33.6,33.6,0,0,1,7.33.08,17.17,17.17,0,0,1,7,2.32,10.94,10.94,0,0,1,1.78,1.34C297.27,422.25,297.45,422.48,297.43,422.5Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M339.27,409.78s-.65-.42-1.8-1.19a27.87,27.87,0,0,0-5.28-2.54,34.07,34.07,0,0,0-8.5-1.72,54.31,54.31,0,0,0-10.64.29c-3.76.46-7.32,1.14-10.56,1.76s-6.12,1.26-8.55,1.82-4.38,1.05-5.73,1.39l-1.56.39a2.61,2.61,0,0,1-.55.11,2.37,2.37,0,0,1,.52-.21l1.54-.48c1.33-.4,3.27-.95,5.69-1.56s5.32-1.27,8.55-1.93,6.79-1.35,10.59-1.81a52.89,52.89,0,0,1,10.74-.25,33,33,0,0,1,8.59,1.86,24.92,24.92,0,0,1,5.26,2.71c.58.4,1,.76,1.28,1S339.29,409.77,339.27,409.78Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M186.34,334.5s5.37,40.44,7.53,46.87S213,411.3,213,411.3l22.62-10.72-15.12-28.45L210.05,340Z"
        style="fill: #ffbe9d"
      ></path>
      <path
        d="M234.7,399.11a27.92,27.92,0,0,1,5.92-.27,3.85,3.85,0,0,1,2.13.59,3.71,3.71,0,0,1,.87,1.12,14.06,14.06,0,0,1,1.81,6.61,1.85,1.85,0,0,1-.3,1.25c-.49.58-1.42.4-2.11.1a10.48,10.48,0,0,1-2.68-1.67,21.12,21.12,0,0,1-2.84-3.21l-.88-1.15c-.75-1-1.5-1.93-2.24-2.9"
        style="fill: #eb996e"
      ></path>
      <path
        d="M226.53,381.66c1.65,4.22,6.64,17.64,6.64,17.64l3.45,3.78-7.8-22.26a1.22,1.22,0,0,0-1.47-.78h0A1.23,1.23,0,0,0,226.53,381.66Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M222.23,400.45s-4.83.37-6.18,1.79-1.79,5.7-1.79,5.7-2.77,3.9-1.3,7.32c0,0-2.32,5.37-.43,7.15a11,11,0,0,0,2.71,2.12H237l8.62-13.23L237,403.06s-5.4-8-8.32-8.3S219.94,395.05,222.23,400.45Z"
        style="fill: #ffbe9d"
      ></path>
      <path
        d="M222.23,400.45a2.59,2.59,0,0,1-.17-.4,4.51,4.51,0,0,1-.35-1.16,3.28,3.28,0,0,1,.21-2,3.59,3.59,0,0,1,2-1.77,9.17,9.17,0,0,1,3.31-.61,6.84,6.84,0,0,1,1.93.16,5.71,5.71,0,0,1,1.81,1.05,36.56,36.56,0,0,1,6.23,7.18l0,0,8.64,8.22.15.14-.12.17-8.61,13.24-.07.12h-1.29l-20.65,0h-.06l0,0a12.28,12.28,0,0,1-2.38-1.8,3.1,3.1,0,0,1-.93-1.17,4,4,0,0,1-.27-1.43,14,14,0,0,1,1.2-5.18v.17a6.5,6.5,0,0,1-.18-4.12,11.12,11.12,0,0,1,1.53-3.38l0,.08a14.81,14.81,0,0,1,1.41-5.14,2.86,2.86,0,0,1,.76-.89,4.64,4.64,0,0,1,.93-.49,9.32,9.32,0,0,1,1.74-.52,20.19,20.19,0,0,1,2.45-.39l.63,0h.22s-.08,0-.21.05l-.63.09c-.55.09-1.38.21-2.42.46a11.77,11.77,0,0,0-1.69.55,5.13,5.13,0,0,0-.88.49,2.73,2.73,0,0,0-.67.82,14.81,14.81,0,0,0-1.3,5v0l0,0a10.75,10.75,0,0,0-1.44,3.28,6.14,6.14,0,0,0,.19,3.87l0,.07,0,.09a13.49,13.49,0,0,0-1.13,5,3.58,3.58,0,0,0,.24,1.27,2.68,2.68,0,0,0,.82,1,12.37,12.37,0,0,0,2.24,1.7l-.08,0,20.65,0H237l-.22.12,8.63-13.22,0,.31-8.6-8.27h0v0a37.17,37.17,0,0,0-6.1-7.16,5.34,5.34,0,0,0-1.68-1,6.73,6.73,0,0,0-1.84-.18,8.85,8.85,0,0,0-3.21.54,3.41,3.41,0,0,0-1.94,1.62,3.22,3.22,0,0,0-.25,1.87,6.38,6.38,0,0,0,.29,1.2C222.2,400.33,222.24,400.46,222.23,400.45Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M232.45,418.09a15.06,15.06,0,0,1-2.81-.23c-.87-.1-1.89-.23-3-.39a7.86,7.86,0,0,1-1.8-.47,8.06,8.06,0,0,1-1.71-1,26.88,26.88,0,0,1-4.89-4.79,9.78,9.78,0,0,1-1.53-2.38c.06-.05.69.83,1.82,2.14a32.14,32.14,0,0,0,4.91,4.61,7.68,7.68,0,0,0,1.6,1,8,8,0,0,0,1.68.47l3,.5A15.31,15.31,0,0,1,232.45,418.09Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M231.54,423.71a11.52,11.52,0,0,1-2.71.32,16.45,16.45,0,0,1-3-.1,11.71,11.71,0,0,1-3.52-1.07,20,20,0,0,1-5.28-4,12.52,12.52,0,0,1-1.81-2.06,16.85,16.85,0,0,1,2.06,1.79,23.31,23.31,0,0,0,5.25,3.8,13.93,13.93,0,0,0,6.28,1.27A19.35,19.35,0,0,1,231.54,423.71Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M235.38,412.52a9.37,9.37,0,0,1-2.16-1.09A33.85,33.85,0,0,1,228.5,408a33.31,33.31,0,0,1-4.06-4.2,8.39,8.39,0,0,1-1.37-2c.12-.1,2.35,2.84,5.77,5.8S235.47,412.38,235.38,412.52Z"
        style="fill: #eb996e"
      ></path>
      <polygon
        points="186.34 430.47 186.34 422.78 250.66 422.78 250.66 425.26 250.66 425.76 241.65 432.1 186.34 430.47"
        style="fill: #fff"
      ></polygon>
      <polygon
        points="240.46 432.67 181.53 432.67 181.53 429.57 245.09 429.57 240.46 432.67"
        style="fill: #455a64"
      ></polygon>
      <path
        d="M261.55,399.25c-.68.13-15,2.59-15,2.59l-10.22,12.27v8.67s3.13,1.82,4.63-.9c0,0-3.14,8.86-1.91,10.16s3.21,2.38,4.91,0a57.87,57.87,0,0,0,4.09-8.26l.41,3.66s8.59-4.2,9.54-5.43,6.55-9.27,6.55-9.27l2.59-2.32-2.59,4.09s-4.64,4.91-2.73,5.87,5.32-2.59,5.32-2.59l5.31-6.14,4.5-.54a38.42,38.42,0,0,0-.68-8.87c-.31-1.89-3.54-5.71-3.22-6.11,0,0,0-.07,0-.06Z"
        style="fill: #ffbe9d"
      ></path>
      <path
        d="M249.66,405.74a20.72,20.72,0,0,1-1.76,2.17,62.11,62.11,0,0,0-4,5.35,11.38,11.38,0,0,0-1.11,3.43c-.3,1.1-.63,2.08-.92,2.89a12.72,12.72,0,0,1-1.08,2.58,18,18,0,0,1,.73-2.69c.25-.82.53-1.8.8-2.9.14-.56.25-1.13.38-1.74a5.3,5.3,0,0,1,.76-1.85,41.05,41.05,0,0,1,4.18-5.31A11.67,11.67,0,0,1,249.66,405.74Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M256.91,407.79s-.17.22-.51.58L255,409.92c-.59.66-1.29,1.46-2,2.35-.37.45-.77.92-1.16,1.42a5.22,5.22,0,0,0-1,1.62c-1.22,4.13-2.27,7.7-2.71,8.86l-.12.32,0-.33c0-.36-.05-.53,0-.54s.09.16.18.51H248c.25-1.24,1.14-4.79,2.38-9a4,4,0,0,1,.46-.95c.19-.29.39-.55.59-.8.4-.51.81-1,1.2-1.42.78-.89,1.51-1.66,2.13-2.29A9,9,0,0,1,256.91,407.79Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M260.6,410.37a8.84,8.84,0,0,1-1.43,1.85l-1.66,1.9a11.66,11.66,0,0,0-.91,1.14,7.09,7.09,0,0,0-.68,1.38c-.37,1-.61,2-.88,2.94l-.71,2.42a10.4,10.4,0,0,1-.78,2.2,10.62,10.62,0,0,1,.42-2.29l.61-2.46a30.49,30.49,0,0,1,.85-3,6.78,6.78,0,0,1,.75-1.47,12,12,0,0,1,1-1.18c.64-.71,1.25-1.32,1.75-1.83A13.52,13.52,0,0,1,260.6,410.37Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M277.42,410.54a1.31,1.31,0,0,1-.31.09l-.95.24-3.68.87,0,0c-.73.86-1.57,1.85-2.53,3l-1.52,1.78a22.88,22.88,0,0,1-1.73,1.92,12.61,12.61,0,0,1-2.28,1.72,5,5,0,0,1-1.43.57,1.76,1.76,0,0,1-1.62-.37,1.11,1.11,0,0,1-.23-.88,3.28,3.28,0,0,1,.22-.83,9,9,0,0,1,.82-1.48,22.69,22.69,0,0,1,2.17-2.72l0,0,2.58-4.1.32.27-2.58,2.33,0,0-6.16,8.77c-.14.19-.26.38-.42.57a3.77,3.77,0,0,1-.58.5c-.39.29-.79.55-1.2.8-.82.5-1.66,1-2.52,1.43q-2.57,1.4-5.28,2.73l-.32.16,0-.36c-.13-1.15-.27-2.4-.41-3.66l.49.07c-.68,1.64-1.4,3.29-2.22,4.94-.4.83-.83,1.65-1.31,2.46a5.3,5.3,0,0,1-2,2.15,2.54,2.54,0,0,1-2,.11,4.77,4.77,0,0,1-1.65-1.06,3.22,3.22,0,0,1-.35-.37,1.42,1.42,0,0,1-.21-.52,4.18,4.18,0,0,1-.06-1,14,14,0,0,1,.24-1.85,54.82,54.82,0,0,1,2-7l.47.21a3.05,3.05,0,0,1-2.3,1.61,4.89,4.89,0,0,1-2.69-.62l-.12-.06v-.15q0-4.48,0-8.67V414l0-.07c3.71-4.43,7.15-8.54,10.26-12.24l0-.06.08,0,15-2.53h0l8.54-2.3,2.27-.59.59-.14c.06,0,.12-.05.15,0s0,.05,0,.05-.21,0-.71.17l-2.25.66-8.5,2.42h0l-15,2.65.12-.07-10.2,12.29.06-.15q0,4.18,0,8.67l-.13-.22a4.34,4.34,0,0,0,2.38.54,2.47,2.47,0,0,0,1.91-1.35l.46.21a55.45,55.45,0,0,0-1.94,6.88,13.55,13.55,0,0,0-.23,1.78,4,4,0,0,0,.05.86,1,1,0,0,0,.41.63,2.77,2.77,0,0,0,3.08.86,4.83,4.83,0,0,0,1.74-2c.47-.79.89-1.6,1.3-2.42.8-1.64,1.53-3.28,2.2-4.9l.39-.93.11,1c.14,1.25.28,2.5.4,3.65l-.36-.2c1.8-.88,3.56-1.79,5.26-2.7.85-.47,1.69-.93,2.5-1.42.4-.25.8-.5,1.17-.77a3.55,3.55,0,0,0,.49-.42c.15-.17.28-.38.41-.56,2.16-3,4.23-6,6.18-8.73l0,0h0l2.59-2.32,1.16-1-.83,1.3-2.6,4.08v0h0a23.06,23.06,0,0,0-2.15,2.67,8.42,8.42,0,0,0-.78,1.4c-.2.47-.33,1-.07,1.34a1.47,1.47,0,0,0,1.29.26,4.6,4.6,0,0,0,1.33-.52,12.16,12.16,0,0,0,2.23-1.65,25.26,25.26,0,0,0,1.74-1.89l1.53-1.75,2.57-2.92,0,0h0l3.71-.79,1-.19A1.92,1.92,0,0,1,277.42,410.54Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M204.21,187.63a15.14,15.14,0,0,1-5.82-15.2,2,2,0,0,0-1.27-2.9,11.22,11.22,0,0,1-5.59-6.87,8.69,8.69,0,0,1,2.27-8.39c-4.43-1.57-6.29-7.53-4.21-11.74s7.2-6.38,11.87-5.82c-1.61-2.37-.91-5.78,1-7.91a13.21,13.21,0,0,1,7.59-3.67c3.79-.69,8.34-.25,10.51,2.93.2-2.35,2.57-3.93,4.86-4.5a12.91,12.91,0,0,1,14.91,8.57,11.77,11.77,0,0,1,6.63-2.66c2.42,0,5,1.46,5.52,3.82,1-2.15,3.64-3,6-3.26a8.65,8.65,0,0,1,5,.8,4.23,4.23,0,0,1,2.24,4.29,6.72,6.72,0,0,1,5.8,12.07,8.56,8.56,0,0,1,.6,14.58c1.44.12,2.17,2.06,1.52,3.36a4.61,4.61,0,0,1-3.58,2.16l1.71,14.9a30.88,30.88,0,0,1,.09,9.64c-.65,3.16-2.5,6.25-5.45,7.56"
        style="fill: #263238"
      ></path>
      <path
        d="M202.65,173.28c-.74-12.09,10.69-25.69,22.8-25.92l39.69,2.53.68,6.47,9.24,83.45-43.24,24.65-2.66-31.41s-15.64-.62-21.32-20.22C205,203.08,203.48,186.71,202.65,173.28Z"
        style="fill: #ffbe9d"
      ></path>
      <path
        d="M209,187.91a2.6,2.6,0,0,0,2.8,2.28,2.46,2.46,0,0,0,2.35-2.64,2.58,2.58,0,0,0-2.79-2.28A2.47,2.47,0,0,0,209,187.91Z"
        style="fill: #263238"
      ></path>
      <path
        d="M207.25,181.32c.36.29,2.13-1.39,4.89-1.73s5,.79,5.21.42c.13-.17-.27-.73-1.23-1.27a7.14,7.14,0,0,0-4.23-.74,6.77,6.77,0,0,0-3.83,1.79C207.29,180.55,207.08,181.19,207.25,181.32Z"
        style="fill: #263238"
      ></path>
      <path
        d="M233.68,185.9a2.58,2.58,0,0,0,2.8,2.28,2.47,2.47,0,0,0,2.35-2.64,2.58,2.58,0,0,0-2.79-2.28A2.47,2.47,0,0,0,233.68,185.9Z"
        style="fill: #263238"
      ></path>
      <path
        d="M234.44,179c.36.3,2.13-1.38,4.89-1.72s4.95.79,5.21.41c.13-.17-.27-.73-1.23-1.26a7.13,7.13,0,0,0-4.23-.75,6.83,6.83,0,0,0-3.83,1.79C234.48,178.27,234.27,178.91,234.44,179Z"
        style="fill: #263238"
      ></path>
      <path
        d="M227,200.16c0-.16-1.76-.24-4.59-.25-.71,0-1.39,0-1.57-.51a3.58,3.58,0,0,1,.21-2.14c.44-1.79.92-3.67,1.41-5.63,1.94-8,3.25-14.55,2.92-14.62s-2.15,6.35-4.09,14.35L220,197a4.1,4.1,0,0,0,0,2.81,1.76,1.76,0,0,0,1.29.88,4.51,4.51,0,0,0,1.21,0C225.26,200.52,227,200.32,227,200.16Z"
        style="fill: #263238"
      ></path>
      <path
        d="M229.16,233.06a51.28,51.28,0,0,0,25.89-10.5s-5.36,14.86-25.61,15.49Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M229,206.66a5,5,0,0,1,4.22-2.4,4.56,4.56,0,0,1,3.35,1.29,2.9,2.9,0,0,1,.63,3.26,3.37,3.37,0,0,1-3.51,1.37,10.6,10.6,0,0,1-3.79-1.67,3.24,3.24,0,0,1-.89-.71.94.94,0,0,1-.12-1"
        style="fill: #eb996e"
      ></path>
      <path
        d="M235.35,201.05c-.45,0-.09,3-2.38,5.48s-5.53,2.51-5.5,2.93c0,.2.78.5,2.13.36a7.47,7.47,0,0,0,4.61-2.28A6.51,6.51,0,0,0,236,203C236,201.71,235.55,201,235.35,201.05Z"
        style="fill: #263238"
      ></path>
      <path
        d="M232.38,169.29c.36.71,3.08,0,6.36,0s6,.54,6.36-.19c.15-.34-.39-1-1.54-1.59a10.68,10.68,0,0,0-4.86-1,10.79,10.79,0,0,0-4.83,1.19C232.74,168.28,232.22,169,232.38,169.29Z"
        style="fill: #263238"
      ></path>
      <path
        d="M207.27,173.2c.56.57,2.38-.32,4.66-.63s4.28-.11,4.64-.82c.16-.35-.23-.94-1.15-1.42a6.83,6.83,0,0,0-7.49,1.17C207.2,172.25,207,172.92,207.27,173.2Z"
        style="fill: #263238"
      ></path>
      <path
        d="M208,149.2a9.78,9.78,0,0,0,15.07,10.18c-1.17,1.44-.66,3.75.73,5a7.18,7.18,0,0,0,5.22,1.44,13.61,13.61,0,0,0,9.93-5.41,9.91,9.91,0,0,0,14.91,5.9,9.08,9.08,0,1,0,16.05,6.77,10.13,10.13,0,0,0,2-16,6.09,6.09,0,0,0,3.28-5.13,12.38,12.38,0,0,0-1.42-6.14c-2.23-4.71-6.09-8.82-11-10.43s-11-.3-14.15,3.82a2.91,2.91,0,0,1-1.28,1.16,2.55,2.55,0,0,1-2.16-.82c-2.75-2.27-5.64-4.71-9.16-5.23a9.59,9.59,0,0,0-10.13,6.22c-2.89-3.8-8.33-5.76-12.72-3.89s-6.7,7.92-4,11.87"
        style="fill: #263238"
      ></path>
      <path
        d="M260.34,176.6a43,43,0,0,0,3.82,11.64c.77,1.55,1.9,3.24,3.63,3.41a3.71,3.71,0,0,0,3.56-2.6,9.81,9.81,0,0,0,.17-4.7,125.66,125.66,0,0,0-3.82-16.42"
        style="fill: #263238"
      ></path>
      <path
        d="M265.27,180.78c.23-.16,9.65-4.79,11.09,5.39s-9.08,9.53-9.14,9.24S265.27,180.78,265.27,180.78Z"
        style="fill: #ffbe9d"
      ></path>
      <path
        d="M269.79,191.12s.19.1.51.19a1.82,1.82,0,0,0,1.33-.16c1-.55,1.69-2.38,1.48-4.23a6,6,0,0,0-.86-2.47,2.07,2.07,0,0,0-1.53-1.17.9.9,0,0,0-1,.64c-.1.3,0,.49-.06.52s-.24-.14-.22-.57a1.14,1.14,0,0,1,.31-.7,1.41,1.41,0,0,1,1-.4,2.53,2.53,0,0,1,2.07,1.32,6.11,6.11,0,0,1,1,2.75c.22,2.06-.55,4.13-2,4.77a1.94,1.94,0,0,1-1.66,0C269.84,191.36,269.76,191.14,269.79,191.12Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M210.51,197.89c-2.44,0-5.47-.17-6.46-1-1.46-1.16-3.35-5.89-5-10.36-.21-.57-.35-1-.4-1.06l.87-.48a10.43,10.43,0,0,1,.47,1.2c.89,2.4,3.24,8.77,4.68,9.92,1.7,1.35,14.64.64,15.74-.37.68-.72,1.8-6.8,2.37-10.89l.06-.37,5.1-.74.16.32c1.29,2.57,5.16,9.48,7.55,10.57,3,1.36,13.87-.73,14.57-2,.61-1.07,1.8-8.15,2.45-12.64l.06-.4,15.67-1.15.07,1-14.87,1.1c-.33,2.23-1.68,11.13-2.51,12.59-1.12,2-12.84,3.78-15.86,2.41s-7-9-7.86-10.71l-3.65.53c-.33,2.31-1.51,9.93-2.59,11.07s-7.5,1.34-8.23,1.37S211.44,197.89,210.51,197.89Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M228.43,185.48l-1.19-1.61c6.34-4.69,18.19-7.71,26.26-4.41l-.75,1.85C245.55,178.37,234.36,181.09,228.43,185.48Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M199.38,186.46l-.95-1.76c7.85-4.23,18.52-4.39,25.37-.38l-1,1.73C216.6,182.42,206.54,182.6,199.38,186.46Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M273.15,186.18a3.62,3.62,0,0,0-3.67,0,2.33,2.33,0,0,0,1,4.15,4.45,4.45,0,0,1,1.37.27,1.59,1.59,0,0,1,.71,1.3,7.22,7.22,0,0,1-.15,1.53,8.66,8.66,0,0,0,.25,3.51,1.76,1.76,0,0,0,.5.89,1.33,1.33,0,0,0,.8.25,2.79,2.79,0,0,0,2.22-1.27,5.92,5.92,0,0,0,.89-2.49,14.58,14.58,0,0,0,.1-4.12,4.94,4.94,0,0,0-.78-2.38c-.79-1.1-3.82-2-5.17-2.14"
        style="fill: #263238"
      ></path>
      <path
        d="M239.21,158.3a1.17,1.17,0,0,1-.3.12c-.21.07-.52.16-.93.26a13.91,13.91,0,0,1-3.62.44,9.94,9.94,0,0,1-2.59-.36,7.25,7.25,0,0,1-4.91-4.19,5.46,5.46,0,0,1-.18-3.91l.36.24a13.64,13.64,0,0,1-7.84,3.48,13.24,13.24,0,0,1-4.75-.37,8.29,8.29,0,0,1-2.31-1,4.79,4.79,0,0,1-1.74-1.91,6.74,6.74,0,0,1-.61-2.93,19.18,19.18,0,0,1,.22-2.87,9.55,9.55,0,0,1,.74-2.72,3.21,3.21,0,0,1,2-1.87l.17.46a8.14,8.14,0,0,1-5.28.44,8.72,8.72,0,0,1-4.22-2.36,7.33,7.33,0,0,1-2-3.8,8.58,8.58,0,0,1,2.88-8.06,5.54,5.54,0,0,1,1.59-1c0,.05-.58.3-1.48,1.09a8.43,8.43,0,0,0-2.55,4.32,8.2,8.2,0,0,0-.08,3.54,7,7,0,0,0,1.93,3.58,8.41,8.41,0,0,0,4,2.2,7.69,7.69,0,0,0,5-.44l.17.46a2.74,2.74,0,0,0-1.71,1.62,9,9,0,0,0-.69,2.57c-.23,1.81-.46,3.8.35,5.5a5.44,5.44,0,0,0,3.71,2.62,12.93,12.93,0,0,0,4.57.36,13.17,13.17,0,0,0,7.58-3.31l.68-.62-.31.86a5.06,5.06,0,0,0,.14,3.6,6.92,6.92,0,0,0,2,2.69,7.24,7.24,0,0,0,2.62,1.36,10,10,0,0,0,2.52.4,16,16,0,0,0,3.58-.31C238.77,158.39,239.21,158.27,239.21,158.3Z"
        style="fill: #fff"
      ></path>
      <path
        d="M207.13,157.77a1.82,1.82,0,0,1-.65.41,5.56,5.56,0,0,1-2,.47,5.23,5.23,0,0,1-3-.75,2.6,2.6,0,0,1-1.14-1.57,1.91,1.91,0,0,1,.68-2l.26.45-.17.07a8.11,8.11,0,0,1-6.86-.28,6.9,6.9,0,0,1-1.72-1.23c-.35-.36-.51-.59-.49-.61a15.32,15.32,0,0,0,2.37,1.51,8.08,8.08,0,0,0,6.49.13l.16-.06,2.35-1-2.09,1.42a1.42,1.42,0,0,0-.49,1.46,2.15,2.15,0,0,0,.92,1.27,5.07,5.07,0,0,0,2.75.79A9.68,9.68,0,0,0,207.13,157.77Z"
        style="fill: #fff"
      ></path>
      <path
        d="M218.46,179.52c-.05.07-.66-.38-1.77-.85a9.71,9.71,0,0,0-8.95.61c-1,.62-1.58,1.15-1.64,1.09a4.39,4.39,0,0,1,1.44-1.4,9.24,9.24,0,0,1,4.57-1.61,9.14,9.14,0,0,1,4.74,1A4.19,4.19,0,0,1,218.46,179.52Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M245.52,176.08a15.68,15.68,0,0,1-1.45-.66,8,8,0,0,0-7.22.48,12.84,12.84,0,0,1-1.34.84s.32-.56,1.15-1.15a7.29,7.29,0,0,1,7.56-.5C245.12,175.57,245.56,176,245.52,176.08Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M237.92,201.79c-.07,0-.21-.16-.48-.38a3.06,3.06,0,0,0-1.23-.54c-1.06-.19-2-.08-2.05-.26a3,3,0,0,1,2.16-.25,2.34,2.34,0,0,1,1.38.8C238,201.49,238,201.76,237.92,201.79Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M240,203c-.14,0-.45-.7-1-1.53s-1-1.44-.9-1.55.81.38,1.35,1.28S240.18,203,240,203Z"
        style="fill: #eb996e"
      ></path>
      <path
        d="M218.82,229.27a2.12,2.12,0,0,1,.11.42c.07.31.16.73.28,1.26.24,1.14.59,2.76,1,4.85.93,4.2,2.22,10.3,4.54,17.64s5.89,15.9,12.26,23.83c1.59,2,3.32,3.93,5.11,5.9a17.28,17.28,0,0,0,2.85,2.76,5.09,5.09,0,0,0,3.73.86,9.26,9.26,0,0,0,3.69-1.64,26.64,26.64,0,0,0,3.19-2.75c2.09-1.95,4.07-4.06,6-6.23,7.77-8.75,13.59-18.58,16.35-28.36a55.15,55.15,0,0,0,2.18-14.19,75.81,75.81,0,0,0-.76-12.52,162.91,162.91,0,0,0-3.73-17.82c-.54-2.06-1-3.67-1.25-4.8-.13-.53-.24-.94-.32-1.25a3.77,3.77,0,0,1-.09-.43,2.69,2.69,0,0,1,.15.42c.09.3.21.71.36,1.24.32,1.11.77,2.72,1.34,4.77a152,152,0,0,1,3.91,17.82,76.11,76.11,0,0,1,.82,12.58,55.15,55.15,0,0,1-2.15,14.31c-2.76,9.87-8.61,19.78-16.42,28.57-2,2.19-4,4.31-6.07,6.27a27.12,27.12,0,0,1-3.26,2.79,9.8,9.8,0,0,1-3.9,1.73,5.56,5.56,0,0,1-4.09-1,17.57,17.57,0,0,1-2.94-2.83c-1.78-2-3.52-3.94-5.11-5.94a72.63,72.63,0,0,1-12.24-24,178.29,178.29,0,0,1-4.39-17.71c-.4-2.09-.72-3.73-.94-4.87-.1-.54-.17-1-.23-1.27A2.3,2.3,0,0,1,218.82,229.27Z"
        style="fill: #263238"
      ></path>
      <path
        d="M255.74,398.19s0-.09-.09-.28-.14-.5-.23-.85-.21-.85-.35-1.39a17.14,17.14,0,0,1-.27-1.94,35.71,35.71,0,0,1,1.07-12.38c1.16-5.14,3.39-11,5-17.74a41.5,41.5,0,0,0,1.29-10.72,32.53,32.53,0,0,0-2.43-11.28c-1.42-3.73-3.18-7.23-4.78-10.66s-3.18-6.82-3.38-10.38,1.2-6.74,2-9.69a15.92,15.92,0,0,0,.62-4.35,12.14,12.14,0,0,0-.82-4,49.42,49.42,0,0,0-3.19-6.28,23.08,23.08,0,0,1-2.17-4.91,11.55,11.55,0,0,1-.36-1.94,13.52,13.52,0,0,1,0-1.43l.15-.88a1.19,1.19,0,0,1,.06-.29,10.22,10.22,0,0,0,.34,4.48,24.27,24.27,0,0,0,2.23,4.82,48,48,0,0,1,3.29,6.3,12.72,12.72,0,0,1,.87,4.08A16.25,16.25,0,0,1,254,311c-.75,3-2.09,6.11-1.89,9.55s1.76,6.8,3.36,10.2,3.37,6.92,4.81,10.68a33.11,33.11,0,0,1,2.45,11.46,41.77,41.77,0,0,1-1.33,10.84c-1.68,6.77-3.95,12.62-5.15,17.72A36.86,36.86,0,0,0,255,393.71a18,18,0,0,0,.23,1.93c.11.54.21,1,.29,1.39s.13.64.18.86A1.73,1.73,0,0,1,255.74,398.19Z"
        style="fill: #263238"
      ></path>
      <polygon
        points="245.09 285.73 244.78 286.84 247.21 289.92 248.23 289.92 250.91 286.84 250.48 285.73 245.09 285.73"
        style="fill: #455a64"
      ></polygon>
    </g>
    <g id="freepik--Device--inject-17">
      <path
        d="M42.38,390.4l44.8,78.93,173.71.07a2.66,2.66,0,0,0,2.64-2.66h0a2.65,2.65,0,0,0-2.63-2.66l-57.71-1.61a7.23,7.23,0,0,1-6.3-3.85l-37.47-71.45a4.54,4.54,0,0,0-3.95-2.42L46.34,383.66A4.51,4.51,0,0,0,42.38,390.4Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M42.57,387.43s.18,0,.52,0h1.53l5.86,0,21.56.19,71.14.81,5.18.07,2.56,0a5.53,5.53,0,0,1,4.55,2.35c.49.75.81,1.49,1.22,2.23l2.3,4.39,8.77,16.73c5.58,10.66,10.6,20.27,14.82,28.34l10,19.13c1.15,2.25,2.06,4,2.68,5.21.29.59.52,1,.69,1.36s.21.48.21.48l-.26-.45c-.18-.32-.42-.77-.73-1.34-.65-1.2-1.58-2.94-2.77-5.17-2.4-4.51-5.85-11-10.1-19.05S173,425.1,167.34,414.45l-8.8-16.72-2.31-4.38c-.39-.72-.75-1.52-1.18-2.17a5,5,0,0,0-1.8-1.57,5,5,0,0,0-2.33-.56l-2.57,0-5.17-.06L72,388l-21.56-.35-5.87-.12-1.52-.05Z"
        style="fill: #263238"
      ></path>
      <path
        d="M115.15,424.46a4.26,4.26,0,1,1-4.24-3.89A4.07,4.07,0,0,1,115.15,424.46Z"
        style="fill: #fff"
      ></path>
    </g>
    <g id="freepik--Floor--inject-17">
      <path
        d="M478.23,469.28c0,.14-101.55.26-226.8.26s-226.82-.12-226.82-.26S126.14,469,251.43,469,478.23,469.13,478.23,469.28Z"
        style="fill: #263238"
      ></path>
    </g>
    <g id="freepik--speech-bubble--inject-17">
      <path
        d="M307.25,210.48l16.52-9.38A44.25,44.25,0,1,0,312,190.5h0Z"
        style="fill: #fff"
      ></path>
      <path
        d="M307.25,210.48l4.58-20,.05-.2.16.13h0l-.19.18a45.38,45.38,0,0,1-5.47-9.08,44.38,44.38,0,0,1-3.46-12.12,39.24,39.24,0,0,1-.35-7c.06-1.2.09-2.42.2-3.66s.37-2.47.56-3.73a44.58,44.58,0,0,1,17.73-27.61,43.88,43.88,0,0,1,17.07-7.47,45.06,45.06,0,0,1,19.65.52A43.58,43.58,0,0,1,376,129.9l2,1.81c.66.59,1.21,1.3,1.83,1.94s1.23,1.28,1.74,2l1.59,2.12a19.88,19.88,0,0,1,1.45,2.2l1.36,2.24,1.14,2.35a20.14,20.14,0,0,1,1.06,2.37,45.22,45.22,0,0,1,3,19.8,43.6,43.6,0,0,1-5.37,18.18,44.82,44.82,0,0,1-25.81,21,47.5,47.5,0,0,1-14.37,1.92,43.76,43.76,0,0,1-12.41-2.16,45,45,0,0,1-9.51-4.43h.13l-16.58,9.27L323.71,201l.06,0,.06,0a44.92,44.92,0,0,0,9.48,4.35,43.58,43.58,0,0,0,12.32,2.09,47.3,47.3,0,0,0,14.24-1.95,44.46,44.46,0,0,0,25.51-20.81,43.2,43.2,0,0,0,5.29-18,44.63,44.63,0,0,0-3-19.57,20.4,20.4,0,0,0-1-2.34l-1.12-2.32-1.35-2.22a18.05,18.05,0,0,0-1.43-2.17L381.13,136c-.51-.72-1.16-1.32-1.73-2s-1.15-1.33-1.81-1.92l-2-1.78a43.07,43.07,0,0,0-18-9.37,44.67,44.67,0,0,0-19.43-.53,43.61,43.61,0,0,0-16.88,7.37,44.23,44.23,0,0,0-17.61,27.3c-.19,1.24-.38,2.48-.57,3.69s-.13,2.44-.21,3.63a40.53,40.53,0,0,0,.33,6.94,44,44,0,0,0,8.78,21.09l.6.79-.78-.6,0,0,.2-.07Z"
        style="fill: #263238"
      ></path>
      <path
        d="M339.21,161.37H327.87l-2.24,5.25h-4.34l10.21-22.69h4.15l10.24,22.69h-4.41Zm-1.39-3.31-4.28-9.92-4.24,9.92Z"
        style="fill: #263238"
      ></path>
      <path
        d="M372.36,166.09H351.64a1.5,1.5,0,0,1,0-3h20.72a1.5,1.5,0,0,1,0,3Z"
        style="fill: #263238"
      ></path>
      <path
        d="M362,166.09a1.5,1.5,0,0,1-1.5-1.5V159a1.5,1.5,0,0,1,3,0v5.59A1.5,1.5,0,0,1,362,166.09Z"
        style="fill: #263238"
      ></path>
      <path
        d="M352.16,183.92a1.5,1.5,0,0,1-.58-2.88c.14-.06,13.88-5.93,15.07-16.62a1.5,1.5,0,1,1,3,.34c-1.39,12.44-16.26,18.78-16.89,19.05A1.46,1.46,0,0,1,352.16,183.92Z"
        style="fill: #263238"
      ></path>
      <path
        d="M368.58,183.92a1.52,1.52,0,0,1-.42-.06c-7.68-2.24-13.76-12.25-14-12.67a1.5,1.5,0,0,1,2.57-1.55c.06.1,5.73,9.43,12.28,11.34a1.51,1.51,0,0,1,1,1.86A1.49,1.49,0,0,1,368.58,183.92Z"
        style="fill: #263238"
      ></path>
    </g>
  </svg>
</template>

<script>
import colors from "vuetify/lib/util/colors";
export default {
  name: "LanguageSimpleDrawing",
  props: ["color"],
  data() {
    return {
      colors: colors,
    };
  },
  computed: {
    hexColor() {
      let result = [];
      const attrs = this.color.split(" ");
      for (let attr of attrs) {
        const part = attr.split("-");
        let tmp;
        if (part.length > 1) {
          tmp = part[0] + this.capitalizeFirstLetter(part[1]);
        } else {
          tmp = part[0];
        }
        result.push(tmp);
      }
      const hex = this.colors[result[0]][result[1]];
      return hex;
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>