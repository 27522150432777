<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <g id="freepik--background-simple--inject-10">
      <path
        d="M410.76,118l-2.06-3.4c-17.41-28.69-48.89-46-82.08-43.89a87.93,87.93,0,0,0-12.7,1.74c-30.17,6.46-56.43,29.28-67.31,58.51-6.4,17.18-8.19,37-20.34,50.61-15.8,17.76-42.64,18.31-66.23,17.58s-50.45-.11-66.18,17.71c-9.85,11.17-13,27-12.19,42,2.1,39.58,29.68,75,64.76,92.56s76.37,19.17,114.75,11.43C315,352,366.1,322.25,396.84,276.27S435.36,167.65,410.76,118Z"
        style="fill: #f5f5f5"
      ></path>
    </g>
    <g id="freepik--Plant--inject-10">
      <path
        d="M108.25,403.89c-.07.08-.61-.25-1.21-.73s-1-.93-1-1,.6.25,1.21.73S108.31,403.81,108.25,403.89Z"
        style="fill: #263238"
      ></path>
      <path
        d="M124.66,379.36a19,19,0,0,0-.56-6.82,6.72,6.72,0,0,0-4.65-4.64c-2.56-.53-5.19,1.09-6.63,3.28a18.84,18.84,0,0,0-2.35,7.42,263.23,263.23,0,0,0-3.22,53.93L117.74,434C122.35,415.18,123.82,398.72,124.66,379.36Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M118,367.82s0,.06,0,.17l-.18.47c-.17.44-.4,1.05-.7,1.81-.14.4-.34.83-.49,1.31l-.51,1.59c-.19.57-.38,1.18-.59,1.83l-.54,2.08a91.54,91.54,0,0,0-2,10.38c-.56,3.94-.94,8.31-1.23,12.9-.54,9.19-.62,17.54-.86,23.57-.11,3-.21,5.46-.33,7.14,0,.82-.1,1.47-.13,1.94l0,.51c0,.11,0,.17,0,.17s0-.06,0-.18,0-.3,0-.5c0-.48,0-1.13.06-1.94.07-1.69.13-4.13.21-7.15.17-6,.21-14.37.75-23.58.29-4.6.67-9,1.26-12.93a88.53,88.53,0,0,1,2.11-10.4l.57-2.08c.22-.64.43-1.25.62-1.82s.37-1.1.54-1.59.37-.91.53-1.3c.32-.75.58-1.35.76-1.79l.21-.46Z"
        style="fill: #263238"
      ></path>
      <path
        d="M119,367.82a7.57,7.57,0,0,1,1.28,2.17,16.06,16.06,0,0,1,1,6.79c-.27,5.8-.79,13.78-1.48,22.6s-1.47,16.8-2.08,22.56c-.31,2.88-.58,5.21-.78,6.82-.1.78-.18,1.4-.24,1.85l-.07.48c0,.11,0,.16,0,.16v-.16c0-.13,0-.29,0-.49,0-.45.09-1.07.17-1.86.15-1.64.38-4,.65-6.83.55-5.77,1.28-13.74,2-22.56s1.25-16.8,1.6-22.58a16.37,16.37,0,0,0-.83-6.72A14.3,14.3,0,0,0,119,367.82Z"
        style="fill: #263238"
      ></path>
      <path
        d="M126.24,395a11,11,0,0,1-3.85-1.38,6.17,6.17,0,0,1,2,.52A6.07,6.07,0,0,1,126.24,395Z"
        style="fill: #263238"
      ></path>
      <path
        d="M127.5,384.28a6.06,6.06,0,0,1-1.89,1.05,6.15,6.15,0,0,1-2,.71,6.39,6.39,0,0,1,1.89-1.05A6.66,6.66,0,0,1,127.5,384.28Z"
        style="fill: #263238"
      ></path>
      <path
        d="M126.85,375.56a3.46,3.46,0,0,1-1.57.08c-.85-.06-1.54-.19-1.53-.3a3.38,3.38,0,0,1,1.56-.08A3.29,3.29,0,0,1,126.85,375.56Z"
        style="fill: #263238"
      ></path>
      <path
        d="M116,370c-.09,0-.54-.52-1-1.28s-.8-1.42-.71-1.48.54.52,1,1.28S116.11,369.91,116,370Z"
        style="fill: #263238"
      ></path>
      <path
        d="M112.45,375.24c0,.11-.67.18-1.49.16a3.21,3.21,0,0,1-1.49-.22,5.94,5.94,0,0,1,3,.06Z"
        style="fill: #263238"
      ></path>
      <path
        d="M109.88,385.8a3.64,3.64,0,0,1-1.57-.43c-.83-.33-1.47-.68-1.43-.78a3.82,3.82,0,0,1,1.57.43C109.28,385.35,109.92,385.7,109.88,385.8Z"
        style="fill: #263238"
      ></path>
      <path
        d="M109.6,394.68c0,.1-.57.31-1.33.47s-1.39.2-1.41.1.57-.31,1.33-.47S109.58,394.58,109.6,394.68Z"
        style="fill: #263238"
      ></path>
      <path
        d="M108.39,409.2c0,.1-.61.3-1.41.44a3.06,3.06,0,0,1-1.49.08,3.13,3.13,0,0,1,1.42-.44A3,3,0,0,1,108.39,409.2Z"
        style="fill: #263238"
      ></path>
      <path
        d="M107.59,417.22c0,.09-.48.46-1.18.83s-1.31.6-1.36.51.48-.47,1.18-.84S107.54,417.12,107.59,417.22Z"
        style="fill: #263238"
      ></path>
      <path
        d="M114.14,426.32c-.09.05-.55-.59-1-1.43a4.23,4.23,0,0,1-.71-1.6c.09-.06.55.58,1,1.42A4.17,4.17,0,0,1,114.14,426.32Z"
        style="fill: #263238"
      ></path>
      <path
        d="M116,411.19c.07.08-.44.7-1.15,1.39s-1.34,1.19-1.41,1.12.44-.69,1.15-1.39S116,411.12,116,411.19Z"
        style="fill: #263238"
      ></path>
      <path
        d="M115.61,403.26c-.08.07-.55-.32-1.07-.87s-.86-1.06-.78-1.13.55.32,1.06.87S115.68,403.19,115.61,403.26Z"
        style="fill: #263238"
      ></path>
      <path
        d="M116.91,393.12c.07.07-.35.68-1,1.35s-1.16,1.17-1.23,1.1.35-.67,1-1.35S116.83,393.05,116.91,393.12Z"
        style="fill: #263238"
      ></path>
      <path
        d="M117.64,385.77c-.07.07-.67-.4-1.34-1s-1.14-1.24-1.07-1.32.67.4,1.34,1.05S117.71,385.69,117.64,385.77Z"
        style="fill: #263238"
      ></path>
      <path
        d="M119.07,375.82c.09.06-.21.72-.67,1.49s-.9,1.35-1,1.3.21-.72.67-1.49S119,375.77,119.07,375.82Z"
        style="fill: #263238"
      ></path>
      <path
        d="M121.78,366.61a3.64,3.64,0,0,1-.6,1.49c-.41.77-.83,1.36-.92,1.31a3.65,3.65,0,0,1,.59-1.49C121.27,367.14,121.68,366.56,121.78,366.61Z"
        style="fill: #263238"
      ></path>
      <path
        d="M124.84,370.79c0,.1-.37.39-.94.66s-1.05.41-1.1.32.38-.39.94-.66S124.8,370.7,124.84,370.79Z"
        style="fill: #263238"
      ></path>
      <path
        d="M124.91,403.89a4.14,4.14,0,0,1-2-.5,4.4,4.4,0,0,1-1.78-1.13,9.77,9.77,0,0,1,1.93.79A9.59,9.59,0,0,1,124.91,403.89Z"
        style="fill: #263238"
      ></path>
      <path
        d="M123.66,410.9c0,.1-.61.31-1.41.46a3,3,0,0,1-1.48.1c0-.1.61-.31,1.41-.47A3.09,3.09,0,0,1,123.66,410.9Z"
        style="fill: #263238"
      ></path>
      <path
        d="M122.12,422.92a5.89,5.89,0,0,1-2.6-3.28,11.61,11.61,0,0,1,1.3,1.64A12,12,0,0,1,122.12,422.92Z"
        style="fill: #263238"
      ></path>
      <path
        d="M105.26,428.42c-.07-.08.36-.56.95-1.06s1.13-.86,1.2-.78-.36.55-1,1.06S105.33,428.5,105.26,428.42Z"
        style="fill: #263238"
      ></path>
      <path
        d="M100.41,402.18c-.68-2.13-2-4.61-4.2-4.65a3.73,3.73,0,0,0-3.15,2.11,7.8,7.8,0,0,0-.85,3.86A100.91,100.91,0,0,0,97,431.44h8.19A97,97,0,0,0,100.41,402.18Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M85.6,388.76a9.1,9.1,0,0,0-8.53-7.28c-3.62.64-5,4.2-5.45,7.85s.7,7.28,1.82,10.78q5.4,16.86,10.78,33.72l10.19-1.11C91.19,417.66,89.34,403.7,85.6,388.76Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M96.21,397.53a10.5,10.5,0,0,0-.69,1.16,7.61,7.61,0,0,0-.39,3.62c.16,1.56.34,3.44.6,5.49s.64,4.31,1.1,6.66c.93,4.71,2,8.93,2.63,12,.34,1.54.59,2.79.75,3.65.07.4.12.73.17,1a1.33,1.33,0,0,1,0,.35s0-.11-.11-.33-.15-.6-.24-1c-.22-.91-.51-2.14-.87-3.62-.73-3.06-1.81-7.27-2.74-12-.46-2.35-.82-4.62-1.07-6.69-.13-1-.22-2-.3-2.94s-.18-1.78-.22-2.57a7.45,7.45,0,0,1,.51-3.7A3.35,3.35,0,0,1,96.21,397.53Z"
        style="fill: #263238"
      ></path>
      <path
        d="M77.22,381.19A15.07,15.07,0,0,0,76,382.84a11.57,11.57,0,0,0-1.55,5.34,24.48,24.48,0,0,0,1.26,8.2c.9,3,1.88,6.36,2.91,9.85l5.21,17.93c.64,2.27,1.17,4.12,1.54,5.44.17.62.3,1.11.4,1.49a3.41,3.41,0,0,1,.12.52,2.64,2.64,0,0,1-.19-.5c-.12-.37-.27-.86-.47-1.47l-1.65-5.41-5.32-17.89c-1-3.5-2-6.83-2.88-9.87a24,24,0,0,1-1.19-8.31,11.35,11.35,0,0,1,1.69-5.39A6.49,6.49,0,0,1,77.22,381.19Z"
        style="fill: #263238"
      ></path>
      <path
        d="M92.19,431.63a2.73,2.73,0,0,1-.23-.49c-.13-.32-.35-.8-.58-1.43-.49-1.25-1.11-3.1-1.75-5.41s-1.29-5.1-1.9-8.22L85.81,406c-.71-3.59-1.5-7-2.41-10a56.48,56.48,0,0,0-3-7.85,40.91,40.91,0,0,0-2.75-4.95c-.35-.57-.67-1-.86-1.28a3.07,3.07,0,0,1-.28-.46,2.3,2.3,0,0,1,.34.41c.21.28.55.68.92,1.25A36.55,36.55,0,0,1,80.67,388a55,55,0,0,1,3.06,7.87,100.34,100.34,0,0,1,2.45,10L88.07,416c.59,3.11,1.2,5.9,1.81,8.21s1.2,4.17,1.64,5.43c.21.64.4,1.12.51,1.46A2.85,2.85,0,0,1,92.19,431.63Z"
        style="fill: #263238"
      ></path>
      <path
        d="M73.62,398.43c0,.1-.53.32-1.23.49s-1.31.23-1.33.13.53-.32,1.24-.49S73.6,398.33,73.62,398.43Z"
        style="fill: #263238"
      ></path>
      <path
        d="M72.55,391.48a5.4,5.4,0,0,1-1.72.18,4.76,4.76,0,0,1-1.73-.05,6,6,0,0,1,3.45-.13Z"
        style="fill: #263238"
      ></path>
      <path
        d="M73.69,385.13c-.06.07-.62-.28-1.23-.81s-1.06-1-1-1.09.62.28,1.24.81S73.76,385.05,73.69,385.13Z"
        style="fill: #263238"
      ></path>
      <path
        d="M76.8,379.25c.1,0,.08.51.06,1.11s0,1.1-.12,1.12-.27-.49-.25-1.13S76.7,379.22,76.8,379.25Z"
        style="fill: #263238"
      ></path>
      <path
        d="M83.15,382c.09.06-.16.56-.55,1.12s-.78,1-.87.9.17-.56.56-1.12S83.07,382,83.15,382Z"
        style="fill: #263238"
      ></path>
      <path
        d="M87.27,389.18c0,.09-.48.4-1.17.68s-1.28.43-1.32.34.49-.41,1.18-.69S87.24,389.08,87.27,389.18Z"
        style="fill: #263238"
      ></path>
      <path
        d="M89.78,396.54a4.68,4.68,0,0,1-3.38.77,6.81,6.81,0,0,1,1.68-.43A7.47,7.47,0,0,1,89.78,396.54Z"
        style="fill: #263238"
      ></path>
      <path
        d="M78.29,394.45c-.09,0,0-.54.16-1.13s.42-1.06.52-1,0,.54-.16,1.14S78.39,394.48,78.29,394.45Z"
        style="fill: #263238"
      ></path>
      <path
        d="M79.9,403.7c-.1,0,.11-.73.46-1.53s.71-1.42.8-1.38-.11.72-.46,1.53S80,403.74,79.9,403.7Z"
        style="fill: #263238"
      ></path>
      <path
        d="M75.94,406.23c0,.1-.69-.08-1.47-.32s-1.41-.47-1.39-.57a2.74,2.74,0,0,1,1.5.21C75.38,405.8,76,406.14,75.94,406.23Z"
        style="fill: #263238"
      ></path>
      <path
        d="M78.22,413.78c.06.08-.38.49-1,.92s-1.14.71-1.2.62.38-.5,1-.92S78.16,413.69,78.22,413.78Z"
        style="fill: #263238"
      ></path>
      <path
        d="M80.05,421.29c0,.11-.52.32-1.21.49s-1.27.22-1.29.12.52-.32,1.21-.48S80,421.19,80.05,421.29Z"
        style="fill: #263238"
      ></path>
      <path
        d="M90,402.83c0,.08-.41.45-1.08.7s-1.27.29-1.28.19.5-.29,1.14-.54S89.92,402.73,90,402.83Z"
        style="fill: #263238"
      ></path>
      <path
        d="M91.4,414.52c-.05.1-.6-.07-1.23-.37s-1.12-.61-1.07-.71.59.08,1.23.37S91.44,414.43,91.4,414.52Z"
        style="fill: #263238"
      ></path>
      <path
        d="M93.9,422.89a3.7,3.7,0,0,1-1.62-.43,4.07,4.07,0,0,1-1.49-.78,3.67,3.67,0,0,1,1.62.43A4.07,4.07,0,0,1,93.9,422.89Z"
        style="fill: #263238"
      ></path>
      <path
        d="M80.45,429.45c0-.09.62-.54,1.39-1.15s1.37-1.15,1.45-1.08-.41.75-1.22,1.37A3.26,3.26,0,0,1,80.45,429.45Z"
        style="fill: #263238"
      ></path>
      <path
        d="M87.07,425.2c-.11,0-.25-.55-.51-1.21s-.52-1.17-.43-1.23.51.39.78,1.1S87.17,425.19,87.07,425.2Z"
        style="fill: #263238"
      ></path>
      <path
        d="M85.32,415.13c-.06.08-.49-.12-1-.46s-.8-.67-.74-.76.49.12,1,.46S85.38,415,85.32,415.13Z"
        style="fill: #263238"
      ></path>
      <path
        d="M100.74,399.35c.07.08-.35.5-.93.92s-1.09.7-1.15.62.36-.5.93-.92S100.68,399.27,100.74,399.35Z"
        style="fill: #263238"
      ></path>
      <path
        d="M103.07,405.71c0,.1-.64.24-1.45.15s-1.41-.36-1.38-.46.65,0,1.42.08S103.06,405.6,103.07,405.71Z"
        style="fill: #263238"
      ></path>
      <path
        d="M93.07,407.13c0,.1-.58.32-1.36.37s-1.4-.08-1.4-.18.63-.14,1.38-.19S93.05,407,93.07,407.13Z"
        style="fill: #263238"
      ></path>
      <path
        d="M93.93,401.12c0,.1-.61.12-1.28-.19s-1.06-.78-1-.85.54.23,1.14.51S94,401,93.93,401.12Z"
        style="fill: #263238"
      ></path>
      <path
        d="M103.93,412.53c-.06.09-.68-.2-1.4-.65s-1.25-.89-1.19-1,.68.21,1.39.66S104,412.45,103.93,412.53Z"
        style="fill: #263238"
      ></path>
      <rect
        x="74.99"
        y="430.37"
        width="53.33"
        height="9.45"
        v-bind:style="`fill: ${hexColor}`"
      ></rect>
      <polygon
        points="78.97 437.95 85.46 466.19 117.24 466.19 124.33 437.95 78.97 437.95"
        v-bind:style="`fill: ${hexColor}`"
      ></polygon>
      <path
        d="M128.29,439.83c0,.11-12,.19-26.87.19s-26.86-.08-26.86-.19,12-.18,26.86-.18S128.29,439.73,128.29,439.83Z"
        style="fill: #263238"
      ></path>
      <path
        d="M98.63,407.34c-.09.06-.45-.37-.81-.95s-.59-1.09-.5-1.15.45.37.81.95S98.72,407.29,98.63,407.34Z"
        style="fill: #263238"
      ></path>
      <path
        d="M98.65,414.94c-.1,0,0-.67.28-1.43s.54-1.35.63-1.32,0,.68-.28,1.44S98.75,415,98.65,414.94Z"
        style="fill: #263238"
      ></path>
      <path
        d="M101.25,422.93a4.09,4.09,0,0,1-.89-1.43,3.89,3.89,0,0,1-.55-1.58c.09-.05.49.59.89,1.42A3.69,3.69,0,0,1,101.25,422.93Z"
        style="fill: #263238"
      ></path>
      <path
        d="M95.62,429.35c-.08-.07.24-.56.71-1.1s.92-.93,1-.86-.23.56-.71,1.1S95.7,429.42,95.62,429.35Z"
        style="fill: #263238"
      ></path>
      <path
        d="M94.44,417.69c0,.1-.53.1-1.15,0s-1.1-.28-1.08-.38.53-.1,1.15,0S94.46,417.59,94.44,417.69Z"
        style="fill: #263238"
      ></path>
    </g>
    <g id="freepik--Window--inject-10">
      <rect
        x="70.82"
        y="42.7"
        width="288.05"
        height="252.43"
        style="fill: #fff"
      ></rect>
      <path
        d="M358.87,295.12c0-1.85-.09-100.49-.22-252.42l.22.22-288,0h0l.26-.26c0,91.72,0,178.09,0,252.42l-.23-.23,288.05.23-288,.23h-.23v-.23c0-74.33,0-160.7,0-252.42v-.26h.29l288,0h.22v.22C359,194.63,358.87,293.27,358.87,295.12Z"
        style="fill: #263238"
      ></path>
      <path
        d="M359.71,65c0,.13-64.68.23-144.44.23S70.82,65.16,70.82,65s64.66-.22,144.45-.22S359.71,64.91,359.71,65Z"
        style="fill: #263238"
      ></path>
      <path
        d="M90.42,53.17a3,3,0,1,1-3-3A3,3,0,0,1,90.42,53.17Z"
        style="fill: #263238"
      ></path>
      <path
        d="M100.13,53.17a3,3,0,1,1-3-3A3,3,0,0,1,100.13,53.17Z"
        style="fill: #263238"
      ></path>
      <path
        d="M109.84,53.17a3,3,0,1,1-3-3A3,3,0,0,1,109.84,53.17Z"
        style="fill: #263238"
      ></path>
      <path
        d="M150.5,272.31c0,.12-10.55.22-23.56.22s-23.57-.1-23.57-.22,10.55-.23,23.57-.23S150.5,272.18,150.5,272.31Z"
        style="fill: #263238"
      ></path>
      <path
        d="M149.9,262H105.65a5.54,5.54,0,0,1-5.54-5.54V195.18a5.54,5.54,0,0,1,5.54-5.53h28.06L155.44,212v44.39A5.54,5.54,0,0,1,149.9,262Z"
        style="fill: #455a64"
      ></path>
      <g style="opacity: 0.2">
        <path
          d="M100.11,240h55.33a0,0,0,0,1,0,0v15.81A6.14,6.14,0,0,1,149.3,262H106.25a6.14,6.14,0,0,1-6.14-6.14V240A0,0,0,0,1,100.11,240Z"
        ></path>
      </g>
      <path
        d="M121,255.52v-5.4l-2.65,4.45h-.93l-2.64-4.33v5.28h-2v-9h1.72l3.37,5.59,3.31-5.59h1.71l0,9Z"
        style="fill: #fff"
      ></path>
      <path
        d="M124.51,251a4.93,4.93,0,1,1,4.92,4.65A4.65,4.65,0,0,1,124.51,251Zm7.73,0a2.82,2.82,0,1,0-2.81,2.88A2.74,2.74,0,0,0,132.24,251Z"
        style="fill: #fff"
      ></path>
      <path
        d="M144.26,246.52l-3.89,9h-2.06l-3.88-9h2.25l2.74,6.43,2.77-6.43Z"
        style="fill: #fff"
      ></path>
      <path
        d="M123.87,218.29V214a1.33,1.33,0,0,1,2-1.15l3.7,2.13,3.69,2.13a1.34,1.34,0,0,1,0,2.32l-3.69,2.13-3.7,2.14a1.34,1.34,0,0,1-2-1.16Z"
        style="fill: #fafafa"
      ></path>
      <path
        d="M142.3,218.29a26.68,26.68,0,0,0-.56-3.52,14.46,14.46,0,0,0-5.29-7.88,14.31,14.31,0,0,0-13.54-2,14.23,14.23,0,0,0-6.63,5,14.21,14.21,0,0,0,0,16.86,13.41,13.41,0,0,0,3,3,13.62,13.62,0,0,0,3.61,2,14.4,14.4,0,0,0,18.83-9.9,26.41,26.41,0,0,0,.56-3.52.87.87,0,0,1,0,.24c0,.18,0,.41,0,.69a6,6,0,0,1-.09,1.12,10,10,0,0,1-.27,1.52,13.78,13.78,0,0,1-1.57,3.92,14.42,14.42,0,0,1-3.7,4.2,14.63,14.63,0,0,1-13.93,2.18,14.1,14.1,0,0,1-3.74-2,13.87,13.87,0,0,1-3.13-3.13,14.69,14.69,0,0,1,0-17.47,13.92,13.92,0,0,1,3.13-3.14,14.07,14.07,0,0,1,3.74-2,14.6,14.6,0,0,1,13.93,2.17,14.55,14.55,0,0,1,3.7,4.2,13.78,13.78,0,0,1,1.57,3.92,10,10,0,0,1,.27,1.52,6.1,6.1,0,0,1,.09,1.12c0,.28,0,.51,0,.7A.82.82,0,0,1,142.3,218.29Z"
        style="fill: #fafafa"
      ></path>
      <path
        d="M133.71,189.65v17.24a5.16,5.16,0,0,0,5.15,5.15h16.58Z"
        style="fill: #455a64"
      ></path>
      <g style="opacity: 0.2">
        <path
          d="M133.94,208.4a5.72,5.72,0,0,0,4.54,5.55l.54.13c2.17.38,16.42,2.92,16.42,2.59V212H138.86S134.72,211.9,133.94,208.4Z"
        ></path>
      </g>
      <path
        d="M155.44,212a11,11,0,0,1-1.65.1l-4.49.08-6.66.06H138.7a5.36,5.36,0,0,1-3.9-1.83,5.49,5.49,0,0,1-1.34-4.07c0-1.37,0-2.68,0-3.94,0-2.48,0-4.73.06-6.66s.06-3.34.08-4.49a10.71,10.71,0,0,1,.1-1.64,9.29,9.29,0,0,1,.1,1.64c0,1.15.05,2.67.09,4.49s0,4.18,0,6.66c0,1.26,0,2.57,0,3.94a5,5,0,0,0,1.22,3.73,4.9,4.9,0,0,0,3.53,1.66l3.92,0,6.66,0,4.49.09A9.48,9.48,0,0,1,155.44,212Z"
        style="fill: #263238"
      ></path>
      <path
        d="M241.3,262H197.05a5.54,5.54,0,0,1-5.54-5.54V195.18a5.54,5.54,0,0,1,5.54-5.53h28.06L246.83,212v44.39A5.54,5.54,0,0,1,241.3,262Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.2">
        <path
          d="M191.51,240h55.33a0,0,0,0,1,0,0v15.81A6.14,6.14,0,0,1,240.7,262H197.64a6.14,6.14,0,0,1-6.14-6.14V240A0,0,0,0,1,191.51,240Z"
        ></path>
      </g>
      <path
        d="M213,255.52v-5.4l-2.65,4.45h-.93l-2.64-4.33v5.28h-1.95v-9h1.72l3.37,5.59,3.31-5.59h1.71l0,9Z"
        style="fill: #fff"
      ></path>
      <path
        d="M220.32,252l-1.21,1.26v2.28H217v-9h2.07v4.2l4-4.2h2.31l-3.73,4,3.95,5H223.2Z"
        style="fill: #fff"
      ></path>
      <path
        d="M234.68,246.52l-3.89,9h-2.06l-3.88-9h2.25l2.74,6.43,2.77-6.43Z"
        style="fill: #fff"
      ></path>
      <path
        d="M215.26,218.29V214a1.34,1.34,0,0,1,2-1.15L221,215l3.7,2.13a1.34,1.34,0,0,1,0,2.32l-3.7,2.13-3.69,2.14a1.34,1.34,0,0,1-2-1.16Z"
        style="fill: #fafafa"
      ></path>
      <path
        d="M233.69,218.29a23.53,23.53,0,0,0-.55-3.52,14.52,14.52,0,0,0-5.29-7.88,14.31,14.31,0,0,0-13.54-2,14.23,14.23,0,0,0-6.63,5,14.21,14.21,0,0,0,0,16.86,13.57,13.57,0,0,0,3,3,13.83,13.83,0,0,0,3.62,2,14.35,14.35,0,0,0,13.54-2,14.52,14.52,0,0,0,5.29-7.88,23.33,23.33,0,0,0,.55-3.52.64.64,0,0,1,0,.24c0,.18,0,.41,0,.69a7,7,0,0,1-.09,1.12,10,10,0,0,1-.27,1.52,13.78,13.78,0,0,1-1.57,3.92,14.42,14.42,0,0,1-3.7,4.2,14.63,14.63,0,0,1-13.93,2.18,14.78,14.78,0,0,1-6.87-5.13,14.69,14.69,0,0,1,0-17.47,13.72,13.72,0,0,1,3.13-3.14,13.91,13.91,0,0,1,3.74-2,14.6,14.6,0,0,1,13.93,2.17,14.55,14.55,0,0,1,3.7,4.2,13.78,13.78,0,0,1,1.57,3.92,10,10,0,0,1,.27,1.52,7.13,7.13,0,0,1,.09,1.12c0,.28,0,.51,0,.7A.6.6,0,0,1,233.69,218.29Z"
        style="fill: #fafafa"
      ></path>
      <path
        d="M225.11,189.65v17.24a5.16,5.16,0,0,0,5.15,5.15h16.57Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.2">
        <path
          d="M225.34,208.4a5.72,5.72,0,0,0,4.54,5.55l.54.13c2.17.38,16.41,2.92,16.41,2.59V212H230.26S226.12,211.9,225.34,208.4Z"
        ></path>
      </g>
      <path
        d="M246.83,212a10.71,10.71,0,0,1-1.64.1l-4.49.08-6.66.06H230.1a5.36,5.36,0,0,1-3.9-1.83,5.54,5.54,0,0,1-1.35-4.07c0-1.37,0-2.68,0-3.94,0-2.48,0-4.73.06-6.66s.06-3.34.08-4.49a9.29,9.29,0,0,1,.1-1.64,9.29,9.29,0,0,1,.1,1.64c0,1.15,0,2.67.08,4.49s0,4.18.06,6.66c0,1.26,0,2.57,0,3.94a5.08,5.08,0,0,0,1.23,3.73,4.89,4.89,0,0,0,3.52,1.66l3.93,0,6.66,0,4.49.09A9.29,9.29,0,0,1,246.83,212Z"
        style="fill: #263238"
      ></path>
      <path
        d="M150.5,166.53c0,.13-10.55.23-23.56.23s-23.57-.1-23.57-.23,10.55-.22,23.57-.22S150.5,166.41,150.5,166.53Z"
        style="fill: #263238"
      ></path>
      <path
        d="M240.06,166.53c0,.13-10.55.23-23.57.23s-23.56-.1-23.56-.23,10.55-.22,23.56-.22S240.06,166.41,240.06,166.53Z"
        style="fill: #263238"
      ></path>
      <path
        d="M149.9,156.2H105.65a5.54,5.54,0,0,1-5.54-5.54V89.41a5.54,5.54,0,0,1,5.54-5.54h28.06l21.73,22.39v44.4A5.54,5.54,0,0,1,149.9,156.2Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.2">
        <path
          d="M100.11,134.25h55.33a0,0,0,0,1,0,0v15.81a6.14,6.14,0,0,1-6.14,6.14H106.25a6.14,6.14,0,0,1-6.14-6.14V134.25A0,0,0,0,1,100.11,134.25Z"
        ></path>
      </g>
      <path
        d="M123,147.82h-4.18l-.8,1.92h-2.13l4-9H122l4,9h-2.19Zm-.66-1.58L121,142.79l-1.43,3.45Z"
        style="fill: #fff"
      ></path>
      <path
        d="M135.11,140.75l-3.89,9h-2.06l-3.88-9h2.25l2.74,6.42,2.77-6.42Z"
        style="fill: #fff"
      ></path>
      <path d="M135.85,140.75h2.08v9h-2.08Z" style="fill: #fff"></path>
      <path
        d="M123.87,112.52v-4.27a1.34,1.34,0,0,1,2-1.16l3.7,2.13,3.69,2.14a1.34,1.34,0,0,1,0,2.32l-3.69,2.13-3.7,2.13a1.33,1.33,0,0,1-2-1.16Z"
        style="fill: #fafafa"
      ></path>
      <path
        d="M142.3,112.52a26.15,26.15,0,0,0-.56-3.52,14.36,14.36,0,0,0-18.83-9.9,14.41,14.41,0,0,0-6.63,5,14.23,14.23,0,0,0,0,16.87,14.41,14.41,0,0,0,6.63,5,14.33,14.33,0,0,0,7.5.64,14.14,14.14,0,0,0,6-2.66,14.41,14.41,0,0,0,5.29-7.87,26.68,26.68,0,0,0,.56-3.52.82.82,0,0,1,0,.23c0,.19,0,.42,0,.7a6.1,6.1,0,0,1-.09,1.12,10.22,10.22,0,0,1-.27,1.52,14,14,0,0,1-1.57,3.92,14.68,14.68,0,0,1-3.7,4.2,14.51,14.51,0,0,1-6.18,2.78,14.74,14.74,0,0,1-7.75-.61,13.82,13.82,0,0,1-3.74-2,13.67,13.67,0,0,1-3.13-3.13,14.69,14.69,0,0,1,0-17.47,14.08,14.08,0,0,1,3.13-3.13,13.82,13.82,0,0,1,3.74-2,14.74,14.74,0,0,1,7.75-.61,14.69,14.69,0,0,1,9.88,7,13.78,13.78,0,0,1,1.57,3.92,10.11,10.11,0,0,1,.27,1.51,6.13,6.13,0,0,1,.09,1.13c0,.28,0,.5,0,.69A.87.87,0,0,1,142.3,112.52Z"
        style="fill: #fafafa"
      ></path>
      <path
        d="M133.71,83.87v17.24a5.16,5.16,0,0,0,5.15,5.15h16.58Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.2">
        <path
          d="M133.94,102.62a5.73,5.73,0,0,0,4.54,5.56,5.23,5.23,0,0,0,.54.12c2.17.39,16.42,2.92,16.42,2.6v-4.64H138.86S134.72,106.12,133.94,102.62Z"
        ></path>
      </g>
      <path
        d="M155.44,106.26a9.48,9.48,0,0,1-1.65.1l-4.49.09-6.66,0H138.7a5.39,5.39,0,0,1-3.9-1.83,5.52,5.52,0,0,1-1.34-4.08c0-1.36,0-2.68,0-3.93,0-2.49,0-4.74.06-6.66s.06-3.34.08-4.49a11,11,0,0,1,.1-1.65,9.48,9.48,0,0,1,.1,1.65c0,1.15.05,2.66.09,4.49s0,4.17,0,6.66c0,1.25,0,2.57,0,3.93a5.05,5.05,0,0,0,1.22,3.74,4.94,4.94,0,0,0,3.53,1.66l3.92,0,6.66.06,4.49.08A11,11,0,0,1,155.44,106.26Z"
        style="fill: #263238"
      ></path>
      <path
        d="M241.15,272.3c0,.12-10.56.22-23.57.22s-23.57-.1-23.57-.22,10.55-.23,23.57-.23S241.15,272.17,241.15,272.3Z"
        style="fill: #263238"
      ></path>
      <path
        d="M331.57,260.06H287.32a5.54,5.54,0,0,1-5.54-5.54V193.27a5.53,5.53,0,0,1,5.54-5.53h28.07l21.72,22.39v44.39A5.54,5.54,0,0,1,331.57,260.06Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.2">
        <path
          d="M281.78,238.12h55.33a0,0,0,0,1,0,0v15.81a6.14,6.14,0,0,1-6.14,6.14H287.92a6.14,6.14,0,0,1-6.14-6.14V238.12A0,0,0,0,1,281.78,238.12Z"
        ></path>
      </g>
      <path
        d="M302.67,253.61l0-5.4L300,252.66h-.94l-2.63-4.33v5.28h-2v-9h1.73l3.36,5.59,3.32-5.59h1.71l0,9Z"
        style="fill: #fff"
      ></path>
      <path
        d="M306.18,249.11a4.93,4.93,0,1,1,4.92,4.65A4.65,4.65,0,0,1,306.18,249.11Zm7.74,0A2.82,2.82,0,1,0,311.1,252,2.75,2.75,0,0,0,313.92,249.11Z"
        style="fill: #fff"
      ></path>
      <path
        d="M325.94,244.61l-3.9,9H320l-3.88-9h2.25l2.73,6.43,2.78-6.43Z"
        style="fill: #fff"
      ></path>
      <path
        d="M305.54,216.38v-4.27a1.34,1.34,0,0,1,2-1.16l3.69,2.14,3.7,2.13a1.34,1.34,0,0,1,0,2.32l-3.7,2.13-3.69,2.14a1.34,1.34,0,0,1-2-1.16Z"
        style="fill: #fafafa"
      ></path>
      <path
        d="M324,216.38a25.11,25.11,0,0,0-.55-3.52,14.49,14.49,0,0,0-5.3-7.88,14.21,14.21,0,0,0-6-2.65,14.37,14.37,0,0,0-7.51.64,14.14,14.14,0,0,0-6.62,5,14.21,14.21,0,0,0,0,16.86,14.14,14.14,0,0,0,6.62,5,14.37,14.37,0,0,0,7.51.64,14.21,14.21,0,0,0,6-2.65,14.49,14.49,0,0,0,5.3-7.88,25.34,25.34,0,0,0,.55-3.52,1.1,1.1,0,0,1,0,.24c0,.18,0,.41,0,.69a7.13,7.13,0,0,1-.09,1.12,10.13,10.13,0,0,1-.28,1.52,13.22,13.22,0,0,1-1.57,3.92,14.51,14.51,0,0,1-3.69,4.2,14.63,14.63,0,0,1-13.93,2.18,14,14,0,0,1-3.74-2,13.72,13.72,0,0,1-3.13-3.14,14.65,14.65,0,0,1,0-17.47,14.78,14.78,0,0,1,6.87-5.13,14.63,14.63,0,0,1,13.93,2.18,14.53,14.53,0,0,1,3.69,4.2,13.22,13.22,0,0,1,1.57,3.92,10.13,10.13,0,0,1,.28,1.52,7.13,7.13,0,0,1,.09,1.12c0,.28,0,.51,0,.69A1.1,1.1,0,0,1,324,216.38Z"
        style="fill: #fafafa"
      ></path>
      <path
        d="M315.39,187.74V205a5.14,5.14,0,0,0,5.15,5.15h16.57Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.2">
        <path
          d="M315.61,206.49a5.72,5.72,0,0,0,4.54,5.55,5.41,5.41,0,0,0,.55.12c2.16.39,16.41,2.93,16.41,2.6v-4.63H320.54S316.4,210,315.61,206.49Z"
        ></path>
      </g>
      <path
        d="M337.11,210.13a11,11,0,0,1-1.65.1l-4.49.08-6.65.05h-3.94a5.36,5.36,0,0,1-3.9-1.83,5.55,5.55,0,0,1-1.35-4.08c0-1.36,0-2.67,0-3.93,0-2.48,0-4.73.05-6.66s.06-3.34.08-4.49a9.76,9.76,0,0,1,.11-1.64,10.71,10.71,0,0,1,.1,1.64c0,1.15.05,2.66.08,4.49s0,4.18.06,6.66c0,1.26,0,2.57,0,3.93a5.09,5.09,0,0,0,1.23,3.74,4.86,4.86,0,0,0,3.52,1.66l3.93,0,6.65.06,4.49.08A10,10,0,0,1,337.11,210.13Z"
        style="fill: #263238"
      ></path>
      <path
        d="M331.05,166.31c0,.12-10.55.22-23.56.22s-23.57-.1-23.57-.22,10.55-.23,23.57-.23S331.05,166.18,331.05,166.31Z"
        style="fill: #263238"
      ></path>
      <path
        d="M331.05,273.71c0,.13-10.55.23-23.56.23s-23.57-.1-23.57-.23,10.55-.22,23.57-.22S331.05,273.59,331.05,273.71Z"
        style="fill: #263238"
      ></path>
      <path
        d="M330.45,156.2H286.2a5.54,5.54,0,0,1-5.54-5.54V89.41a5.54,5.54,0,0,1,5.54-5.54h28.06L336,106.26v44.4A5.54,5.54,0,0,1,330.45,156.2Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.2">
        <path
          d="M280.66,134.25H336a0,0,0,0,1,0,0v15.81a6.14,6.14,0,0,1-6.14,6.14H286.79a6.14,6.14,0,0,1-6.14-6.14V134.25A0,0,0,0,1,280.66,134.25Z"
        ></path>
      </g>
      <path
        d="M299.74,142.42v2.38h4.17v1.67h-4.17v3.27h-2.08v-9h6.8v1.67Z"
        style="fill: #fff"
      ></path>
      <path
        d="M305.87,140.75H308v7.3h4.51v1.69h-6.59Z"
        style="fill: #fff"
      ></path>
      <path
        d="M321.64,140.75l-3.89,9h-2.06l-3.88-9h2.25l2.74,6.42,2.77-6.42Z"
        style="fill: #fff"
      ></path>
      <path
        d="M304.41,112.52v-4.27a1.34,1.34,0,0,1,2-1.16l3.7,2.13,3.69,2.14a1.34,1.34,0,0,1,0,2.32l-3.69,2.13-3.7,2.13a1.34,1.34,0,0,1-2-1.16Z"
        style="fill: #fafafa"
      ></path>
      <path
        d="M322.85,112.52a26.15,26.15,0,0,0-.56-3.52,14.36,14.36,0,0,0-18.83-9.9,14.41,14.41,0,0,0-6.63,5,14.23,14.23,0,0,0,0,16.87,14.41,14.41,0,0,0,6.63,5A14.4,14.4,0,0,0,322.29,116a26.68,26.68,0,0,0,.56-3.52,2.07,2.07,0,0,1,0,.23c0,.19,0,.42,0,.7a6.1,6.1,0,0,1-.09,1.12,10.22,10.22,0,0,1-.27,1.52,14,14,0,0,1-1.57,3.92,14.68,14.68,0,0,1-3.7,4.2A14.51,14.51,0,0,1,311,127a14.74,14.74,0,0,1-7.75-.61,14.78,14.78,0,0,1-6.87-5.13,14.69,14.69,0,0,1,0-17.47,14.78,14.78,0,0,1,6.87-5.13A14.74,14.74,0,0,1,311,98a14.69,14.69,0,0,1,9.88,7,13.78,13.78,0,0,1,1.57,3.92,10.11,10.11,0,0,1,.27,1.51,6.13,6.13,0,0,1,.09,1.13c0,.28,0,.5,0,.69A2.17,2.17,0,0,1,322.85,112.52Z"
        style="fill: #fafafa"
      ></path>
      <path
        d="M314.26,83.87v17.24a5.16,5.16,0,0,0,5.15,5.15H336Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <g style="opacity: 0.2">
        <path
          d="M314.49,102.62a5.73,5.73,0,0,0,4.54,5.56,5.23,5.23,0,0,0,.54.12c2.17.39,16.42,2.92,16.42,2.6v-4.64H319.41S315.27,106.12,314.49,102.62Z"
        ></path>
      </g>
      <path
        d="M336,106.26a9.48,9.48,0,0,1-1.65.1l-4.49.09-6.66,0h-3.94a5.39,5.39,0,0,1-3.9-1.83A5.52,5.52,0,0,1,314,100.6c0-1.36,0-2.68,0-3.93,0-2.49,0-4.74.06-6.66s.06-3.34.08-4.49a11,11,0,0,1,.1-1.65,9.48,9.48,0,0,1,.1,1.65c0,1.15.05,2.66.09,4.49s0,4.17.05,6.66c0,1.25,0,2.57,0,3.93a5.05,5.05,0,0,0,1.22,3.74,4.94,4.94,0,0,0,3.53,1.66l3.92,0,6.66.06,4.49.08A11,11,0,0,1,336,106.26Z"
        style="fill: #263238"
      ></path>
      <path
        d="M241.68,156.2H197.42a5.54,5.54,0,0,1-5.53-5.54V89.41a5.54,5.54,0,0,1,5.53-5.54h28.07l21.72,22.39v44.4A5.54,5.54,0,0,1,241.68,156.2Z"
        style="fill: #455a64"
      ></path>
      <g style="opacity: 0.2">
        <path
          d="M192.74,134.25h53.63a.85.85,0,0,1,.85.85v14.82a6.28,6.28,0,0,1-6.28,6.28H198.16a6.28,6.28,0,0,1-6.28-6.28V135.1A.85.85,0,0,1,192.74,134.25Z"
        ></path>
      </g>
      <path
        d="M213.34,149.57v-5.14l-2.53,4.24h-.89l-2.51-4.13v5h-1.87V141h1.65l3.21,5.33,3.16-5.33h1.63l0,8.57Z"
        style="fill: #fff"
      ></path>
      <path
        d="M224.68,144.11c0,1.91-1.44,3.1-3.73,3.1h-1.73v2.36h-2V141H221C223.24,141,224.68,142.18,224.68,144.11Zm-2,0c0-1-.61-1.5-1.83-1.5h-1.62v3h1.62C222.06,145.59,222.67,145,222.67,144.11Z"
        style="fill: #fff"
      ></path>
      <path
        d="M233.16,147.77h-1.4v1.8h-1.93v-1.8h-4.62v-1.33l4-5.44h2.08l-3.75,5.15h2.31v-1.6h1.87v1.6h1.4Z"
        style="fill: #fff"
      ></path>
      <path
        d="M215.64,112.52v-4.27a1.34,1.34,0,0,1,2-1.16l3.69,2.13,3.7,2.14a1.34,1.34,0,0,1,0,2.32l-3.7,2.13-3.69,2.13a1.34,1.34,0,0,1-2-1.16Z"
        style="fill: #fafafa"
      ></path>
      <path
        d="M234.07,112.52a23.14,23.14,0,0,0-.55-3.52,14.36,14.36,0,0,0-18.84-9.9,14.32,14.32,0,0,0-6.62,5,14.23,14.23,0,0,0,0,16.87,14.32,14.32,0,0,0,6.62,5,14.37,14.37,0,0,0,7.51.64,14.19,14.19,0,0,0,6-2.66,14.47,14.47,0,0,0,5.29-7.87,23.53,23.53,0,0,0,.55-3.52.6.6,0,0,1,0,.23c0,.19,0,.42,0,.7a7.13,7.13,0,0,1-.09,1.12,10.22,10.22,0,0,1-.27,1.52,14,14,0,0,1-1.57,3.92,14.68,14.68,0,0,1-3.7,4.2,14.51,14.51,0,0,1-6.18,2.78,14.74,14.74,0,0,1-7.75-.61,14.78,14.78,0,0,1-6.87-5.13,14.69,14.69,0,0,1,0-17.47,13.87,13.87,0,0,1,3.13-3.13,13.67,13.67,0,0,1,3.74-2,14.74,14.74,0,0,1,7.75-.61,14.69,14.69,0,0,1,9.88,7,13.78,13.78,0,0,1,1.57,3.92,10.11,10.11,0,0,1,.27,1.51,7.16,7.16,0,0,1,.09,1.13c0,.28,0,.5,0,.69A.64.64,0,0,1,234.07,112.52Z"
        style="fill: #fafafa"
      ></path>
      <path
        d="M225.49,83.87v17.24a5.15,5.15,0,0,0,5.15,5.15h16.57Z"
        style="fill: #455a64"
      ></path>
      <g style="opacity: 0.2">
        <path
          d="M225.71,102.62a5.75,5.75,0,0,0,4.55,5.56,5.23,5.23,0,0,0,.54.12c2.17.39,16.41,2.92,16.41,2.6v-4.64H230.64S226.5,106.12,225.71,102.62Z"
        ></path>
      </g>
      <path
        d="M247.21,106.26a9.29,9.29,0,0,1-1.64.1l-4.49.09-6.66,0h-3.94a5.39,5.39,0,0,1-3.9-1.83,5.57,5.57,0,0,1-1.35-4.08c0-1.36,0-2.68,0-3.93,0-2.49,0-4.74.05-6.66s.07-3.34.09-4.49a9.48,9.48,0,0,1,.1-1.65,11,11,0,0,1,.1,1.65c0,1.15,0,2.66.08,4.49s0,4.17.06,6.66c0,1.25,0,2.57,0,3.93a5.1,5.1,0,0,0,1.23,3.74,4.93,4.93,0,0,0,3.52,1.66l3.93,0,6.66.06,4.49.08A10.71,10.71,0,0,1,247.21,106.26Z"
        style="fill: #263238"
      ></path>
    </g>
    <g id="freepik--Character--inject-10">
      <path
        d="M319.11,388.62h87.31a5.52,5.52,0,0,0,5.52-5.52h0a5.52,5.52,0,0,0-5.52-5.52H319.11a5.52,5.52,0,0,0-5.52,5.52h0A5.52,5.52,0,0,0,319.11,388.62Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M251.88,430.58l-15.5,35.68L192.43,446c2.2-4.08,28.82-2,28.82-2l9.62-23Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M235.71,448.33A2,2,0,0,1,236,451a1.89,1.89,0,0,1-2.59.32,2.08,2.08,0,0,1-.3-2.78,2,2,0,0,1,2.73,0"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M236.38,466.26l1.75-3.69-43-17.88s-2.35,0-2.76,1.45Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M220.64,443.42c-.09.21.94.81,1.67,2s.89,2.34,1.12,2.35.49-1.38-.43-2.79S220.68,443.2,220.64,443.42Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M215.15,443.2c-.14.18.62,1,.93,2.22s.08,2.3.29,2.39.92-1.06.5-2.6S215.25,443,215.15,443.2Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M210,447.51c.17.13,1-.8,1-2.23s-.8-2.36-1-2.22.16,1.05.14,2.22S209.79,447.39,210,447.51Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M223.41,438.42c0,.23,1.13.39,2.3,1s1.94,1.46,2.14,1.33-.36-1.32-1.75-2S223.39,438.2,223.41,438.42Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M226.87,433.68c-.05.09.74.62,2.2,1a11,11,0,0,0,2.63.35,10.64,10.64,0,0,0,1.57-.09,2.63,2.63,0,0,0,.91-.21,1,1,0,0,0,.62-1.09,1.33,1.33,0,0,0-.71-.93,3.08,3.08,0,0,0-.88-.26,8.87,8.87,0,0,0-1.59-.17,8.78,8.78,0,0,0-2.64.4,4.12,4.12,0,0,0-2.12,1.19c.07.12.87-.33,2.27-.63a9.84,9.84,0,0,1,2.47-.2,9.51,9.51,0,0,1,1.45.2c.52.09.92.27.94.53,0,.1,0,.13-.16.22a2.53,2.53,0,0,1-.67.16,12.48,12.48,0,0,1-1.46.12,13.57,13.57,0,0,1-2.51-.16A18.89,18.89,0,0,0,226.87,433.68Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M227,434.12c.06.07.84-.5,1.56-1.86a7.65,7.65,0,0,0,.81-2.55,7.57,7.57,0,0,0,.06-1.59,2,2,0,0,0-.89-1.76,1.13,1.13,0,0,0-1.2.19,3.11,3.11,0,0,0-.6.7,5.55,5.55,0,0,0-.69,1.49,5.46,5.46,0,0,0-.1,2.7c.33,1.54,1.11,2.21,1.16,2.14a18.08,18.08,0,0,1-.59-2.23,5.41,5.41,0,0,1,.26-2.39,5.2,5.2,0,0,1,.62-1.25c.29-.44.64-.73.79-.6s.43.62.44,1.07a8.74,8.74,0,0,1,0,1.45A8.91,8.91,0,0,1,228,432C227.5,433.34,226.9,434,227,434.12Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M362.77,340.9c-1.87.88-93.92-1.81-96.24,1.4-3.34,4.62-45.37,94-45.37,94l25,13.11,40.27-71.25L387.31,378s11.41-.59,12.8-15.22Z"
        style="fill: #263238"
      ></path>
      <path
        d="M242.65,399.79l-9.54,29.74L189,412.76c1.86-4.12,27.92-3.91,27.92-3.91l5.44-16Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M231.26,412.12a1.93,1.93,0,0,1,.46,2.53,1.85,1.85,0,0,1-2.5.49,2,2,0,0,1-.48-2.69,1.92,1.92,0,0,1,2.66-.21"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M233.11,429.53l1.45-3.71-43.07-14.51s-2.29.12-2.58,1.59Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M216.25,408.35c-.08.22,1,.73,1.76,1.82s1,2.22,1.25,2.21.38-1.38-.61-2.69S216.28,408.14,216.25,408.35Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M210.89,408.51c-.13.19.66.91,1.05,2.1s.23,2.23.45,2.31.82-1.1.31-2.57S211,408.3,210.89,408.51Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M206.15,413.05c.18.12.89-.84.79-2.23s-.94-2.25-1.1-2.1.23,1,.3,2.15S206,413,206.15,413.05Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M218.61,403.3c0,.23,1.12.31,2.31.85s2,1.29,2.17,1.15-.44-1.26-1.84-1.88S218.57,403.09,218.61,403.3Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M221.65,398.46a4.55,4.55,0,0,0,2.22.83,10.31,10.31,0,0,0,2.58.16,9.4,9.4,0,0,0,1.53-.19,2.84,2.84,0,0,0,.86-.26,1,1,0,0,0,.54-1.11,1.24,1.24,0,0,0-.76-.85,2.84,2.84,0,0,0-.87-.2,9.12,9.12,0,0,0-1.55-.06,8.43,8.43,0,0,0-2.55.57c-1.39.56-2,1.23-2,1.3s.82-.38,2.16-.76a9.17,9.17,0,0,1,2.4-.37,9.8,9.8,0,0,1,1.41.09c.52.06.92.21,1,.46s0,.13-.15.23a2.05,2.05,0,0,1-.63.19,12.68,12.68,0,0,1-1.42.23,12.48,12.48,0,0,1-2.45,0A16.55,16.55,0,0,0,221.65,398.46Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M221.82,398.87c.06.07.78-.54,1.39-1.91a7.69,7.69,0,0,0,.62-2.54,8.14,8.14,0,0,0-.05-1.55,2,2,0,0,0-1-1.65,1.11,1.11,0,0,0-1.16.26,3.06,3.06,0,0,0-.53.73,5.2,5.2,0,0,0-.57,1.48,5.4,5.4,0,0,0,.08,2.65c.43,1.47,1.23,2.07,1.27,2a20.72,20.72,0,0,1-.72-2.13,5.38,5.38,0,0,1,.09-2.34,4.64,4.64,0,0,1,.53-1.27c.24-.44.56-.75.72-.64s.46.58.5,1a7.58,7.58,0,0,1,.09,1.41,8.12,8.12,0,0,1-.41,2.37C222.25,398.08,221.71,398.8,221.82,398.87Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M244.29,412.38,215.8,402.12,242.17,320a19.58,19.58,0,0,1,22.44-11.7l85.59,19.31,1.53,13.24-82.88,1.43Z"
        style="fill: #263238"
      ></path>
      <path
        d="M400.47,377.58l10.87,8,25.79-95.88a6.34,6.34,0,0,0-4.48-7.77h0a6.34,6.34,0,0,0-7.77,4.48Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M437.72,202.24a3.52,3.52,0,0,0-.7-1.68,7.9,7.9,0,0,0-4.92-3.17,14.35,14.35,0,0,0,4.5-3.12,5.36,5.36,0,0,0,1.29-5.1,6.36,6.36,0,0,0-3.93-3.49,11.46,11.46,0,0,0-7.39-.53,7.85,7.85,0,0,0-5.29,4.95l2.34,6.07a13.6,13.6,0,0,0,2.72,6.58c.68.87,1.67,1.72,2.74,1.5a2.27,2.27,0,0,0,1.61-2.07,6.7,6.7,0,0,0-.51-2.74,12.65,12.65,0,0,0,4.7,4.1,2.33,2.33,0,0,0,2.08.26A1.63,1.63,0,0,0,437.72,202.24Z"
        style="fill: #263238"
      ></path>
      <path
        d="M424.27,195.56a3.94,3.94,0,0,0-.07-1.45c-.34-1.93-.74-4-2.1-5.38a1.17,1.17,0,0,0-.9-.44c-.54.06-.77.76-.68,1.3a6.94,6.94,0,0,1,.3,1.6l.91,3.89a2.05,2.05,0,0,0,.49,1.17,1.2,1.2,0,0,0,1.17.38A1.32,1.32,0,0,0,424.27,195.56Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M420.63,222.85a78.09,78.09,0,0,0,3.85-13,27.37,27.37,0,0,0-.63-13.43c-.22-.66-2.12-5-2.8-5-.49,0,.63,4.11.37,4.52a23.34,23.34,0,0,0-3.07,13.5,61.87,61.87,0,0,0,2.28,13.43"
        style="fill: #263238"
      ></path>
      <path
        d="M407.17,260.33a12.92,12.92,0,0,0,13.06-12.49l1.42-42.25c.74-11.34-5.25-21.23-16.6-21.65L404,184a20.31,20.31,0,0,0-19.86,19.09c-.59,9.84-1.12,20.71-.94,26,.36,10.9,11.3,12.37,11.3,12.37s0,2.53-.09,5.73a12.91,12.91,0,0,0,12.76,13.21Z"
        style="fill: #b78876"
      ></path>
      <path
        d="M394.5,241.39a26.36,26.36,0,0,0,14.11-4.49s-3.3,8-14.2,7.25Z"
        style="fill: #995037"
      ></path>
      <path
        d="M387.42,211.74a1.56,1.56,0,0,0,1.54,1.54,1.48,1.48,0,0,0,1.57-1.43A1.56,1.56,0,0,0,389,210.3,1.5,1.5,0,0,0,387.42,211.74Z"
        style="fill: #263238"
      ></path>
      <path
        d="M386.56,207.53c.2.2,1.36-.7,3-.73s2.92.78,3.1.57-.12-.45-.66-.83a4.2,4.2,0,0,0-2.48-.71,4.11,4.11,0,0,0-2.41.83C386.63,207.07,386.46,207.44,386.56,207.53Z"
        style="fill: #263238"
      ></path>
      <path
        d="M402.93,212.38a1.55,1.55,0,0,0,1.53,1.54,1.49,1.49,0,0,0,1.58-1.44,1.57,1.57,0,0,0-1.54-1.54A1.48,1.48,0,0,0,402.93,212.38Z"
        style="fill: #263238"
      ></path>
      <path
        d="M403,207.87c.2.2,1.36-.7,3-.73s2.92.78,3.1.57-.12-.45-.66-.83a4.26,4.26,0,0,0-2.49-.71,4.17,4.17,0,0,0-2.41.83C403.06,207.41,402.89,207.78,403,207.87Z"
        style="fill: #263238"
      ></path>
      <path
        d="M397.24,220.87a10.56,10.56,0,0,0-2.73-.44c-.43,0-.84-.11-.91-.4a2.12,2.12,0,0,1,.26-1.27c.38-1,.78-2.14,1.19-3.29,1.66-4.67,2.86-8.51,2.67-8.58s-1.69,3.67-3.35,8.35l-1.14,3.3a2.43,2.43,0,0,0-.19,1.68,1,1,0,0,0,.71.61,3,3,0,0,0,.73.09A11.12,11.12,0,0,0,397.24,220.87Z"
        style="fill: #263238"
      ></path>
      <path
        d="M402.2,221.92c-.28,0-.24,1.81-1.77,3.13s-3.47,1.17-3.48,1.42.44.35,1.26.35a4.51,4.51,0,0,0,2.9-1.08,3.94,3.94,0,0,0,1.37-2.62C402.53,222.36,402.32,221.91,402.2,221.92Z"
        style="fill: #263238"
      ></path>
      <path
        d="M402.94,201.07c.17.45,1.84.2,3.81.39s3.57.7,3.82.29c.11-.2-.17-.62-.82-1.05a6.37,6.37,0,0,0-2.85-.93,6.53,6.53,0,0,0-3,.41C403.22,200.49,402.86,200.85,402.94,201.07Z"
        style="fill: #263238"
      ></path>
      <path
        d="M387.14,202c.3.37,1.44,0,2.83-.09s2.57.2,2.83-.21c.12-.19-.08-.57-.6-.92a4.12,4.12,0,0,0-4.56.24C387.15,201.37,387,201.76,387.14,202Z"
        style="fill: #263238"
      ></path>
      <path
        d="M420.92,213.68c.19-.09,7.45-2.37,7.36,5.09s-7.55,5.83-7.56,5.62S420.92,213.68,420.92,213.68Z"
        style="fill: #b78876"
      ></path>
      <path
        d="M423,221.59s.13.09.35.19a1.3,1.3,0,0,0,1,0,3.21,3.21,0,0,0,1.52-2.86,4.49,4.49,0,0,0-.34-1.87,1.55,1.55,0,0,0-1-1,.67.67,0,0,0-.77.35c-.1.21-.06.36-.1.37s-.16-.13-.09-.43a.8.8,0,0,1,.3-.47,1,1,0,0,1,.72-.18,1.84,1.84,0,0,1,1.34,1.17,4.42,4.42,0,0,1,.43,2.09c-.07,1.5-.85,2.9-1.93,3.2a1.39,1.39,0,0,1-1.19-.22C423,221.77,423,221.6,423,221.59Z"
        style="fill: #995037"
      ></path>
      <path
        d="M389.21,190.8c-1.2-.69,2.5-4.53,5.5-5.82,4.52-1.94,11.39-3.06,15.5-1.8,5,1.51,9,4.07,11.44,8.65a29.28,29.28,0,0,1,3,15,8.8,8.8,0,0,1-1.14,4.53c-1.07,1.63-3.09,2.63-3.05,4.91a3.16,3.16,0,0,1-.82,2.26c-1.39,1.42-3,0-3.78-1.36-1.58-3-1.69-6.44-1.77-9.79a21.82,21.82,0,0,1,.62-7.08c.39-1.24,1-2.4,1.32-3.66a4.46,4.46,0,0,0-.46-3.73,4.51,4.51,0,0,0-.59-.63,7.8,7.8,0,0,0-7.69-1.62c-4.23,1.4-11.44,3.48-13.29,2a11.58,11.58,0,0,1-3.27-3.46Z"
        style="fill: #263238"
      ></path>
      <path
        d="M409.84,182.16a16.4,16.4,0,0,0-12.61.44,8.54,8.54,0,0,0-4.6,4.86c-.66,2.2.23,4.93,2.33,5.84l.15.09c3.16,1.14,5.23.75,8.43-.28,2.69-.87,5.09-2.47,7.78-3.33a15.18,15.18,0,0,1,8.88-.08A24.76,24.76,0,0,0,409.84,182.16Z"
        style="fill: #263238"
      ></path>
      <path
        d="M392.74,364.6c-4.05,0-15.83-5.31-25-9.75A32.1,32.1,0,0,1,355,343.71l-9.69-14.65h0l20-19.05s9-31.32,11.54-36.95,16.39-25,17.16-25.9l0,0,.05,0,26.41-6h0c.16.24,13.34,17.36,11.19,40-1.81,19-31,81.29-31.33,81.91l0,.06-.06,0A23.68,23.68,0,0,1,392.74,364.6Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M288.29,262.56c.42,10.25,4.47,53.68,4.47,53.68l43,24.66,3.17-3.11L296.71,313l-4.14-50.65a2.15,2.15,0,0,0-2.2-2h0A2.14,2.14,0,0,0,288.29,262.56Z"
        style="fill: #e0e0e0"
      ></path>
      <path
        d="M288.29,262.56a1.1,1.1,0,0,1,0-.27,2.32,2.32,0,0,1,.19-.78,2.24,2.24,0,0,1,.9-1,2.21,2.21,0,0,1,3.25,1.52c.1.84.16,1.73.26,2.71.16,2,.36,4.2.57,6.71.85,10.07,2.05,24.37,3.49,41.44l-.11-.18L339,337.56l.29.18-.24.23-.51.51c-.9.87-1.79,1.75-2.66,2.61l-.14.14-.17-.1-43-24.69-.1-.06,0-.12c-1.46-16.08-2.6-29.5-3.33-38.91-.36-4.7-.62-8.4-.76-10.92-.07-1.26-.11-2.23-.13-2.88,0-.31,0-.56,0-.74a1.54,1.54,0,0,1,0-.25.79.79,0,0,1,0,.25c0,.18,0,.42.06.73.05.68.11,1.64.2,2.88.21,2.54.51,6.23.89,10.91.8,9.4,2,22.81,3.51,38.89l-.11-.17,43,24.63-.31,0,2.65-2.62.51-.5.05.41-42.17-24.87-.1-.06V313c-1.36-17.06-2.5-31.38-3.3-41.45q-.29-3.76-.51-6.71c-.08-1-.14-1.89-.22-2.7a2.08,2.08,0,0,0-1.35-1.59,2.12,2.12,0,0,0-2.56,1A2.58,2.58,0,0,0,288.29,262.56Z"
        style="fill: #263238"
      ></path>
      <path
        d="M296.77,313c.08.13-.84.84-2,1.72a8.79,8.79,0,0,1-2.13,1.51c-.1-.1.66-1,1.81-1.91A5.51,5.51,0,0,1,296.77,313Z"
        style="fill: #263238"
      ></path>
      <path
        d="M343,326.17l-11.1-8.5-17.66,4.25s.25,1.55,2.52,2.3l4.8-.75-8.83,6a4.83,4.83,0,0,0,3.4,3.4,8.8,8.8,0,0,1,4.42,3.16l4.54,4.79h12.11l8.21-.82L343,326.17Z"
        style="fill: #b78876"
      ></path>
      <path
        d="M215.8,402.12l.52.16,1.49.5,5.69,2,20.85,7.42-.25.11c5.28-14.59,12.32-34.06,20-55.39l2.93-8.1h0v0a12.52,12.52,0,0,1,10.1-6.79c1.39-.09,2.7-.05,4-.09l15.23-.18,25.57-.28,17.08-.15,4.62,0h1.2l.41,0-.41,0-1.2,0-4.61.11L322,341.8l-25.57.39-15.23.22c-1.32,0-2.68,0-4,.09a12.43,12.43,0,0,0-3.86,1,12.08,12.08,0,0,0-5.83,5.53v0c-.93,2.58-1.93,5.32-2.93,8.1l-20.15,55.35-.07.18-.18-.07c-8.75-3.18-15.85-5.77-20.79-7.58l-5.66-2.09-1.47-.56Z"
        style="fill: #455a64"
      ></path>
      <path
        d="M348.88,352.36,346,335.51l.18,0,43.12-13.28s10.7-42.82,12.81-48c2.6-6.37,12-13.52,15.51-13.05,6.05.82,18.65-1.6,11.18,29.67-2.89,12.09-10.36,33.13-15.35,46.75a18.43,18.43,0,0,1-16.27,12Z"
        v-bind:style="`fill: ${hexColor}`"
      ></path>
      <path
        d="M428.75,290.84c-2.89,12.09-10.36,33.13-15.35,46.75a18.43,18.43,0,0,1-16.27,12l-48.25,2.73L346,335.51l.18,0,43.12-13.28s10.7-42.82,12.81-48"
        style="fill: none"
      ></path>
      <path
        d="M402.06,274.22s-.08.29-.27.85-.43,1.41-.77,2.51c-.63,2.21-1.53,5.47-2.63,9.65-2.21,8.36-5.28,20.39-8.93,35l0,.11-.11,0-43.11,13.33h0l-.18,0,.21-.3c.94,5.4,1.92,11.07,2.93,16.84l-.27-.21,3.44-.2,44.9-2.51a18.22,18.22,0,0,0,9.3-3.24,18,18,0,0,0,6-7.09c.65-1.37,1.12-2.81,1.64-4.2s1-2.76,1.49-4.1c1-2.68,1.89-5.26,2.77-7.72,3.48-9.85,6.14-17.88,7.83-23.48.85-2.79,1.46-5,1.87-6.46.19-.73.34-1.29.45-1.69s.17-.57.17-.57,0,.2-.12.58-.23,1-.4,1.71c-.38,1.49-1,3.69-1.78,6.49-1.63,5.62-4.23,13.67-7.67,23.54-.87,2.47-1.79,5-2.74,7.74-.48,1.34-1,2.71-1.48,4.11s-1,2.82-1.64,4.24a18.68,18.68,0,0,1-15.68,10.61l-44.9,2.56-3.44.2h-.23l0-.23c-1-5.77-2-11.44-2.93-16.84l0-.25.25-.05.18,0h0L389.19,322l-.14.15c3.69-14.62,6.83-26.63,9.1-35,1.14-4.18,2.08-7.43,2.75-9.63.36-1.09.6-1.94.83-2.49S402.06,274.22,402.06,274.22Z"
        style="fill: #263238"
      ></path>
      <path
        d="M424.48,261.83a9.75,9.75,0,0,0-2.21-3.67,14.44,14.44,0,0,0-9.4-4,14.69,14.69,0,0,0-5.59,1.08,16.58,16.58,0,0,0-4.06,2.35,27.31,27.31,0,0,0-3.06,3.09,4.42,4.42,0,0,1,.62-1,13.89,13.89,0,0,1,2.21-2.37,15.39,15.39,0,0,1,9.9-3.66,14.25,14.25,0,0,1,9.64,4.21,7.81,7.81,0,0,1,1.71,2.77,5.11,5.11,0,0,1,.21.85C424.49,261.72,424.5,261.83,424.48,261.83Z"
        style="fill: #263238"
      ></path>
      <g style="opacity: 0.30000000000000004">
        <path
          d="M397.13,338.42c-4.28,1.52-8.9,1.79-13.44,2-10.55.59-19.54.81-30.1,1.4,12.13,3.72,25.78,3.5,38.11.51a29.18,29.18,0,0,0,9.88-3.92c2.93-2,5.26-5.19,5.5-8.75C405.41,333.93,401.42,336.9,397.13,338.42Z"
        ></path>
      </g>
      <g style="opacity: 0.30000000000000004">
        <path
          d="M244.29,412.38c-1.78-.62-3.31-1.21-5.09-1.83-.42.86-.5,1.88-.92,2.74l-.58,1.86c1.41.63,3.23,1.2,4.7,1.69a5.67,5.67,0,0,0,4.71-.51c1.34-.92,2-2.79,2.48-4.34l6.12-18.39c3.48-10.49,6.57-22.5,7.72-33.49C256.63,376.94,250.14,395.2,244.29,412.38Z"
        ></path>
      </g>
      <path
        d="M409.94,387.34a29,29,0,0,0-4-5.61,32.33,32.33,0,0,0-5.49-4.15,5.88,5.88,0,0,1,1.9.78,17,17,0,0,1,4,3,16,16,0,0,1,2.9,4A5.54,5.54,0,0,1,409.94,387.34Z"
        style="fill: #263238"
      ></path>
      <polygon
        points="322.05 466.26 316.31 466.26 323.61 387.23 329.3 387.71 322.05 466.26"
        style="fill: #455a64"
      ></polygon>
      <polygon
        points="407.91 466.26 413.64 466.26 406.3 386.66 400.61 387.15 407.91 466.26"
        style="fill: #455a64"
      ></polygon>
      <rect
        x="323.83"
        y="418.99"
        width="83.25"
        height="3.93"
        style="fill: #455a64"
      ></rect>
      <rect
        x="323.83"
        y="428.88"
        width="83.25"
        height="3.93"
        style="fill: #455a64"
      ></rect>
      <path
        d="M406.16,388.5c0,.15-19.87.26-44.37.26s-44.37-.11-44.37-.26,19.86-.26,44.37-.26S406.16,388.36,406.16,388.5Z"
        style="fill: #263238"
      ></path>
      <path
        d="M322.38,462.6c-.14,0,1-14,2.61-31.13s3-31.1,3.12-31.09-1,13.95-2.6,31.13S322.53,462.61,322.38,462.6Z"
        style="fill: #263238"
      ></path>
    </g>
    <g id="freepik--Floor--inject-10">
      <path
        d="M463.08,466.26c0,.14-95.86.26-214.07.26s-214.09-.12-214.09-.26S130.76,466,249,466,463.08,466.11,463.08,466.26Z"
        style="fill: #263238"
      ></path>
    </g>
  </svg>
</template>
<script>
import colors from "vuetify/lib/util/colors";
export default {
  name: "LanguageSimpleDrawing",
  props: ["color"],
  data() {
    return {
      colors: colors,
    };
  },
  computed: {
    hexColor() {
      let result = [];
      const attrs = this.color.split(" ");
      for (let attr of attrs) {
        const part = attr.split("-");
        let tmp;
        if (part.length > 1) {
          tmp = part[0] + this.capitalizeFirstLetter(part[1]);
        } else {
          tmp = part[0];
        }
        result.push(tmp);
      }
      const hex = this.colors[result[0]][result[1]];
      return hex;
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>