import { render, staticRenderFns } from "./Poster.vue?vue&type=template&id=9aecea96&scoped=true&"
import script from "./Poster.vue?vue&type=script&lang=js&"
export * from "./Poster.vue?vue&type=script&lang=js&"
import style0 from "./Poster.vue?vue&type=style&index=0&id=9aecea96&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9aecea96",
  null
  
)

export default component.exports