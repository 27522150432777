var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"VideoRow"}},[_c('div',{staticClass:"py-2"},[_c('h1',{staticClass:"px-2 mb-2 headline d-flex"},[_vm._v(" "+_vm._s(_vm.title)+" "),(!_vm.noLink)?_c('router-link',{attrs:{"to":{
          path: 'recordings/grid/',
          params: {
            title: _vm.title,
          },
          query: _vm.query,
        }}},[_c('v-btn',{staticClass:"ml-2 mt-n1",attrs:{"icon":"","color":"primary"}},[_c('v-icon',[_vm._v("mdi-chevron-double-right")])],1)],1):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","color":"primary","disabled":_vm.counter === 0},on:{"click":_vm.prevVideo}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":_vm.counter == _vm.filterVideo.length - _vm.columns ||
            _vm.filterVideo.length < _vm.columns},on:{"click":_vm.nextVideo}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('masonry',{ref:"masonry",attrs:{"cols":{ default: 4, 1264: 3, 960: 2, 600: 1 },"gutter":16}},_vm._l((_vm.displayedVideos),function(video,n){return _c('PreviewCard',{key:video + n,staticClass:"mb-4",attrs:{"video":video,"no-link":_vm.noLink}})}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }